import deepmerge from 'deepmerge';
import { FieldArray, Formik } from 'formik';
import React, { Component } from 'react';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import { AccountIdsProps } from '../../../auth/accessToken/components/withAccountIds';
import { Button, ButtonGroup } from '../../../components/Buttons';
import Card from '../../../components/Card';
import { BackButton, Checkbox } from '../../../components/Forms';
import Hr from '../../../components/Hr';
import Icon from '../../../components/Icons';
import { LoadingOverlay } from '../../../components/Loading';
import Page, { Dismiss, PageButton } from '../../../components/Page';
import { H1, H2 } from '../../../components/Typography';
import { getPostalPlace } from '../../../helpers/dataload/postalCodes';
import { unformatOrgNumber } from '../../../helpers/formatters';
import { getValueAt } from '../../../helpers/getValueAt';
import { checkAccountReadyForProduction } from '../../../settings/account/helpers';
import CountryDropdown from '../../../settings/components/CountryDropdown';
import { border, colors, distances, globalColumnMaxWidth, grid } from '../../../styles/constants';
import { AccountBilling, AccountCompany, UpdateAccount } from '../../../types/management-auth';
import { AccountApplicant } from '../../../types/management-auth/generated';
import { State as AccountState } from '../reducer';
import { validateValues } from '../validators/validateNewAccount';
import PricePackageDropdown from './PricePackageDropdown';
import { TranslatedValidatedInput } from './ValidatedInput';

interface MatchParams {
    accountId: string;
}

export interface FullFormProps extends AccountIdsProps, WithTranslation, RouteComponentProps<MatchParams> {
    createAccount: (accountId: string, account: UpdateAccount, type: 'merchant' | 'partner' | undefined) => void;
    resetForm: () => void;
    account: AccountState;
}

export interface FormValues {
    company: AccountCompany;
    billing: AccountBilling;
    applicant: AccountApplicant;

    [key: string]: any;
}

class NewAccountStep2 extends Component<FullFormProps> {
    render() {
        const { t, match, account, baseAccountId } = this.props;
        const { accountId } = match.params;
        const isNotReadyForProduction = account.account && !checkAccountReadyForProduction(account.account);

        if (!account.account) {
            return <LoadingOverlay />;
        }

        const initialFormValues: FormValues = {
            company: account.account.company,
            billing: account.account.billing,
            applicant: account.account.applicant,
            account_id: '',
            partner_id: baseAccountId,
            same_info: true,
            invites: [''],
            send_invite: account.type === 'merchant',
            price_package: account.pricePackages.length === 1 ? { value: account.pricePackages[0].price_package_id, label: account.pricePackages[0].name } : '',
        };
        if (isNotReadyForProduction && initialFormValues && !initialFormValues.billing.email && account.account) {
            // inject email address from applicant if none is set
            initialFormValues.billing.email = account.account.applicant.email;
        }

        const dismissPageButton = account.isPrefilled ? (
            <Dismiss handleClick={() => this.props.resetForm()} />
        ) : (
            <Dismiss />
        );

        const abortButton = account.isPrefilled ? (
            <Button className="alt" onClick={() => this.props.resetForm()}>
                {t(`partner.new_account.abort`)}
            </Button>
        ) : (
            <BackButton>{t(`partner.new_account.abort`)}</BackButton>
        );
        return (
            <Formik
                enableReinitialize
                initialValues={initialFormValues}
                validate={(values) => validateValues(values, t, account.type)}
                onSubmit={(values) => {
                    if (!account.account || !values) {
                        return;
                    }
                    const submitValues: FormValues & {livemode: boolean} = { ...values, livemode: true };
                    if (values.same_info) {
                        submitValues.billing.business_name = submitValues.company.business_name;
                        submitValues.billing.organization_number = submitValues.company.organization_number;
                        submitValues.billing.address = {
                            address_line: submitValues.company.address.address_line,
                            address_line_2: submitValues.company.address.address_line_2,
                            postal_code: submitValues.company.address.postal_code,
                            postal_place: submitValues.company.address.postal_place,
                            country: submitValues.company.address.country,
                        };
                    }
                    if (!submitValues.billing.email) {
                        submitValues.billing.email = submitValues.applicant.email;
                    }
                    if (submitValues.company.website === 'https://') {
                        submitValues.company.website = '';
                    }
                    if (submitValues.company.terms_url === 'https://') {
                        submitValues.company.terms_url = '';
                    }
                    submitValues.company.organization_number = unformatOrgNumber(
                        submitValues.company.address.country,
                        submitValues.company.organization_number || ''
                    );
                    submitValues.billing.organization_number = unformatOrgNumber(
                        submitValues.billing.address.country,
                        submitValues.billing.organization_number || ''
                    );
                    if (submitValues.company.email === '') {
                        // Backend does not accept empty string
                        submitValues.company.email = undefined;
                    }
                    if (submitValues.company.technical_email === '') {
                        // Backend does not accept empty string
                        submitValues.company.technical_email = undefined;
                    }
                    if (submitValues.price_package === '') {
                        submitValues.price_package = undefined;
                    } else {
                        submitValues.price_package = submitValues.price_package.value;
                    }
                    if (submitValues.invites && submitValues.invites.length > 0) {
                        submitValues.invites = submitValues.invites.filter(Boolean).map((invite: string) => invite);
                        submitValues.applicant.email = submitValues.invites[0];
                    } else {
                        submitValues.invites = undefined;
                    }
                    if (submitValues.send_invite) {
                        submitValues.actions = ['send_invite'];
                    }
                    submitValues.send_invite = undefined;
                    if (account.type === 'merchant') {
                        // Let the backend decide the account_id for a merchant
                        submitValues.account_id = undefined;
                        submitValues.account_manager = undefined;
                    }
                    this.props.createAccount(accountId, submitValues, account.type);
                }}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, touched, setTouched }) => {

                    return (
                        <Form onSubmit={handleSubmit} autoComplete="nope">
                            <Page
                                title={
                                    <React.Fragment>
                                        {dismissPageButton}
                                        <Title>{t(`partner.new_account.title`, {
                                            context: account.type,
                                        })}</Title>
                                        <PageButton
                                            disabled={
                                                account.isLoading ||
                                                Object.keys(errors || {}).length > 0 ||
                                                Object.keys(touched || {}).length === 0
                                            }
                                            type="submit"
                                        >
                                            <Icon icon="save" fill="currentColor" />
                                            {t(`partner.new_account.save`, {
                                                context: account.type,
                                            })}
                                        </PageButton>
                                    </React.Fragment>
                                }
                            >
                                <Card title={undefined}>
                                    <Wrapper>
                                        <FormGroup>
                                            <H2>{t(`partner.new_account.company_info`)}</H2>
                                            <Hr />
                                            <FlexHalf>
                                                <CountryDropdown
                                                    name="company.address.country"
                                                    value={getValueAt('company.address.country', values)}
                                                    label={
                                                        t(`partner.new_account.fields.company.address.country`)
                                                    }
                                                    onChange={(value) => {
                                                        setFieldValue('company.address.country', value);
                                                        setTouched(
                                                            deepmerge(touched || {}, {
                                                                company: {
                                                                    address: {
                                                                        country: true,
                                                                    },
                                                                },
                                                            })
                                                        );
                                                    }}
                                                    placeholder={t(
                                                        `partner.new_account.placeholders.company.address.country`
                                                    )}
                                                />
                                            </FlexHalf>
                                            <Flex>
                                                <TranslatedValidatedInput
                                                    path="company.business_name"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                    accountType={account.type}
                                                />
                                                <TranslatedValidatedInput
                                                    path="company.display_name"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    accountType={account.type}
                                                />
                                            </Flex>
                                            <Flex>
                                                <TranslatedValidatedInput
                                                    path="company.organization_number"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    setFieldValue={setFieldValue}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    setTouched={setTouched}
                                                    initialValues={initialFormValues}
                                                    required
                                                    accountType={account.type}
                                                />
                                                <TranslatedValidatedInput
                                                    path="company.industry"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    accountType={account.type}
                                                />
                                            </Flex>
                                            <Flex>
                                                <TranslatedValidatedInput
                                                    path="company.website"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    setFieldValue={setFieldValue}
                                                    required
                                                    accountType={account.type}
                                                />
                                                <TranslatedValidatedInput
                                                    path="company.terms_url"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    setFieldValue={setFieldValue}
                                                    accountType={account.type}
                                                />
                                            </Flex>
                                            <Flex>
                                                <TranslatedValidatedInput
                                                    path="company.address.address_line"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                    accountType={account.type}
                                                />
                                                <TranslatedValidatedInput
                                                    path="company.address.address_line_2"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    accountType={account.type}
                                                />
                                            </Flex>
                                            <Flex>
                                                <TranslatedValidatedInput
                                                    path="company.address.postal_code"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={(e) => {
                                                        getPostalPlace(values.company.address.country, (e.target as HTMLInputElement).value).then(
                                                            (place) => {
                                                                if (place) {
                                                                    setFieldValue(
                                                                        'company.address.postal_place',
                                                                        place
                                                                    );
                                                                }
                                                            }
                                                        );
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    accountType={account.type}
                                                />
                                                <TranslatedValidatedInput
                                                    path="company.address.postal_place"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                    accountType={account.type}
                                                />
                                            </Flex>
                                        </FormGroup>
                                        {((account.pricePackages.length > 0) || (account.type === 'partner')) && (<>
                                            <H2>{t(`partner.new_account.account_info`)}</H2>
                                            <Hr />
                                        </>)}
                                        {account.type === 'partner' && (
                                            <Flex>
                                                <TranslatedValidatedInput
                                                    path="account_id"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                    accountType={account.type}
                                                />
                                                <TranslatedValidatedInput
                                                    path="account_manager.email"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                    accountType={account.type}
                                                />
                                            </Flex>)}
                                        {account.pricePackages.length > 0 &&
                                            <PricePackageDropdown
                                                account={account}
                                                values={values}
                                                setFieldValue={setFieldValue}
                                                setTouched={setTouched}
                                                touched={touched}
                                            />
                                        }
                                        {values && !values.same_info && (
                                            <React.Fragment>
                                                <H2>{t(`partner.new_account.billing_info`)}</H2>
                                                <Hr />
                                                <MarginWrapper>
                                                    <Checkbox
                                                        label={t(
                                                            `partner.new_account.use_same_info_for_company_and_billing`
                                                        )}
                                                        checked={values.same_info}
                                                        name="same_info"
                                                        onChange={(event) => {
                                                            if (values && account.account) {
                                                                const source = values.company;
                                                                setFieldValue(
                                                                    'billing.business_name',
                                                                    getValueAt('business_name', source)
                                                                );
                                                                setFieldValue(
                                                                    'billing.organization_number',
                                                                    getValueAt('organization_number', source)
                                                                );
                                                                setFieldValue(
                                                                    'billing.address.address_line',
                                                                    getValueAt('address.address_line', source)
                                                                );
                                                                setFieldValue(
                                                                    'billing.address.address_line_2',
                                                                    getValueAt('address.address_line_2', source)
                                                                );
                                                                setFieldValue(
                                                                    'billing.address.postal_code',
                                                                    getValueAt('address.postal_code', source)
                                                                );
                                                                setFieldValue(
                                                                    'billing.address.postal_place',
                                                                    getValueAt('address.postal_place', source)
                                                                );
                                                                setFieldValue(
                                                                    'billing.address.country',
                                                                    getValueAt('address.country', source)
                                                                );
                                                                handleChange(event);
                                                            }
                                                        }}
                                                    />
                                                </MarginWrapper>
                                            </React.Fragment>
                                        )}
                                        {values && !values.same_info && (
                                            <React.Fragment>
                                                <Flex>
                                                    <TranslatedValidatedInput
                                                        path="billing.business_name"
                                                        values={values}
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        touched={touched}
                                                        required
                                                        accountType={account.type}
                                                    />
                                                    <TranslatedValidatedInput
                                                        path="billing.organization_number"
                                                        values={values}
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        setFieldValue={setFieldValue}
                                                        setTouched={setTouched}
                                                        onBlur={handleBlur}
                                                        touched={touched}
                                                        required
                                                        accountType={account.type}
                                                    />
                                                </Flex>
                                            </React.Fragment>
                                        )}
                                        {values && !values.same_info && (
                                            <React.Fragment>
                                                <Flex>
                                                    <TranslatedValidatedInput
                                                        path="billing.email"
                                                        values={values}
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        touched={touched}
                                                        setFieldValue={setFieldValue}
                                                        accountType={account.type}
                                                    />
                                                    <TranslatedValidatedInput
                                                        path="billing.reference"
                                                        values={values}
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        touched={touched}
                                                        accountType={account.type}
                                                    />
                                                </Flex>
                                            </React.Fragment>
                                        )}
                                        {values && !values.same_info && (
                                            <React.Fragment>
                                                <Flex>
                                                    <TranslatedValidatedInput
                                                        path="billing.address.address_line"
                                                        values={values}
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        touched={touched}
                                                        required
                                                        accountType={account.type}
                                                    />
                                                    <TranslatedValidatedInput
                                                        path="billing.address.address_line_2"
                                                        values={values}
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        touched={touched}
                                                        accountType={account.type}
                                                    />
                                                </Flex>
                                                <Flex>
                                                    <TranslatedValidatedInput
                                                        path="billing.address.postal_code"
                                                        values={values}
                                                        errors={errors}
                                                        onChange={(e) => {
                                                            getPostalPlace(values.billing.address.country || values.company.address.country, (e.target as HTMLInputElement).value).then(
                                                                (place) => {
                                                                    if (place) {
                                                                        setFieldValue(
                                                                            'billing.address.postal_place',
                                                                            place
                                                                        );
                                                                    }
                                                                }
                                                            );
                                                            handleChange(e);
                                                        }}
                                                        onBlur={handleBlur}
                                                        touched={touched}
                                                        accountType={account.type}
                                                    />
                                                    <TranslatedValidatedInput
                                                        path="billing.address.postal_place"
                                                        values={values}
                                                        errors={errors}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        touched={touched}
                                                        required
                                                        accountType={account.type}
                                                    />
                                                </Flex>

                                                <FlexHalf>
                                                    <CountryDropdown
                                                        name="billing.address.country"
                                                        value={getValueAt('billing.address.country', values)}
                                                        label={
                                                            t(`partner.new_account.fields.billing.address.country`) +
                                                            ' *'
                                                        }
                                                        onChange={(value) => {
                                                            setFieldValue('billing.address.country', value);
                                                            setTouched(
                                                                deepmerge(touched || {}, {
                                                                    billing: {
                                                                        address: {
                                                                            country: true,
                                                                        },
                                                                    },
                                                                })
                                                            );
                                                        }}
                                                        placeholder={t(
                                                            `partner.new_account.placeholders.billing.address.country`
                                                        )}
                                                    />
                                                </FlexHalf>
                                            </React.Fragment>
                                        )}
                                        <InvitesManager
                                            values={values}
                                            errors={errors}
                                            path="invites"
                                            touched={touched}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            setFieldValue={setFieldValue}
                                            setTouched={setTouched}
                                            type={account.type}
                                        />
                                        <ButtonGroup>
                                            {abortButton}
                                            <Button
                                                disabled={
                                                    account.isLoading ||
                                                    Object.keys(errors || {}).length > 0 ||
                                                    Object.keys(touched || {}).length === 0
                                                }
                                                type="submit"
                                            >
                                                {t(`partner.new_account.save`, {
                                                    context: account.type,
                                                })}
                                            </Button>
                                        </ButtonGroup>
                                        {account.isLoading && <LoadingOverlay />}
                                    </Wrapper>
                                </Card>
                            </Page>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

interface InvitesManagerProps {
    values: any;
    errors: any;
    path: string;
    touched: any;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    setFieldValue: (field: string, value: any) => void;
    setTouched: (fields: any) => void;
    type: 'partner' | 'merchant' | undefined;
}

const InvitesManager = (props: InvitesManagerProps) => {
    const { values, errors, touched, setFieldValue, setTouched, path, type, onBlur, onChange } = props;
    const { t } = useTranslation('app');
    return <>
        <H2>{t('partner.new_account.invites')}</H2>
        <span>{t('partner.new_account.applicant_info')}</span>
        <Hr />
        <FieldArray
            name={path}
            render={arrayHelpers => <InviteTable>
                <thead>
                    <tr>
                        <th>{t('partner.new_account.invite_email')}</th>
                        <th>
                            <AddButton onClick={() => arrayHelpers.push('')} className="alt tiny with-icon" type="button">
                                <OffsetIcon icon="plus" />
                                {t('partner.new_account.add_invite')}
                            </AddButton>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {values.invites && values.invites.length > 0 ? (
                        values.invites.map((_: string, index: number) => (
                            <tr key={index}>
                                <td>
                                    <TranslatedValidatedInput
                                        path={`invites[${index}]`}
                                        values={values}
                                        errors={errors}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        touched={touched}
                                        required
                                        accountType={type}
                                        setFieldValue={setFieldValue}
                                    />
                                </td>
                                <td>
                                    <Button className="alt tiny" type="button" onClick={() => arrayHelpers.remove(index)}>
                                        <Icon icon="delete" />
                                    </Button>
                                </td>
                            </tr>
                        ))) : <tr>
                        <NoInvitesTd>{t('partner.new_account.no_invites')}</NoInvitesTd>
                        <td></td>
                    </tr>}
                </tbody>
            </InviteTable>}
        />
        <Checkbox
            name="send_invite"
            label="Send invites when account is created"
            checked={values.send_invite}
            onChange={(e) => {
                setFieldValue('send_invite', (e.target as HTMLInputElement).checked);
                setTouched(
                    deepmerge(touched || {}, {
                        send_invite: true,
                    })
                );
            }}
            disabled={values.invites.length === 0}
        />
        <Hr />
    </>;
};

const InviteTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-bottom: ${distances.small};

    th, td {
        &:first-child {
            column-span: 2;
        }
        &:last-child {
            text-align: right;
            width: 150px;
        }
    }

    tbody tr td {
        padding: ${distances.micro} 0;
        border-bottom: ${border.normal} solid ${colors.borderLight};
    }

    th {
        text-align: left;
        border-bottom: ${border.normal} solid ${colors.borderLight};
        padding: ${distances.micro} 0;
    }
`;

const NoInvitesTd = styled.td`
    text-align: left;
    height: ${distances.large};
`;

const AddButton = styled(Button)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const OffsetIcon = styled(Icon)`
    top: -1px;
    position: relative;
`;

const Form = styled.form`
    width: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    width: 100%;
`;

const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Flex = styled.div`
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const FlexHalf = styled.div`
    display: flex;
    max-width: ${grid.spans.span4};
`;

const MarginWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.small};
`;



export default withTranslation()(NewAccountStep2);
