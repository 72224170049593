import React, { useEffect, useRef, useState } from 'react';
import { TFunction, Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Formik, FormikErrors, FormikTouched } from 'formik';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../Actions';
import { useAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { Dropdown, Input, ValidationState } from '../../../../components/Forms';
import { LoadingOverlay } from '../../../../components/Loading';
import Modal from '../../../../components/Modal';
import { H1, P } from '../../../../components/Typography';
import { formatDate, formatOrgNumber, formatTime } from '../../../../helpers/formatters';
import { getValueAt } from '../../../../helpers/getValueAt';
import { validate, validateLength, validatePositiveNumber } from '../../../../helpers/validation';
import { distances } from '../../../../styles/constants';
import type { ApprovalsPaymentResponse } from '../../../../types/management-auth/generated';
import { InputWrapper } from '../../../payoutRules/newPayoutRule/components/common';
import { usePaymentApprovals } from '../../approvalsPayments/hooks';
import { ToggleDetails } from '../../connections/components/styledListItemComponents';
import { ModalState, namespace } from '../actions';
import { ENVIRONMENT, Environment } from '../../../../env';



type FormValues = {
    pricePackage: string;
    mcc: string;
    approval: ApprovalsPaymentResponse | undefined;
}

const pricePacksForEnv = {
    dev: ['DINTERO_PP_001'], // only price package working in Klarna playground
    test: ['DINTERO_PP_001'],
    prod: ['EU_DINTERO_PP001', 'EU_DINTERO_PP002', 'EU_DINTERO_PP003'],
} as Record<Environment, string[]>;

const fieldValidationMessage = (
    path: string,
    errors: FormikErrors<FormValues>,
    touched: FormikTouched<FormValues>,
    initialValues: FormValues
) => {
    const initialValue = getValueAt(path, initialValues);
    const error = getValueAt(path, errors);
    const was_touched = getValueAt(path, touched);
    if (error && (was_touched || initialValue)) {
        return {
            state: ValidationState.Invalid,
            message: error,
        };
    }
    return undefined;
};

const toOption = (approval: ApprovalsPaymentResponse | undefined, language: string) => {
    const country = approval?.bank_accounts?.[0]?.bank_account_country_code || '';
    const currency = approval?.bank_accounts?.[0]?.bank_account_currency;
    const org = approval?.organization_number || '';
    const date = formatDate(approval?.created_at || '', language);
    const time = formatTime(approval?.created_at || '', language);
    const mcc = approval?.mcc || '';
    return {
        label: [currency, date, time, country, formatOrgNumber(country, org).trim(), mcc].filter(x => x).join(', '),
        value: approval?.id || '',
    };
};

const toPriceOption = (pricePackage: string, t: TFunction<'app'>) => {
    return {
        label: t('settings.payment_connections.klarna_modal.price_package', {
            context: pricePackage,
        }),
        value: pricePackage,
    };
};

const KlarnaModal = () => {
    const { t, i18n } = useTranslation();
    const approval = useRef<ApprovalsPaymentResponse>();
    const { signupForConnection, closeModal } = useActions('connections.klarna');
    const { prodAccountId } = useAccountIds();
    const { approvals, isLoading } = usePaymentApprovals();
    const activeApprovals = (approvals || []).filter(x => x?.case_status === 'ACTIVE');
    const pricePackages = pricePacksForEnv[ENVIRONMENT];
    useEffect(() => {
        if (activeApprovals.length > 0) {
            const lastActive = activeApprovals[activeApprovals.length -1];
            approval.current = lastActive;
            // default to most recent approval
        }
    }, [activeApprovals, approval]);

    const { modalState, account } = useSelector(state => {
        return ({
            modalState: state[namespace].modalState,
            account: state[namespace].account,
        });
    });

    if (!prodAccountId) {
        return null;
    }

    if (modalState === ModalState.Closed) {
        return null;
    }

    if (!account || isLoading) {
        return <LoadingOverlay />;
    }

    return (
        (<Modal fixedWidth>
            {[
                ModalState.ApplicationFormOpened,
                ModalState.InitiateApplicationLoading,
            ].includes(
                modalState
            ) && (
                <>
                    <H1>{t('settings.payment_connections.klarna_modal.title_intro')}</H1>
                    <Formik<FormValues>
                        initialValues={{
                            approval: approval.current,
                            pricePackage: pricePackages[0] || '',
                            mcc: '',
                        }}
                        validate={values => {
                            return [
                                {
                                    path: 'mcc',
                                    validator: validateLength(4, t('settings.payment_connections.klarna_modal.invalid.mcc')),
                                },
                                {
                                    path: 'mcc',
                                    validator: validatePositiveNumber(t('settings.payment_connections.klarna_modal.invalid.mcc')),
                                },
                            ].reduce((acc, elem) => {
                                return validate(elem.path, elem.validator, values, acc);
                            }, {} as { [key: string]: string });
                        }}
                        onSubmit={values => {
                            const { approval, pricePackage, mcc } = values;
                            if (approval && approval.id) {
                                const currencies = [...(new Set<string>((approval.bank_accounts || []).map(x => x.bank_account_currency)))];
                                if (currencies.length > 0) {
                                    signupForConnection(prodAccountId, {
                                        case_id: approval.id,
                                        payment_options: [
                                            {
                                                currencies,
                                                type: 'klarna.klarna',
                                            },
                                        ],
                                        price_package: pricePackage,
                                        ...(mcc && {
                                            mcc,
                                        }),
                                    });
                                }
                            }
                        }}
                    >
                        {({ values, handleChange, handleBlur, setFieldValue, handleSubmit, errors, touched, initialValues }) => (
                            <form onSubmit={handleSubmit}>
                                <SpacedWrapper>
                                    <P>
                                        <Trans i18nKey="settings.payment_connections.klarna_modal.intro" />
                                    </P>
                                </SpacedWrapper>
                                <SpacedWrapper>
                                    {(approvals === undefined || approvals.length === 0) &&
                                <P>
                                    {t('settings.payment_connections.klarna_modal.no_approvals')}
                                </P>
                                    }
                                    {approvals && approvals.length > 0 && activeApprovals.length === 0 &&
                                <P>
                                    {t('settings.payment_connections.klarna_modal.approvals_invalid_state')}
                                </P>
                                    }
                                    {
                                        activeApprovals.length > 0 && <>
                                            <Dropdown
                                                label={t('settings.payment_connections.klarna_modal.select_approval_label')}
                                                name="approval"
                                                value={toOption(values.approval, i18n.language)}
                                                onChange={option => {
                                                    const result = activeApprovals.find(x => x.id === option.value);
                                                    setFieldValue('approval', result);
                                                }}
                                                disabled={activeApprovals.length === 0 || modalState === ModalState.InitiateApplicationLoading}
                                                options={
                                                    activeApprovals.reverse().map((a) => toOption(a, i18n.language))
                                                }
                                                required
                                            />
                                            <Dropdown
                                                label={t('settings.payment_connections.klarna_modal.select_price_package_label')}
                                                name="price-package"
                                                value={toPriceOption(values.pricePackage, t)}
                                                onChange={option => {
                                                    setFieldValue('pricePackage', option.value);
                                                }}
                                                disabled={activeApprovals.length === 0 || modalState === ModalState.InitiateApplicationLoading}
                                                options={pricePackages.map(x => toPriceOption(x, t))}
                                            />
                                            <OverrideMCCSettings>
                                                <InputWrapper>
                                                    <Input
                                                        label={t('settings.payment_connections.klarna_modal.override_mcc_label')}
                                                        placeholder={t('settings.payment_connections.klarna_modal.override_mcc_placeholder')}
                                                        type="text"
                                                        name="mcc"
                                                        value={values.mcc}
                                                        onChange={handleChange}
                                                        onBlur={(e) => {
                                                            handleChange(e);
                                                            handleBlur(e);
                                                        }}
                                                        disabled={activeApprovals.length === 0 || modalState === ModalState.InitiateApplicationLoading}
                                                        validation={fieldValidationMessage('mcc', errors, touched, initialValues)}
                                                    />
                                                </InputWrapper>
                                            </OverrideMCCSettings>
                                        </>
                                    }
                                </SpacedWrapper>
                                <ButtonGroup>
                                    <Button className="alt" onClick={closeModal}>
                                        {t('settings.payment_connections.klarna_modal.cancel')}
                                    </Button>
                                    <Button type="submit" disabled={activeApprovals.length === 0 && !Boolean(values.approval)}>
                                        {t(
                                            'settings.payment_connections.klarna_modal.sign_up_with_klarna_call_to_action'
                                        )}
                                    </Button>
                                </ButtonGroup>
                                {modalState === ModalState.InitiateApplicationLoading && <LoadingOverlay />}
                            </form>
                        )}
                    </Formik>
                </>
            )}
            {modalState === ModalState.ApplicationReceived && (
                <>
                    <H1>{t('settings.payment_connections.klarna_modal.title_last_step')}</H1>
                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.klarna_modal.last_step" />
                        </P>
                    </SpacedWrapper>

                    <ButtonGroup>
                        <Button onClick={closeModal}>
                            {t('settings.payment_connections.klarna_modal.close')}
                        </Button>
                    </ButtonGroup>
                </>
            )}
        </Modal>)
    );
};

const OverrideMCCSettings = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [overrideSettings, setOverrideSettings] = useState(false);
    const { t } = useTranslation();
    return (<>
        <InputWrapper>
            <ToggleDetails type="button" onClick={() => setOverrideSettings(!overrideSettings)}>
                {t('settings.payment_connections.bambora_modal.advanced_toggle', {
                    context: overrideSettings ? 'hide' : 'show',
                })}
            </ToggleDetails>
        </InputWrapper>
        {overrideSettings && <>
            {children}
        </>}
    </>
    );
};


const SpacedWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;

export default KlarnaModal;
