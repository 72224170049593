import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useSelector } from 'react-redux';
import PayoutDestinationStatus from './components/PayoutDestinationStatus';

import { distances } from '../../styles/constants';
import { useActions } from '../../Actions';

import Page, { Back,  PageButton,  Title } from '../../components/Page';
import Card from '../../components/Card';
import { Dl, Dd, Dt } from '../../components/DescriptionList/Horizontal';
import { H2, P } from '../../components/Typography';
import { LoadingOverlay } from '../../components/Loading';
import { DateTime } from '../../components/Formatters';
import { createSelector } from 'reselect';
import A from '../../components/A';
import { useAccountIds } from '../../auth/accessToken/components/withAccountIds';
import { approvalsStateSelector } from '../approvalsSelectors';
import { ApprovalsPayoutDestinationResponse } from '../../types/management-auth/generated';
import AllowDinteroRoot from '../../auth/accessToken/components/AllowDinteroRoot';
import { ButtonExternalLink } from '../../components/Buttons';
import { formatOrgNumber } from '../../helpers/formatters';
import Icon from '../../components/Icons';
import RemovePayoutDestinationApproval from './components/RemovePayoutDestinationApproval';
import View from '../../components/View';
import Grid, { GridItem } from '../../components/Grid';
import Tooltip from '../../components/Tooltip';

const approvalSelector = (accountId: string, approvalId: string) => createSelector(
    approvalsStateSelector(accountId),
    ({ loading, approvalById }) => ({
        loading,
        // typescript behaves rather odd here for some reason..
        approval: approvalById[approvalId] as ApprovalsPayoutDestinationResponse | undefined,
    })
);

const getCaseUrl =(approval: ApprovalsPayoutDestinationResponse) => {
    try {
        const cddCaseUrl = approval.links?.find(x => x.rel ==='cdd_case_url');
        if (cddCaseUrl && cddCaseUrl.href) {
            // is quickr v1
            return (cddCaseUrl.href || '').replace('api.', 'app.');
        }
        const declarationUrl = (approval.links || []).find(x => x.rel === 'declaration_url');
        if (declarationUrl && declarationUrl.href) {
            // is quickr v2
            const url = new URL(declarationUrl.href);
            const casePath = url.pathname.replace('/answer', '').split('declarations')[0];
            return url.origin + casePath;
        }
    } catch (e: any) {
        console.error(e);
    }
};


export default function PayoutDestinationApproval() {
    const { t } = useTranslation();
    const accountIds = useAccountIds();
    const { getSellerApproval, deleteSellerApproval } = useActions('management.approvals');

    const isPayoutDestination = new URLSearchParams(window.location.search).get('isPayoutDestination') === 'true';
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const deletePayoutDestinationApproval = async () => {
        deleteSellerApproval(accountIds.urlAccountId, payoutDestinationApprovalId);
        setShowDeleteModal(false);
    };


    const {
        payoutDestinationApprovalId,
    } = useRouteMatch<{payoutDestinationApprovalId: string}>().params;

    useEffect(() => {
        getSellerApproval(accountIds.urlAccountId, payoutDestinationApprovalId);
    }, [getSellerApproval, accountIds.urlAccountId, payoutDestinationApprovalId]);

    const {
        loading,
        approval,
    } = useSelector(
        useMemo(() => approvalSelector(accountIds.urlAccountId, payoutDestinationApprovalId), [accountIds.urlAccountId, payoutDestinationApprovalId])
    );

    if (loading || !approval) {
        return null;
    }

    const contractUrl = approval.links?.find(x => x.rel ==='contract_url');
    const caseUrl = getCaseUrl(approval);

    return (
        <Page
            title={<>
                <Back />
                <Title>
                    {t('settings.payout_destination_approval.title')}
                </Title>
                <PageButton onClick={() => setShowDeleteModal(true)}  disabled={approval.case_status === 'ACTIVE' || approval.case_status === 'ARCHIVED'}>
                    <Icon icon="delete" />
                    {t('settings.payout_destination_approval.delete')}
                </PageButton>
            </>}
        >
            <Card marginBottom={distances.normal}>
                <View>
                    <H2>{approval.payout_destination_id}</H2>
                </View>
                <Grid gap={16}>
                    <GridItem>
                        <Dl>
                            <Dt doubleColumn>{t('settings.payout_destination_approval.fields.payout_destination_name')}</Dt>
                            <Dd>{approval.payout_destination_name}</Dd>
                        </Dl>
                        {approval.payout_destination_description && <Dl>
                            <Dt doubleColumn>{t('settings.payout_destination_approval.fields.payout_destination_description')}</Dt>
                            <Dd>{approval.payout_destination_description}</Dd>
                        </Dl>}
                        <Dl>
                            <Dt doubleColumn>{t('settings.payout_destination_approval.fields.payout_destination_reference')}</Dt>
                            <Dd>{approval.payout_reference}</Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>{t('settings.payout_destination_approval.fields.organization_number')}</Dt>
                            <Dd>{approval.country_code.toLocaleUpperCase()}: {formatOrgNumber(approval.country_code.toLocaleUpperCase(), approval.organization_number)}</Dd>
                        </Dl>

                    </GridItem>
                    <GridItem>
                        {<Dl>
                            <Dt>{t('settings.payout_destination_approval.fields.status')}</Dt>
                            <Dd>
                                <Tooltip title={t(`app:settings.account.payout.case_status_description.${approval.case_status}` as any)}>
                                    <PayoutDestinationStatus status={approval.case_status || 'ERROR'} activeAsPayoutDestination={isPayoutDestination} />
                                </Tooltip>
                            </Dd>
                        </Dl>}
                        <Dl>
                            <Dt>{t('settings.payout_destination_approval.fields.created_at')}</Dt>
                            <Dd><DateTime isoString={approval?.created_at ?? ''} /></Dd>
                        </Dl>
                        <Dl>
                            <Dt>{t('settings.payout_destination_approval.fields.updated_at')}</Dt>
                            <Dd><DateTime isoString={approval?.updated_at ?? ''} /></Dd>
                        </Dl>
                        {approval?.deleted_at &&(
                            <Dl>
                                <Dt>{t('settings.payout_destination_approval.fields.deleted_at')}</Dt>
                                <Dd><DateTime isoString={approval?.deleted_at ?? ''} /></Dd>
                            </Dl>)}
                    </GridItem>
                </Grid>
                {loading && <LoadingOverlay />}
            </Card>
            <Card>
                <Dl>
                    <Dt doubleColumn>{t('settings.payout_destination_approval.fields.contract_url')}</Dt>
                    <Dd>
                        {contractUrl ?
                            <A
                                href={contractUrl.href}
                                target="_blank"
                            >
                                {contractUrl.href}
                            </A> :<P>{t('app:settings.account.payout.application_link_missing')}</P>
                        }
                    </Dd>
                </Dl>
            </Card>
            <AllowDinteroRoot visibleFrame>
                {caseUrl ?
                    <Card>
                        <ButtonExternalLink href={caseUrl} target="_blank" onClick={e => e.stopPropagation()}>{t('partner.merchant.open_in_quickr')} ↗</ButtonExternalLink>
                    </Card>
                    : <></>
                }
            </AllowDinteroRoot>
            {showDeleteModal  && <RemovePayoutDestinationApproval dismiss={() => setShowDeleteModal(false)} remove={deletePayoutDestinationApproval} />}
        </Page>
    );
}
