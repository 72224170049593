
import CheckboxListFilter, { Option } from '../../../components/Filters/CheckboxListFilter';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FilterProps } from './FilterProps';

export type ConnectionFilterProps = FilterProps

const getConnectionName = (t: TFunction, value: string) => {
    const key = `payments.payment_product.${value || 'undefined'}`;
    const translated = t(key);
    if (translated === key) {
        return value;
    }
    return translated;
};

const optionValues = [
    'payex',
    'collector',
    'bambora',
    'swish',
    'vipps',
    'instabank',
    'klarna',
] as const;

const ConnectionFilter = ({ queryParams, onChange, onClose }: ConnectionFilterProps) => {
    const { t } = useTranslation();

    const options: Option[] = optionValues.map((value) => ({
        label: t(`payments.payment_product.${value}`),
        value,
    })).sort((a, b) => a.label.localeCompare(b.label));

    const values = queryParams.getAll('connection');

    return (
        <CheckboxListFilter
            label={t('partner.merchants.filter.connection.label', {
                count: values.length,
                value: values.map(value => getConnectionName(t, value)).join(', '),
            })}
            title={t('partner.merchants.filter.connection.title')}
            name="connection"
            values={values}
            options={options}
            onApply={(name, values) => {
                onChange({ [name]: values });
            }}
            onDelete={name => {
                onChange({ [name]: []});
            }}
            onCloseMenu={onClose}
        />
    );
};

export default ConnectionFilter;
