import { Route } from 'react-router-dom';
import AllowRoute from '../auth/accessToken/components/AllowRoute';
import WithSubAccountAccessToken from '../auth/accessToken/components/WithSubAccountAccessToken';
import { WithNotFound } from '../errors';
import MerchantDetails from './merchantDetails/components/MerchantPage';
import Merchants from './merchants/components/Merchants';
import { NewAccount } from './newAccount';

const PartnerRoutes = () => (
    <WithNotFound>
        <AllowRoute path={`/:accountId/sub-accounts/new`} exact component={NewAccount} anyOfScopes={[['admin:accounts'], ['write:accounts']]} />
        <Route
            path={`/:accountId/sub-accounts/:subAccountId`}
            exact
            render={(props) => (
                <WithSubAccountAccessToken {...props}>
                    <MerchantDetails />
                </WithSubAccountAccessToken>
            )}
        />
        <Route path={`/:accountId/sub-accounts`} exact component={Merchants} />
    </WithNotFound>
);

export default PartnerRoutes;
