import useSWR from 'swr';
import { useAccountId } from '../../../../auth/useAccountId';
import useStore from '../../../../common/hooks/useExternalStore';
import useHasAnyOfScopes from '../../../../common/hooks/useHasAnyOfScopes';
import { actions, clientDetailsStore } from '../observables/clientDetailsStore';

export const useClient = (clientId: string) => {
    const accountId = useAccountId();
    const hasScopesForRead = useHasAnyOfScopes(['read:accounts', 'admin:accounts']);
    const { state } = useStore(clientDetailsStore);
    const { data: client, isLoading: isLoadingClient } = useSWR(() => accountId && hasScopesForRead ? {
        key: `client-${accountId}-${clientId}`,
        accountId,
        clientId,
    } : null, actions.getClient);
    const { data: grants, isLoading: isLoadingGrants } = useSWR(() => accountId && hasScopesForRead ? {
        key: `client-grants-${accountId}-${client?.client_id}`,
        accountId,
        clientId: client?.client_id,
    } : null, actions.getGrants);

    return {
        ...state,
        client,
        grants: grants || [],
        isLoading: isLoadingClient || isLoadingGrants,
    };
};
