import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { useSelector } from 'react-redux';
import { namespace } from '..';
import { useActions } from '../../../../Actions';
import { useAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { LoadingOverlay } from '../../../../components/Loading';
import Modal from '../../../../components/Modal';
import { H1, P } from '../../../../components/Typography';
import { distances } from '../../../../styles/constants';
import { SwishModalState } from '../actions';
import SwishForm from './SwishForm';

const SwishModal = () => {
    const { t } = useTranslation();
    const { prodAccountId } = useAccountIds();
    const swish = useSelector(state => state[namespace]);
    const { closeSwishModal } = useActions('connections.swish');

    if (!prodAccountId) {
        return null;
    }

    if (swish.swishModalState === SwishModalState.Closed) {
        return null;
    }

    const account = swish.account;

    if (!account) {
        return <LoadingOverlay />;
    }

    return (
        <Modal fixedWidth>
            {[SwishModalState.ApplicationFormOpened, SwishModalState.InitiateApplicationLoading].includes(
                swish.swishModalState
            ) && (
                <>
                    <H1>{t('settings.payment_connections.swish_modal.title_intro')}</H1>
                    <SwishForm account={account} accountId={prodAccountId} />
                    {swish.swishModalState === SwishModalState.InitiateApplicationLoading && (
                        <LoadingOverlay />
                    )}
                </>
            )}
            {swish.swishModalState === SwishModalState.ApplicationReceived && (
                <>
                    <H1>{t('settings.payment_connections.swish_modal.title_last_step')}</H1>

                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.swish_modal.last_step" />
                        </P>
                    </SpacedWrapper>

                    <ButtonGroup>
                        <Button onClick={closeSwishModal}>{t('settings.payment_connections.swish_modal.close')}</Button>
                    </ButtonGroup>
                </>
            )}
            {swish.swishModalState === SwishModalState.SwishApiError && (
                <>
                    <H1>{t('settings.payment_connections.swish_modal.title_error')}</H1>e
                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.swish_callback_modal.api_error" />
                        </P>
                    </SpacedWrapper>
                    <ButtonGroup>
                        <Button onClick={closeSwishModal}>
                            {t('settings.payment_connections.swish_callback_modal.close')}
                        </Button>
                    </ButtonGroup>
                </>
            )}
        </Modal>
    );
};

export default SwishModal;

const SpacedWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
