import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { distances } from '../../../../styles/constants';
import Page from '../../../../components/Page';
import { LoadingOverlay } from '../../../../components/Loading';
import { H1 } from '../../../../components/Typography';
import Details from './Details';
import useAccount from '../../hooks/useAccount';

const AccountPage = () => {
    const { t } = useTranslation('app');
    const { account, isLoading } = useAccount();
    return (
        <Page title={<Title>{t('settings.account.title')}</Title>}>
            <Wrapper>
                {account && <Details account={account} isLoading={isLoading} />}
                {isLoading && <LoadingOverlay />}
            </Wrapper>
        </Page>
    );
};

const Wrapper = styled.div`
    position: relative;
    min-height: calc(${distances.ginormous} + ${distances.large});
`;

const Title = styled(H1)`
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default AccountPage;
