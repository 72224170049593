import React, { useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useTranslation } from 'react-i18next';
import Card from '../../../../components/Card';
import Icon from '../../../../components/Icons';
import { LoadingOverlay } from '../../../../components/Loading';
import Page, { Back, PageButton } from '../../../../components/Page';
import { H1 } from '../../../../components/Typography';
import { colors, distances } from '../../../../styles/constants';
import { useClient } from '../hooks/useClient';
import { actions } from '../observables/clientDetailsStore';
import DeleteClientModal from './DeleteClientModal';
import DeleteGrantModal from './DeleteGrantModal';
import Details from './Details';
import NotFound from './NotFound';
import RotateSecretModal from './RotateSecretModal';

const ClientPage = () => {
    const { t } = useTranslation();
    const { accountId, clientId } = useParams();
    const client = useClient(clientId);


    useEffect(() => {
        return () => {
            actions.resetState();
        };
    }, []);

    if (client.isDeleted) {
        return <Redirect to={`/${accountId}/settings/clients`} />;
    }

    return (
        <Page
            title={
                <React.Fragment>
                    <Back to={`/${accountId}/settings/clients`} />
                    <Title>{t('settings.client.title')}</Title>
                    <PageButton
                        onClick={actions.openDeleteClientModal}
                        disabled={!client.client || client.client.deleted_at !== undefined}
                    >
                        <Icon icon="delete" />
                        {t('settings.client.delete_client')}
                    </PageButton>
                    <PageButton
                        onClick={actions.openRotateSecretModal}
                        disabled={!client.client || client.client.deleted_at !== undefined}
                    >
                        <Icon icon="revert" />
                        {t('settings.client.rotate_secret')}
                    </PageButton>
                </React.Fragment>
            }
        >
            <Card title={undefined} noPadding>
                <Wrapper>
                    {client.client && (
                        <Details
                            accountId={accountId}
                            client={client.client}
                            grants={client.grants}
                            openDeleteGrantModal={actions.openDeleteGrantModal}
                        />
                    )}
                    {!client.isLoading && !client.client && <NotFound clientId={clientId} accountId={accountId} />}
                    {client.isLoading && <LoadingOverlay />}
                </Wrapper>
            </Card>
            {client.client && client.isShowingDeleteModal && (
                <DeleteClientModal
                    accountId={accountId}
                    client={client.client}
                    deleteClient={actions.deleteClient}
                    dismissDeleteClientModal={actions.dismissDeleteClientModal}
                />
            )}
            {client.client && client.isShowingRotateSecretModal && (
                <RotateSecretModal
                    accountId={accountId}
                    client={client.client}
                    grants={client.grants}
                    rotated={client.rotated}
                    isSecretSafe={client.isSecretSafe}
                    dismissRotateSecretModal={actions.dismissRotateSecretModal}
                    rotateSecret={actions.rotateSecret}
                    toggleSecretIsSafe={actions.toggleSecretIsSafe}
                />
            )}
            {client.client && client.deleteGrant && client.isShowingDeleteGrantModal && (
                <DeleteGrantModal
                    accountId={accountId}
                    client={client.client}
                    grant={client.deleteGrant}
                    dismissDeleteGrantModal={actions.dismissDeleteGrantModal}
                    deleteGrant={actions.deleteGrant}
                />
            )}
        </Page>
    );
};

const Wrapper = styled.div`
    position: relative;
    min-height: calc(${distances.ginormous} + ${distances.large});
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default ClientPage;
