import React, { useMemo } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { AuthenticatedUser } from '../../types';
import { useAccountId } from '../../useAccountId';
import useAuth from '../hooks/useAuth';

// A HOC that injects urlAccountId, prodAccountId and accountId props.

interface MatchParams {
    accountId: string;
}

export interface AccountIdsProps {
    urlAccountId: string; // The one in the url param :accountId
    prodAccountId?: string; // The production version of the account in the url
    accountIds: string[]; // Either only test or both test and prod ids
    baseAccountId: string; // AccountId without T or P prefix
    isTest: boolean; // Is the account a test account
}

export const withAccountIds = <WrappedProps extends AccountIdsProps>(
    WrappedComponent: React.ComponentType<WrappedProps>
) => {
    type Excluded = Exclude<WrappedProps, AccountIdsProps>;
    type HocOnly = {
        user: AuthenticatedUser;
    };

    type HocProps = Excluded & HocOnly & RouteComponentProps<MatchParams>;

    const WithAccountIds = (props: HocProps) => {
        const user = useAuth(state => state.account_user);
        const { ...restProps } = props as {};
        const allAccountIds = user.accounts.map(x => x.account_id);
        const urlAccountId = props.match.params.accountId;
        const accountIds = allAccountIds.filter(a => a.substr(1) === urlAccountId.substring(1));
        const prodAccountId = accountIds.find(a => a.startsWith('P'));
        const baseAccountId = urlAccountId.replace(/^[T|P]/i, '');
        const isTest = urlAccountId.startsWith('T');
        return (
            <WrappedComponent
                {...restProps as WrappedProps}
                urlAccountId={urlAccountId}
                prodAccountId={prodAccountId}
                accountIds={accountIds}
                baseAccountId={baseAccountId}
                isTest={isTest}
            />
        );
    };

    WithAccountIds.displayName = `withAccountIds(${WrappedComponent.name})`;

    return withRouter(WithAccountIds) as any;
};

export const useAccountIds = () => {
    const user = useAuth(state => state.account_user);
    const urlAccountId = useAccountId();
    return useMemo(() => {
        const allAccountIds = user.accounts.map(x => x.account_id);
        const accountIds = allAccountIds.filter(a => a.substr(1) === urlAccountId.substring(1));
        const baseAccountId = urlAccountId.replace(/^[T|P]/i, '');
        const isTest = urlAccountId.startsWith('T');
        return {
            urlAccountId,
            allAccountIds,
            accountIds,
            prodAccountId: accountIds.find(a => a.startsWith('P')),
            testAccountId: accountIds.find(a => a.startsWith('T')),
            baseAccountId: baseAccountId,
            // This one is always defined, as opposed to the other one which is only defined if it exists
            optimisticProdAccountId: `P${baseAccountId}`,
            isTest,
        };
    }, [user.accounts, urlAccountId]);
};
