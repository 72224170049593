import type React from 'react';
import createExternalStore from '../../common/utils/createExternalStore';

type StickyAlert = {
    id: string;
    message: string;
    severity: 'error' | 'warning' | 'info' | 'success';
    icon?: React.ReactNode;
    autoHideDuration?: number;
    onClose?: () => void;
    timer?: NodeJS.Timeout;
}

export type State = {
    alert: StickyAlert | undefined;
};

export const initialState: State = {
    alert: undefined,
};

export const stickyAlertStore = createExternalStore(initialState, {
    setAlert: (state, alert: StickyAlert) => {
        if (state.alert?.timer) {
            clearTimeout(state.alert.timer);
        }
        return {
            ...state,
            alert,
        };
    },
    clearAlert: (state) => {
        if (state.alert?.timer) {
            clearTimeout(state.alert.timer);
        }
        return {
            ...state,
            alert: undefined,
        };
    },
}, 'stickyAlertStore');

export const setAlert = (alert: Omit<StickyAlert, 'id' | 'timer'>) => {
    const id = Math.random().toString(36).substring(7);
    const autoHideDuration = alert.autoHideDuration === 0 ? undefined : alert.autoHideDuration ?? 3000;
    const onClose = alert.onClose ? alert.onClose : () => {
        stickyAlertStore.dispatch('clearAlert');
    };
    const timer = autoHideDuration ? setTimeout(() => {
        stickyAlertStore.dispatch('clearAlert');
    }, autoHideDuration) : undefined;
    stickyAlertStore.dispatch('setAlert', { ...alert, id, timer, onClose });
};
export const removeAlert = () => {
    stickyAlertStore.dispatch('clearAlert');
};
