import useSWR from 'swr';
import useHasAnyOfScopes from '../../../../common/hooks/useHasAnyOfScopes';
import { CORE_API_HOSTNAME } from '../../../../env';
import fulfill from '../../../../fulfill';
import type { Client, Grant } from '../../../../types/management-auth';
import { sortElements } from '../../../helpers';

const chunkFetchGrants = async (accountId: string, clients: Client[]): Promise<Grant[]> => {
    const maxChunkSize = 30;
    const clientIds = clients.map(client => client.client_id);
    const chunks = [];
    for (let i = 0; i < clientIds.length; i += maxChunkSize) {
        chunks.push(clientIds.slice(i, i + maxChunkSize));
    }
    const grants: Grant[] = [];
    for (const chunk of chunks) {
        const grantsChunk: Grant[] = await fulfill.get({
            accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/auth/client-grants?client_id=${chunk.join(',')}`,
            handlers: {
                200: (grants: Grant[]) => grants,
            },
        });
        grants.push(...grantsChunk);
    }
    return grants;
};

const fetchClients = async (payload: {
    accountId: string;
}) => {
    const clients: Client[] = await fulfill.get({
        accountId: payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/auth/clients`,
        handlers: {
            200: (clients: Client[]) => clients,
        },
    });
    const grants: Grant[] = await chunkFetchGrants(payload.accountId, clients);
    const grantsByClientId = grants.reduce((acc: { [clientId: string]: Grant[] }, grant) => {
        if (grant.client_id in acc) {
            return {
                ...acc,
                [grant.client_id]: [...acc[grant.client_id], grant],
            };
        }
        return {
            ...acc,
            [grant.client_id]: [grant],
        };
    }, {});
    return {
        clients: sortElements(clients),
        grants: grantsByClientId,
    };
};

export const useClients = (accountId: string) => {
    const hasScopesForRead = useHasAnyOfScopes(['read:accounts', 'admin:accounts']);
    const { data, isLoading, mutate } = useSWR(() => accountId && hasScopesForRead ? {
        key: `clients-${accountId}`,
        accountId,
    } : null, fetchClients);

    const clients = data?.clients || [];
    const grants = data?.grants || {};

    return {
        clients,
        grants,
        isLoading,
        refresh: mutate,
    };
};
