import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import deepmerge from 'deepmerge';

import { Button, ButtonGroup } from '../../../../components/Buttons';
import { Checkbox, BackButton, Radiobutton, Input, ValidationState } from '../../../../components/Forms';
import { colors, distances, grid, globalColumnMaxWidth } from '../../../../styles/constants';
import Page, { Dismiss, PageButton } from '../../../../components/Page';
import Card from '../../../../components/Card';
import { LoadingOverlay } from '../../../../components/Loading';
import { H1, H2, H3, P } from '../../../../components/Typography';

import { validate, validateRequired, validateUrl } from '../../../../helpers/validation';
import { AccountIdsProps, withAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import Icon from '../../../../components/Icons';
import { NewProfileConfiguration, ProfileConfiguration, PaymentConfiguration, CheckoutConfig } from '../../../../types/checkout';
import { State } from '../reducer';
import ProfilePreview from '../../components/ProfilePreview';
import { getValueAt } from '../../../../helpers/getValueAt';
import AllowPartner from '../../../../auth/accessToken/components/AllowPartner';
import { getProfilePaymentTypeLabel } from '../../../../helpers/getProfilePaymentTypeLabel';
import { allPaymentTypes } from '../../../../util/paymentTypes';
import Hr from '../../../../components/Hr';
import { smsOrPush } from '../../helpers';
import CustomerTypeDropdown from '../../../components/CustomerTypeDropdown';
import AllowDifferentBillingShippingAddressDropdown from '../../../components/AllowDifferentBillingShippingAddressDropdown';
const FALLBACK_MERCHANT_LOGO = 'https://checkout.dintero.com/assets/images/merchants/default.png';

const COUNTRIES = ['NO', 'SE', 'DK', 'FI', 'GB', 'DE'];

interface MatchParams {
    accountId: string;
    profileAccountId: string;
    profileId: string;
}

const buildOption = (path: string[]): any => {
    const [first, ...rest] = path;
    if (rest.length === 0) {
        return {
            [first]: { enabled: true },
        };
    } else {
        return {
            [first]: buildOption(rest),
        };
    }
};

const toConfiguration = (selected: string[], checkoutConfig?: CheckoutConfig): PaymentConfiguration => {
    let newConfigPaymentTypes = [...selected];
    if (checkoutConfig) {
        // Remove the "active_payment_types" option if it exists in teh selection and any of the individual payment types
        // have been disabled.
        if (
            newConfigPaymentTypes.includes('active_payment_types') &&
            newConfigPaymentTypes.length !== (checkoutConfig.configuration.payment_options || []).length + 1
        ) {
            newConfigPaymentTypes = newConfigPaymentTypes.filter(s => s !== 'active_payment_types');
        }

        // Remove any payment types in the selection that are not in the checkout config.
        const checkoutConfigPaymentTypes = (checkoutConfig.configuration.payment_options || []).map(option => option.type) as string[];
        newConfigPaymentTypes = newConfigPaymentTypes.filter(s => {
            if (s === 'active_payment_types') {
                return true;
            }
            return checkoutConfigPaymentTypes.includes(s);
        });
    }

    const paymentConfiguration: PaymentConfiguration = newConfigPaymentTypes.reduce((acc, path) => {
        return deepmerge(acc, buildOption(path.split('.')));
    }, {});
    return paymentConfiguration;
};

export interface EditPaymentProfileProps extends WithTranslation, RouteComponentProps<MatchParams>, AccountIdsProps {
    editPaymentProfile: (accountId: string, profileId: string, paymentProfile: NewProfileConfiguration) => void;
    getCheckoutConfiguration: (accountId: string, profileId: string) => void;
    resetForm: () => void;
    paymentProfile: State;
}

const getPaymentTypes = (profile: ProfileConfiguration, checkoutConfig?: CheckoutConfig) => {
    if (profile.configuration.active_payment_types?.enabled) {
        // Show all checkout payment types as enabled regardless of payment types in profile
        return [
            ...(checkoutConfig?.configuration.payment_options || []).map((option) => option.type),
            'active_payment_types',
        ];
    }
    const types = [
        ...allPaymentTypes,
        'active_payment_types',
    ];
    return types.filter((paymentType) => getValueAt(`configuration.${paymentType}.enabled`, profile));
};

interface ValidatedInputProps extends WithTranslation {
    values: any;
    errors: any;
    path: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
}

const ValidatedInput = (
    {
        t,
        values,
        errors,
        path,
        onChange,
        onBlur,
        touched,
        required,
    }: ValidatedInputProps
) => {
    const value = getValueAt(path, values);
    const error = getValueAt(path, errors);
    const wasTouched = getValueAt(path, touched);
    const validation = error
        ? {
            state: ValidationState.Invalid,
            message: error,
        }
        : undefined;
    const label = t(`settings.edit_payment_profile.fields.${path}`);
    return (
        <InputWrapper>
            <Input
                label={label}
                placeholder={t(`settings.edit_payment_profile.placeholders.${path}`)}
                type="text"
                name={path}
                value={value || ''}
                onChange={(e) => {
                    onChange(e);
                }}
                onBlur={(e) => {
                    onChange(e);
                    onBlur(e);
                }}
                validation={wasTouched && validation}
                required={required}
            />
        </InputWrapper>
    );
};

const TranslatedValidatedInput = withTranslation()(ValidatedInput);

interface FormikValues {
    accountId: string;
    description: string;
    payment_types: string[];
    merchant: {
        name: string;
        logo_url: string;
    };
    url?: {
        merchant_terms_url?: string;
    };
    auto_capture: boolean;
    allow_countries: string[];
    deny_countries: string[];
    preferred_country?: string;
    publish_sms_checkout_link: boolean;
    default_customer_type: 'b2b' | 'b2c';
    allow_different_billing_shipping_address: 'none' | 'b2b' | 'b2c' | 'b2b_b2c';
}

const toAllowDifferentBillingShippingAddress = (value: string[]): 'none' | 'b2b' | 'b2c' | 'b2b_b2c' => {
    const hasB2B = value.includes('b2b');
    const hasB2C = value.includes('b2c');
    if (hasB2B && hasB2C) {
        return 'b2b_b2c';
    } else if (hasB2B) {
        return 'b2b';
    } else if (hasB2C) {
        return 'b2c';
    }
    return 'none';
};

class EditPaymentProfileForm extends Component<EditPaymentProfileProps> {
    componentDidMount() {
        const accountId = this.props.match.params.profileAccountId;
        const profileId = this.props.match.params.profileId;
        this.props.getCheckoutConfiguration(accountId, profileId);
    }

    componentWillUnmount() {
        this.props.resetForm();
    }

    render() {
        const { t, paymentProfile } = this.props;
        const { isLoading } = this.props.paymentProfile;
        const profile = paymentProfile.profile;
        const accountId = this.props.match.params.profileAccountId;
        const profileId = this.props.match.params.profileId;

        const checkoutConfiguration = paymentProfile.checkoutConfiguration;

        if (paymentProfile.result) {
            return (
                <Redirect
                    to={`/${paymentProfile.result.accountId}/settings/payment-profiles/${paymentProfile.result.accountId}/${paymentProfile.result.paymentProfile.id}`}
                />
            );
        }

        return (
            <Formik
                enableReinitialize
                initialValues={
                    {
                        merchant: {
                            name: (profile && profile.merchant && profile.merchant.name) || '',
                            logo_url:
                                (profile &&
                                    profile.merchant &&
                                    profile.merchant.logo_url &&
                                    !profile.merchant.logo_url.endsWith('/assets/images/merchants/default.png') &&
                                    profile.merchant.logo_url) ||
                                '',
                        },
                        url: {
                            merchant_terms_url:
                                (profile &&
                                    profile.url &&
                                    profile.url.merchant_terms_url &&
                                    profile.url.merchant_terms_url) ||
                                undefined,
                        },
                        accountId: accountId,
                        description: (profile && profile.description) || '',
                        payment_types: (profile && getPaymentTypes(profile, checkoutConfiguration)) || [],
                        publish_sms_checkout_link:
                            (profile &&
                                profile.configuration.publish &&
                                profile.configuration.publish.find(
                                    (p) => p.type === 'checkout-link' && p.channel === 'sms'
                                ) &&
                                true) ||
                            false,
                        auto_capture: (profile && profile.configuration.auto_capture) || false,
                        allow_countries: profile?.configuration?.countries?.allow_countries || [],
                        deny_countries: profile?.configuration?.countries?.deny_countries || [],
                        preferred_country: profile?.configuration?.countries?.preferred_country,
                        default_customer_type: profile?.configuration?.default_customer_type || 'b2c',
                        allow_different_billing_shipping_address: toAllowDifferentBillingShippingAddress(profile?.configuration?.allow_different_billing_shipping_address || []),
                    } as FormikValues
                }
                validate={(values) => {
                    let errors: { [key: string]: string } = {};

                    const validators = [
                        {
                            path: 'description',
                            validator: validateRequired(t('settings.edit_payment_profile.invalid.description')),
                        },
                        {
                            path: 'merchant.name',
                            validator: validateRequired(t('settings.edit_payment_profile.invalid.merchant.name')),
                        },
                        {
                            path: 'merchant.logo_url',
                            validator: validateUrl(
                                t('settings.edit_payment_profile.invalid.merchant.logo_url.invalid')
                            ),
                        },
                        {
                            path: 'url.merchant_terms_url',
                            validator: validateUrl(t('settings.edit_payment_profile.invalid.url.merchant_terms_url')),
                        },
                    ];
                    errors = validators.reduce((acc, elem) => {
                        return validate(elem.path, elem.validator, values, acc);
                    }, errors);

                    if (values.payment_types.length === 0) {
                        errors['payment_types'] = t('settings.edit_payment_profile.invalid.payment_types');
                    }
                    return errors;
                }}
                validateOnChange={true}
                onSubmit={(values) => {
                    const merchant_terms_url =
                        values.url && values.url.merchant_terms_url ? values.url.merchant_terms_url : undefined;
                    const url = values.url ? { ...values.url, merchant_terms_url } : undefined;
                    const changedProfile = {
                        description: values.description,
                        configuration: toConfiguration(values.payment_types, checkoutConfiguration),
                        merchant: {
                            ...values.merchant,
                        },
                        url,
                    };
                    if (values.publish_sms_checkout_link) {
                        changedProfile.configuration.publish = [
                            {
                                channel: 'sms',
                                type: 'checkout-link',
                            },
                        ];
                    }
                    if (!changedProfile.merchant.logo_url) {
                        changedProfile.merchant.logo_url = FALLBACK_MERCHANT_LOGO;
                    }
                    if (values.auto_capture) {
                        changedProfile.configuration.auto_capture = values.auto_capture;
                    }
                    if (values.default_customer_type === 'b2b') {
                        changedProfile.configuration.default_customer_type = values.default_customer_type;
                    }
                    const mapping = {
                        'none': [],
                        b2b: ['b2b'],
                        b2c: ['b2c'],
                        b2b_b2c: ['b2b', 'b2c'],
                    };
                    changedProfile.configuration.allow_different_billing_shipping_address = (mapping[values.allow_different_billing_shipping_address] || []) as ('b2b' | 'b2c')[];;
                    if (
                        values.allow_countries.length > 0 ||
                        values.deny_countries.length > 0 ||
                        values.preferred_country
                    ) {
                        changedProfile.configuration.countries = {};
                        if (values.allow_countries.length > 0) {
                            changedProfile.configuration.countries.allow_countries = values.allow_countries;
                        }
                        if (values.deny_countries.length > 0) {
                            changedProfile.configuration.countries.deny_countries = values.deny_countries;
                        }
                        if (values.preferred_country) {
                            changedProfile.configuration.countries.preferred_country = values.preferred_country;
                        }
                    }
                    this.props.editPaymentProfile(accountId, profileId, changedProfile);
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    touched,
                    setFieldTouched,
                }) => {
                    return (
                        <Form onSubmit={handleSubmit} autoComplete="nope">
                            <Page
                                title={
                                    <>
                                        <Dismiss />
                                        <Title>{t('settings.edit_payment_profile.title')}</Title>
                                        <PageButton
                                            type="submit"
                                            disabled={
                                                Object.keys(errors || {}).length > 0 ||
                                                Object.keys(touched || {}).length === 0
                                            }
                                        >
                                            <Icon icon="save" fill="currentColor" />
                                            {t('settings.edit_payment_profile.save_changes')}
                                        </PageButton>
                                    </>
                                }
                            >
                                <Card title={undefined}>
                                    <Wrapper>
                                        <FormGroup>
                                            <H2>{t('settings.edit_payment_profile.payment_profile')}</H2>
                                            <Hr />
                                            <Flex>
                                                <TranslatedValidatedInput
                                                    path="description"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                />

                                                <TranslatedValidatedInput
                                                    path="merchant.name"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                />
                                            </Flex>
                                            <Flex>

                                                <TranslatedValidatedInput
                                                    path="merchant.logo_url"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                />

                                                <TranslatedValidatedInput
                                                    path="url.merchant_terms_url"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                />
                                            </Flex>
                                            {paymentProfile.checkoutConfiguration && (
                                                <PreviewWrapper>
                                                    <ProfilePreview
                                                        configuration={
                                                            paymentProfile.checkoutConfiguration
                                                        }
                                                        profile={
                                                            {
                                                                merchant: values.merchant,
                                                                configuration: toConfiguration(values.payment_types),
                                                            } as ProfileConfiguration
                                                        }
                                                        countryCode={
                                                            paymentProfile.account?.company?.address?.country ||
                                                            undefined
                                                        }
                                                    />
                                                </PreviewWrapper>
                                            )}
                                        </FormGroup>
                                        <H2>{t('settings.edit_payment_profile.payment_methods')}</H2>
                                        <Hr />
                                        <FormGroup>
                                            <OptionList>
                                                {checkoutConfiguration?.configuration.payment_options.map((option) => (
                                                    <Checkbox
                                                        key={option.type}
                                                        name="payment_types"
                                                        label={getProfilePaymentTypeLabel(option.type, t)}
                                                        checked={values.payment_types.includes(option.type)}
                                                        value={option.type}
                                                        onChange={handleChange}
                                                        setFieldTouched={setFieldTouched}
                                                        onBlur={handleBlur}
                                                    />
                                                ))}
                                            </OptionList>
                                            {errors.payment_types && touched.payment_types && (
                                                <InvalidMessage>{errors.payment_types}</InvalidMessage>
                                            )}
                                        </FormGroup>

                                        <H2>{t('settings.edit_payment_profile.express_settings_title')}</H2>
                                        <Hr />
                                        <FormGroup>
                                            <CustomerTypeDropdown
                                                label={t('settings.edit_payment_profile.fields.default_customer_type')}
                                                name="default_customer_type"
                                                value={values.default_customer_type}
                                                onChange={(value) => {
                                                    setFieldValue('default_customer_type', value);
                                                    setFieldTouched('default_customer_type', true);
                                                }}
                                            />
                                            <AllowDifferentBillingShippingAddressDropdown
                                                label={t('settings.edit_payment_profile.fields.allow_different_billing_shipping_address')}
                                                name="allow_different_billing_shipping_address"
                                                value={values.allow_different_billing_shipping_address}
                                                onChange={(value) => {
                                                    setFieldValue('allow_different_billing_shipping_address', value);
                                                    setFieldTouched('allow_different_billing_shipping_address', true);
                                                }}
                                            />
                                        </FormGroup>

                                        <P>{t('settings.edit_payment_profile.countries_description')}</P>
                                        <br />
                                        {values.allow_countries.length === 0 && values.deny_countries.length === 0 && (
                                            <FormGroup>
                                                <Button
                                                    className={'alt tiny'}
                                                    onClick={() => {
                                                        setFieldValue('allow_countries', COUNTRIES);
                                                        setFieldTouched('allow_countries', true);
                                                    }}
                                                >
                                                    {t('settings.edit_payment_profile.countries_enable_button')}
                                                </Button>
                                            </FormGroup>
                                        )}

                                        {values.allow_countries.length !== 0 && (
                                            <>
                                                <H3>{t('settings.edit_payment_profile.countries_allow_title')}</H3>
                                                <FormGroup>
                                                    <OptionList>
                                                        {COUNTRIES.map((x) => (
                                                            <Checkbox
                                                                key={x}
                                                                name={`allow_countries`}
                                                                label={t(`settings.country_options.${x}`, {
                                                                    defaultValue: x,
                                                                })}
                                                                checked={(values.allow_countries || []).includes(x)}
                                                                value={x}
                                                                onChange={handleChange}
                                                                setFieldTouched={setFieldTouched}
                                                                onBlur={handleBlur}
                                                                disabled={values.deny_countries.includes(x)}
                                                            />
                                                        ))}
                                                    </OptionList>
                                                </FormGroup>
                                            </>
                                        )}
                                        {values.deny_countries.length > 0 && (
                                            <>
                                                <H3>{t('settings.edit_payment_profile.countries_deny_title')}</H3>
                                                <FormGroup>
                                                    <OptionList>
                                                        {COUNTRIES.map((x) => (
                                                            <Checkbox
                                                                key={x}
                                                                name={`deny_countries`}
                                                                label={t(`settings.country_options.${x}`, {
                                                                    defaultValue: x,
                                                                })}
                                                                checked={values.deny_countries.includes(x)}
                                                                value={x}
                                                                onChange={handleChange}
                                                                setFieldTouched={setFieldTouched}
                                                                onBlur={handleBlur}
                                                                disabled={values.allow_countries.includes(x)}
                                                            />
                                                        ))}
                                                    </OptionList>
                                                </FormGroup>
                                            </>
                                        )}
                                        {(values.preferred_country ||
                                            values.allow_countries.length > 0 ||
                                            values.deny_countries.length > 0) && (
                                            <>
                                                <H3>{t('settings.edit_payment_profile.countries_prefer_title')}</H3>
                                                <FormGroup>
                                                    <P>
                                                        {t(
                                                            'settings.edit_payment_profile.countries_prefer_description'
                                                        )}
                                                    </P>
                                                    <br />
                                                    <OptionList>
                                                        {(values.allow_countries.length > 0
                                                            ? values.allow_countries
                                                            : COUNTRIES
                                                        )
                                                            .filter((x) => !values.deny_countries.includes(x))
                                                            .map((x) => (
                                                                <Radiobutton
                                                                    key={x}
                                                                    name={`deny_countries`}
                                                                    label={t(`settings.country_options.${x}`, {
                                                                        defaultValue: x,
                                                                    })}
                                                                    value={values.preferred_country === x}
                                                                    onChange={(name, value) => {
                                                                        setFieldValue('preferred_country', x);
                                                                        setFieldTouched('preferred_country', true);
                                                                    }}
                                                                />
                                                            ))}
                                                        <Radiobutton
                                                            key={'undefined'}
                                                            name={`deny_countries`}
                                                            label={t(`settings.country_options.none`, {
                                                                defaultValue: 'None',
                                                            })}
                                                            value={!values.preferred_country}
                                                            onChange={(name, value) => {
                                                                setFieldValue('preferred_country', undefined);
                                                                setFieldTouched('preferred_country', true);
                                                            }}
                                                        />
                                                    </OptionList>
                                                </FormGroup>
                                            </>
                                        )}

                                        <H2>{t('settings.edit_payment_profile.publish_title')}</H2>
                                        <Hr />
                                        <FormGroup>
                                            <P>
                                                {t(
                                                    `settings.edit_payment_profile.about_publish_${smsOrPush(
                                                        (
                                                            paymentProfile.checkoutConfiguration|| {
                                                                configuration: { payment_options: []},
                                                            }
                                                        ).configuration.payment_options.map((v) => v.type),
                                                        values.payment_types
                                                    )}`
                                                )}
                                            </P>
                                            <br />
                                            <OptionList>
                                                <Checkbox
                                                    name="publish_sms_checkout_link"
                                                    label={t(
                                                        `settings.edit_payment_profile.fields.publish_${smsOrPush(
                                                            (
                                                                paymentProfile.checkoutConfiguration || {
                                                                    configuration: { payment_options: []},
                                                                }
                                                            ).configuration.payment_options.map((v) => v.type),
                                                            values.payment_types
                                                        )}_checkout_link_checkout_link`
                                                    )}
                                                    checked={values.publish_sms_checkout_link}
                                                    setFieldTouched={setFieldTouched}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </OptionList>
                                        </FormGroup>

                                        <AllowPartner
                                            visibleFrame
                                            fallback={
                                                <>
                                                    <H2>{t('settings.edit_payment_profile.payment_settings_title')}</H2>
                                                    <Hr />
                                                    <P>
                                                        {t(
                                                            'settings.edit_payment_profile.auto_capture_non_root_message'
                                                        )}
                                                    </P>
                                                    <br />
                                                    <FormGroup>
                                                        <OptionList>
                                                            <Checkbox
                                                                name="auto_capture"
                                                                label={t(
                                                                    `settings.edit_payment_profile.fields.auto_capture`
                                                                )}
                                                                checked={values.auto_capture}
                                                                disabled
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                setFieldTouched={setFieldTouched}
                                                            />
                                                        </OptionList>
                                                    </FormGroup>
                                                </>
                                            }
                                        >
                                            <>
                                                <H2>{t('settings.edit_payment_profile.payment_settings_title')}</H2>
                                                <Hr />
                                                <FormGroup>
                                                    <OptionList>
                                                        <Checkbox
                                                            name="auto_capture"
                                                            label={t(
                                                                `settings.edit_payment_profile.fields.auto_capture`
                                                            )}
                                                            checked={values.auto_capture}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            setFieldTouched={setFieldTouched}
                                                        />
                                                    </OptionList>
                                                </FormGroup>
                                            </>
                                        </AllowPartner>

                                        <ButtonGroup>
                                            <BackButton children={t('settings.edit_payment_profile.abort')} />
                                            <Button
                                                type="submit"
                                                disabled={
                                                    Object.keys(errors || {}).length > 0 ||
                                                    Object.keys(touched || {}).length === 0
                                                }
                                            >
                                                {t('settings.edit_payment_profile.save_changes')}
                                            </Button>
                                        </ButtonGroup>
                                        {isLoading && <LoadingOverlay />}
                                    </Wrapper>
                                </Card>
                            </Page>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

export default withAccountIds(withTranslation()(EditPaymentProfileForm));

const Form = styled.form`
    width: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
`;

const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Flex = styled.div`
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const OptionList = styled.div`
    display: flex;
    flex-direction: column;
    > label {
        padding-bottom: ${distances.small};
    }
`;
const InputWrapper = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
    }
`;



const InvalidMessage = styled.span`
    position: absolute;
    font-size: 11px;
    line-height: ${distances.small};
    color: ${colors.invalid};
`;

const PreviewWrapper = styled.div`
    padding: ${distances.normal};
`;
