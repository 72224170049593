import useSWR from 'swr';
import { useAccountId } from '../../../../auth/useAccountId';
import useStore from '../../../../common/hooks/useExternalStore';
import useHasAnyOfScopes from '../../../../common/hooks/useHasAnyOfScopes';
import { actions, editUserStore } from '../observables/editUserStore';

export const useEditUser = (userId: string) => {
    const { state } = useStore(editUserStore);
    const hasScopesForRead = useHasAnyOfScopes(['read:accounts', 'admin:accounts']);
    const accountId = useAccountId();
    const { data, isLoading } = useSWR(() => accountId && userId && hasScopesForRead ? {
        accountId,
        userId,
    } : null, actions.getUser);

    return {
        ...state,
        user: data?.user,
        isLoading: state.isLoading || isLoading,
    };
};
