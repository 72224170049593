import { useCallback, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useActions } from '../../../../Actions';
import { fetchAccount } from '../actions';
import { newLocationStateSelector } from '../selectors';

import Card from '../../../../components/Card';
import { LoadingOverlay } from '../../../../components/Loading';
import Page, { Back, Title } from '../../../../components/Page';

import { useAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import InvalidAddressModal from '../../shared/InvalidAddressModal';
import LocationForm from '../../shared/LocationForm';
import { getTypeOptions } from '../../shared/options';
import { getGeoLocation, getPhonePrefixFromCountry, mapValues } from '../../shared/utils';
import { LocationFormValues } from '../../types';
import NewLocationEntry, { NewLocationEntryValues } from '../components/NewLocationEntry';

const newLocationToPrefilledAccount = ({
    countryCode,
    ...entry
}: NewLocationEntryValues, t: TFunction<'app'>): LocationFormValues => ({
    country: {
        label: countryCode === 'NO'
            ? t('locations.location_form.fields.norway')
            : t('locations.location_form.fields.sweden'),
        value: countryCode,
    },
    addressLine: '',
    addressLine2: '',
    businessName: '',
    countryPrefix: getPhonePrefixFromCountry(countryCode),
    phoneNumber: '',
    email: '',
    franchise: false,
    franchiseName: '',
    latitude: 0,
    longitude: 0,
    name: '',
    postalCode: '',
    postalPlace: '',
    websiteUrl: '',
    type: getTypeOptions(t)[0],
    ...entry as any,
});

export default function NewLocationRoute() {
    const { t, i18n } = useTranslation('app');
    const history = useHistory();
    const dispatch = useDispatch();
    const getPayoutConfig = useActions('payout.config').getAccountConfig;
    const createLocation = useActions('settings.locations').createLocation;
    const [locationFormValues, setLocationFormValues] = useState<LocationFormValues>();
    // todo: Move to LocationForm?
    const [invalidAddress, setInvalidAddress] = useState(false);

    const {
        urlAccountId,
    } = useAccountIds();
    const {
        isLoading,
        account,
    } = useSelector(newLocationStateSelector);

    useEffect(() => {
        if (!account && !isLoading) {
            dispatch(fetchAccount(urlAccountId));
        }
    }, [dispatch, account, isLoading, urlAccountId]);

    useEffect(() => {
        getPayoutConfig(urlAccountId);
    }, [urlAccountId, getPayoutConfig]);

    const onContinue = useCallback(async (entry: NewLocationEntryValues) => {
        setLocationFormValues(newLocationToPrefilledAccount(entry, t));
    }, [t]);

    const onSubmit = useCallback(async (values: LocationFormValues) => {
        setLocationFormValues(values);
        const location = mapValues(values);
        const geoLocation = await getGeoLocation(location.address!, i18n.language);

        await createLocation({
            ...location,
            address: {
                ...location.address!,
                ...(geoLocation || {}),
            },
        }, values.accountId);
        history.push(`/${urlAccountId}/settings/locations`);
    }, [urlAccountId, createLocation, history, i18n.language]);

    if (isLoading || !account) {
        return <LoadingOverlay />;
    }

    return (
        <Page title={<>
            <Back />
            <Title>{t('locations.new_location.title')}</Title>
        </>}>
            <Card title={t('locations.new_location.subtitle')}>
                <NewLocationEntry
                    initialValues={{
                        countryCode: account?.company?.address?.country,
                        accountId: urlAccountId,
                    }}
                    onSubmit={onContinue}
                    onChange={() => {
                        setLocationFormValues(undefined); // need to click continue again
                    }}
                    disabled={isLoading || !!locationFormValues}
                >
                    {locationFormValues &&
                        <LocationForm
                            locationFormValues={locationFormValues}
                            onSubmit={onSubmit}
                            withGrid={false}
                        />
                    }
                </NewLocationEntry>
            </Card>
            {invalidAddress &&
                <InvalidAddressModal dismissModal={() => setInvalidAddress(false)} />
            }
        </Page>
    );
}
