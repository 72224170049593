import { reducer as newPartner, namespace as newPartnerNamespace } from './newAccount';

export interface State {
    [newPartnerNamespace]: newPartner.State;
}

export const initialState: State = {
    [newPartnerNamespace]: newPartner.initialState,
};

export const reducers = {
    [newPartnerNamespace]: newPartner.reducer,
};
