import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Formik } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js';

import { distances, grid } from '../../../../styles/constants';
import { H1, P } from '../../../../components/Typography';
import Modal from '../../../../components/Modal';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { InstabankModalState, State } from '../reducer';
import { LoadingOverlay } from '../../../../components/Loading';
import {
    validate,
    validateEmail,
    validateNorwegianBankAccountNumber,
    validatePhoneNumber,
    validateRequired
} from '../../../../helpers/validation';
import { FormattedInput, Input, PhoneNumber, ValidationState } from '../../../../components/Forms';
import { CleaveOptions } from 'cleave.js/options';
import { AccountIdsProps, withAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import NumberOfTransactionsDropdown, {
    optionValues as numberOfTransactionsValues
} from './NumberOfTransactionsDropdown';
import TransactionsVolumeDropdown, { optionValues as transactionsVolumeValues } from './TransactionsVolumeDropdown';
import { InstabankConnectionSignup } from '../../../../types/management-auth';
import { getValueAt } from '../../../../helpers/getValueAt';

const splitApplicantPhoneNumber = (phoneNumber: string | undefined) => {
    if (phoneNumber) {
        try {
            const parsed = parsePhoneNumber(phoneNumber);
            if (parsed) {
                return [`+${parsed.countryCallingCode}`, parsed.formatNational() as string];
            }
        } catch {
            // ingnore errors
        }
    }
    return ['+47', ''];
};

interface ValidatedInputProps extends WithTranslation {
    values: any;
    initialValues: any;
    errors: any;
    path: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    prefix?: string;
    options?: CleaveOptions;
    maxlength?: number;
    formatter?: (value: string) => string;
}

const ValidatedInput = (
    {
        t,
        values,
        initialValues,
        errors,
        path,
        onChange,
        onBlur,
        touched,
        required,
        prefix,
        options,
        maxlength,
    }: ValidatedInputProps
) => {
    const value = getValueAt(path, values);
    const initialValue = getValueAt(path, initialValues);
    const error = getValueAt(path, errors);
    const was_touched = getValueAt(path, touched);
    const validation =
        (was_touched || initialValue) && error
            ? {
                state: ValidationState.Invalid,
                message: error,
            }
            : undefined;
    const label = t(`settings.payment_connections.instabank_modal.fields.${path}`);
    if (options) {
        return (
            <InputWrapper>
                <FormattedInput
                    label={label}
                    placeholder={t(`settings.payment_connections.instabank_modal.placeholders.${path}`)}
                    type="text"
                    name={path}
                    value={value || ''}
                    onChange={(e) => {
                        onChange(e);
                    }}
                    onBlur={(e) => {
                        onChange(e);
                        onBlur(e);
                    }}
                    validation={validation}
                    prefix={prefix}
                    options={options}
                    required={required}
                />
            </InputWrapper>
        );
    }
    return (
        <InputWrapper>
            <Input
                label={label}
                placeholder={t(`settings.payment_connections.instabank_modal.placeholders.${path}`)}
                type="text"
                name={path}
                value={value || ''}
                onChange={(e) => {
                    onChange(e);
                }}
                onBlur={(e) => {
                    onChange(e);
                    onBlur(e);
                }}
                validation={validation}
                prefix={prefix}
                maxlength={maxlength}
                required={required}
            />
        </InputWrapper>
    );
};

const TranslatedValidatedInput = withTranslation()(ValidatedInput);

export interface InstabankModalProps extends WithTranslation, AccountIdsProps {
    initiateInstabankConnection: (accountId: string, data: InstabankConnectionSignup) => void;
    closeInstabankModal: () => void;
    instabank: State;
}

enum InstabankProduct {
    Finance = 'finance',
    Invoice = 'invoice',
}

interface FormikValues {
    email: string;
    phone_number: string;
    country_prefix: string;
    account_number: string;
    total_number_of_transactions: string;
    total_volume_of_transactions: string;
    finance_number_of_transactions: string;
    finance_volume_of_transactions: string;
    industry: string;
    payment_options: InstabankProduct[];
    [key: string]: any;
}

const InstabankModal = (
    {
        t,
        instabank,
        closeInstabankModal,
        initiateInstabankConnection,
        prodAccountId,
    }: InstabankModalProps
) => {
    if (!prodAccountId) {
        return null;
    }
    if (instabank.modalState === InstabankModalState.Closed) {
        return null;
    }
    const account = instabank.account;
    const [country_prefix, phone_number] = splitApplicantPhoneNumber(account ? account.applicant.phone_number : '');
    const initialFormValues = account
        ? {
            email: account.billing.email || '',
            phone_number: phone_number,
            country_prefix: country_prefix,
            account_number: '',
            total_number_of_transactions: numberOfTransactionsValues[0],
            total_volume_of_transactions: transactionsVolumeValues[0],
            finance_number_of_transactions: numberOfTransactionsValues[0],
            finance_volume_of_transactions: transactionsVolumeValues[0],
            industry: account.company.industry || '',
            payment_options: [InstabankProduct.Finance, InstabankProduct.Invoice],
        }
        : undefined;

    const validateValues = (values: FormikValues) => {
        let errors: { [key: string]: string } = {};
        if (!values) {
            // this should not happen but...
            return { error: 'account not loaded yet...' };
        }
        const manipulatedValues = {
            ...values,
            phone_number: values.phone_number ? `${values.country_prefix} ${values.phone_number}` : '',
        };
        const validators = [
            {
                path: 'email',
                validator: validateRequired(t('settings.payment_connections.instabank_modal.invalid.email.required')),
            },
            {
                path: 'email',
                validator: validateEmail(t('settings.payment_connections.instabank_modal.invalid.email.invalid')),
            },
            {
                path: 'phone_number',
                validator: validateRequired(
                    t('settings.payment_connections.instabank_modal.invalid.phone_number.required')
                ),
            },
            {
                path: 'phone_number',
                validator: validatePhoneNumber(
                    t('settings.payment_connections.instabank_modal.invalid.phone_number.invalid')
                ),
            },
            {
                path: 'account_number',
                validator: validateRequired(
                    t('settings.payment_connections.instabank_modal.invalid.account_number.required')
                ),
            },
            {
                path: 'account_number',
                validator: validateNorwegianBankAccountNumber(
                    t('settings.payment_connections.instabank_modal.invalid.account_number.invalid')
                ),
            },
            {
                path: 'industry',
                validator: validateRequired(t('settings.payment_connections.instabank_modal.invalid.industry')),
            },
            {
                path: 'payment_options',
                validator: validateRequired(t('settings.payment_connections.instabank_modal.invalid.payment_options')),
            },
        ];

        errors = validators.reduce((acc, elem) => {
            return validate(elem.path, elem.validator, manipulatedValues, acc);
        }, errors);

        return errors;
    };
    if (!account) {
        return <LoadingOverlay />;
    }
    const initialValidation = validateValues(initialFormValues as FormikValues);
    return (
        (<Modal fixedWidth>
            <H1>{t('settings.payment_connections.instabank_modal.title')}</H1>
            {[InstabankModalState.ApplicationForm, InstabankModalState.InitiateApplicationLoading].includes(
                instabank.modalState
            ) && (
                <Formik
                    enableReinitialize
                    initialValues={initialFormValues as FormikValues}
                    validate={validateValues}
                    initialErrors={initialValidation}
                    onSubmit={(values) => {
                        initiateInstabankConnection(prodAccountId, {
                            email: values.email,
                            phone_number: parsePhoneNumber(`${values.country_prefix}${values.phone_number}`)
                                .number as string,
                            account_number: values.account_number.replace(/\s/g, ''),
                            total_number_of_transactions: t(
                                `settings.payment_connections.number_of_transactions_options.${values.total_number_of_transactions}`
                            ),
                            total_volume_of_transactions: t(
                                `settings.payment_connections.transaction_volume_options.${values.total_volume_of_transactions}`
                            ),
                            finance_number_of_transactions: t(
                                `settings.payment_connections.number_of_transactions_options.${values.finance_number_of_transactions}`
                            ),
                            finance_volume_of_transactions: t(
                                `settings.payment_connections.transaction_volume_options.${values.finance_volume_of_transactions}`
                            ),
                            industry: values.industry,
                            payment_options: values.payment_options.map((p) => ({
                                type: `instabank.${p}`,
                            })) as { type: 'instabank.finance' | 'instabank.invoice' }[],
                        });
                    }}
                >
                    {({ values, errors, handleChange, handleBlur, setFieldValue, touched, handleSubmit, isValid }) => (
                        <form onSubmit={handleSubmit}>
                            <SpacedWrapper>
                                <P>
                                    <Trans i18nKey="settings.payment_connections.instabank_modal.intro" />
                                </P>
                            </SpacedWrapper>
                            <SpacedWrapper>
                                <TranslatedValidatedInput
                                    path="email"
                                    initialValues={initialFormValues}
                                    values={values}
                                    errors={errors}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched}
                                    required
                                />
                                <InputWrapper>
                                    <PhoneNumber
                                        name="phone_number"
                                        label={t('settings.payment_connections.instabank_modal.fields.phone_number')}
                                        phoneNumber={values.phone_number}
                                        countryPrefix={values.country_prefix}
                                        countryPrefixName="country_prefix"
                                        setFieldValue={setFieldValue}
                                        onBlur={handleBlur}
                                        disabled={false}
                                        autoComplete="off"
                                        validation={
                                            touched.phone_number && errors.phone_number
                                                ? {
                                                    state: ValidationState.Invalid,
                                                    message: errors.phone_number,
                                                }
                                                : undefined
                                        }
                                    />
                                </InputWrapper>
                                <TranslatedValidatedInput
                                    path="account_number"
                                    initialValues={initialFormValues}
                                    values={values}
                                    errors={errors}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched}
                                    options={{
                                        numericOnly: true,
                                        blocks: [4, 2, 5],
                                        delimiter: ' ',
                                    }}
                                    required
                                />
                                <NumberOfTransactionsDropdown
                                    label={t(
                                        'settings.payment_connections.instabank_modal.fields.total_number_of_transactions'
                                    )}
                                    placeholder={t(
                                        'settings.payment_connections.instabank_modal.placeholders.total_number_of_transactions'
                                    )}
                                    name="total_number_of_transactions"
                                    value={values ? values.total_number_of_transactions : ''}
                                    onChange={(value) => {
                                        setFieldValue('total_number_of_transactions', value);
                                    }}
                                />
                                <TransactionsVolumeDropdown
                                    label={t(
                                        'settings.payment_connections.instabank_modal.fields.total_volume_of_transactions'
                                    )}
                                    placeholder={t(
                                        'settings.payment_connections.instabank_modal.placeholders.total_volume_of_transactions'
                                    )}
                                    name="total_volume_of_transactions"
                                    value={values ? values.total_volume_of_transactions : ''}
                                    onChange={(value) => {
                                        setFieldValue('total_volume_of_transactions', value);
                                    }}
                                />
                                <NumberOfTransactionsDropdown
                                    label={t(
                                        'settings.payment_connections.instabank_modal.fields.finance_number_of_transactions'
                                    )}
                                    placeholder={t(
                                        'settings.payment_connections.instabank_modal.placeholders.finance_number_of_transactions'
                                    )}
                                    name="finance_number_of_transactions"
                                    value={values ? values.finance_number_of_transactions : ''}
                                    onChange={(value) => {
                                        setFieldValue('finance_number_of_transactions', value);
                                    }}
                                />
                                <TransactionsVolumeDropdown
                                    label={t(
                                        'settings.payment_connections.instabank_modal.fields.finance_volume_of_transactions'
                                    )}
                                    placeholder={t(
                                        'settings.payment_connections.instabank_modal.placeholders.finance_volume_of_transactions'
                                    )}
                                    name="finance_volume_of_transactions"
                                    value={values ? values.finance_volume_of_transactions : ''}
                                    onChange={(value) => {
                                        setFieldValue('finance_volume_of_transactions', value);
                                    }}
                                />
                                <TranslatedValidatedInput
                                    path="industry"
                                    initialValues={initialFormValues}
                                    values={values}
                                    errors={errors}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched}
                                    required
                                />
                            </SpacedWrapper>
                            <ButtonGroup>
                                <Button className="alt" onClick={closeInstabankModal}>
                                    {t('settings.payment_connections.instabank_modal.cancel')}
                                </Button>
                                <Button type="submit" disabled={!isValid}>
                                    {t('settings.payment_connections.instabank_modal.sign_up_call_to_action')}
                                </Button>
                            </ButtonGroup>
                            {instabank.modalState === InstabankModalState.InitiateApplicationLoading && (
                                <LoadingOverlay />
                            )}
                        </form>
                    )}
                </Formik>
            )}
            {instabank.modalState === InstabankModalState.ApplicationReceived && (
                <>
                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.instabank_modal.last_step" />
                        </P>
                    </SpacedWrapper>

                    <ButtonGroup>
                        <Button onClick={closeInstabankModal}>
                            {t('settings.payment_connections.instabank_modal.close')}
                        </Button>
                    </ButtonGroup>
                </>
            )}
        </Modal>)
    );
};

export default withAccountIds(withTranslation()(InstabankModal));

const SpacedWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;

const InputWrapper = styled.div`
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }
`;
