import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import authStore from '../../../auth/accessToken/observables/authStore';
import { useAccountId } from '../../../auth/useAccountId';
import useHasAnyOfScopes from '../../../common/hooks/useHasAnyOfScopes';
import { CORE_API_HOSTNAME } from '../../../env';
import fulfill from '../../../fulfill';
import type { Account, UpdateAccount } from '../../../types/management-auth';

const fetchAccount = async (payload: {
    accountId: string;
}): Promise<Account> => {
    return fulfill.get({
        accountId: payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/management/settings`,
        handlers: {
            200: (account: Account) => {
                return account;
            },
            404: () => {
                return null;
            },
        },
    });
};

const fetchUpdateAccount = async (key: `updateAccount-${string}`, options: {
    arg: {
        accountId: string;
        account: UpdateAccount;
    };
}) => {
    return fulfill.put({
        accountId: options.arg.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${options.arg.accountId}/management/settings`,
        json: options.arg.account,
        handlers: {
            200: (account: Account) => {
                authStore.dispatch('updateAuthenticatedUser', {
                    accountId: options.arg.accountId,
                });
                return account;
            },
        },
    });
};

export default function useAccount() {
    const accountId = useAccountId();
    const hasScopesForRead = useHasAnyOfScopes(['read:accounts', 'admin:accounts']);
    const { data, error, isLoading } = useSWR(
        () => (accountId && hasScopesForRead ? { accountId } : null),
        fetchAccount
    );
    const { trigger: updateAccount, isMutating: isUpdatingAccount, error: updateAccountError } = useSWRMutation(`updateAccount-${accountId}`, fetchUpdateAccount);

    return {
        isLoading,
        isNotFound: !!error || !data,
        account: data,
        accountId,
        updateAccount,
        isUpdatingAccount,
        updateAccountError,
    };
}
