import { useMemo } from 'react';
import type { AuthenticatedUser } from '../../types';
import authStore, { type State } from '../observables/authStore';
import useStore from '../../../common/hooks/useExternalStore';

// #region Module functions

export const setAuthenticatedUser = (accountUser: AuthenticatedUser, exchangeTokenPartnerId?: string) => {
    authStore.dispatch('setAuthenticatedUser', { accountUser, exchangeTokenPartnerId });
};

export const setInspectAuthenticatedUser = (accountUser: AuthenticatedUser, exchangeTokenPartnerId: string) => {
    authStore.dispatch('setInspectAuthenticatedUser', { accountUser, exchangeTokenPartnerId });
};

export const setLeaveInspect = () => {
    authStore.dispatch('setLeaveInspect');
};

export const loadTokens = (accountId: string, showLoading = true) => {
    authStore.dispatch('loadTokens', { accountId, showLoading });
};

export const updateAuthenticatedUser = (accountId: string) => {
    authStore.dispatch('updateAuthenticatedUser', { accountId });
};

export const loadSubAccountTokens = (accountId: string, subAccountId: string) => {
    authStore.dispatch('loadSubAccountTokens', { accountId, subAccountId });
};

export const openChangePasswordModal = () => {
    authStore.dispatch('openChangePassword');
};

export const changePassword = (previous_password: string, proposed_password: string) => {
    authStore.dispatch('changePassword', { previous_password, proposed_password });
};

export const openAuthenticatedUserSettingsModal = () => {
    authStore.dispatch('openAuthenticatedUserSettings');
};

export const changeMfaSms = (enabled: boolean, phone_number?: string) => {
    authStore.dispatch('changeAuthenticatedUserMfaSms', { enabled, phone_number });
};

export const changeMfaTotp = (enabled: boolean, user_code?: string) => {
    authStore.dispatch('changeAuthenticatedUserMfaTotp', { enabled, user_code });
};

/**
 * Hook to access the auth store.
 * @param selector The selector function to get the desired value from the store.
 */
export default function useAuth<T>(
    selector: (state: State) => T
) {
    const { state } = useStore(authStore);

    const selected = selector(state);

    return useMemo(() => {
        return selected;
    }, [selected]);
}
