import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useAccountIds } from '../../../auth/accessToken/components/withAccountIds';
import { ButtonExternalLink } from '../../../components/Buttons';
import Card from '../../../components/Card';
import { DateTime, Json } from '../../../components/Formatters';
import Hr from '../../../components/Hr';
import { H3 } from '../../../components/Typography';
import { colors, distances, palette } from '../../../styles/constants';
import { ApprovalsPaymentResponse } from '../../../types/management-auth/generated';

const PaymentApprovalSummary = ({ approval }: {approval: ApprovalsPaymentResponse}) => {
    const { t } = useTranslation();
    if (approval.case_status === 'ACTIVE') {
        return <ApprovalBadge status="enabled">{t('partner.merchants.approvals_payments_status.active')}</ApprovalBadge>;
    }
    if (approval.case_status === 'UNDER_MANUAL_REVIEW' || approval.case_status === 'AUTOMATIC_REVIEW') {
        return <ApprovalBadge status="pending">{t('partner.merchants.approvals_payments_status.pending_review')}</ApprovalBadge>;
    }
    if (approval.case_status === 'WAITING_FOR_SIGNATURE') {
        return <ApprovalBadge status="pending">{t('partner.merchants.approvals_payments_status.waiting_for_signature')}</ApprovalBadge>;
    }
    if (approval.case_status === 'WAITING_FOR_DETAILS') {
        return <ApprovalBadge status="pending">{t('partner.merchants.approvals_payments_status.waiting_for_details')}</ApprovalBadge>;
    }
    if (approval.case_status === 'WAITING_FOR_DECLARATION') {
        return <ApprovalBadge status="pending">{t('partner.merchants.approvals_payments_status.waiting_for_declaration')}</ApprovalBadge>;
    }
    if (approval.case_status === 'DECLINED') {
        return <ApprovalBadge status="declined">{t('partner.merchants.approvals_payments_status.declined')}</ApprovalBadge>;
    }
    if (approval.case_status === 'ERROR') {
        return <ApprovalBadge status="declined">{t('partner.merchants.approvals_payments_status.error')}</ApprovalBadge>;
    }
    return null;
};


type ApprovalsPaymentsProps = {
    approvals: ApprovalsPaymentResponse[];
}

const getQuickrUrl = (approval: ApprovalsPaymentResponse) => {
    try {
        const declarationUrl = (approval.links || []).find(x => x.rel === 'declaration_url');
        if (declarationUrl && declarationUrl.href) {
            const url = new URL(declarationUrl.href);
            const casePath = url.pathname.replace('/answer', '').split('declarations')[0];
            return url.origin + casePath;
        }
    } catch (e: any) {
        console.error(e);
    }
};

const Approval = ({ approval }: {approval: ApprovalsPaymentResponse}) => {
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const { prodAccountId } = useAccountIds();
    const showQuickrLinks = prodAccountId === 'P00000000';

    const toggle = () => {
        setOpen(!open);
    };
    const quickrUrl = getQuickrUrl(approval);

    return <Item >
        <Toggle onClick={toggle}>
            <code title={approval.id}>{approval.id?.substring(0, 16)}...</code>
            <DateTime isoString={approval.created_at || ''} boldTime i18n={i18n}/>
            <PaymentApprovalSummary approval={approval} />
        </Toggle>
        {open && <>
            {showQuickrLinks && <Pad>
                <ButtonExternalLink href={quickrUrl} target="_blank" onClick={e => e.stopPropagation()}>{t('partner.merchant.open_in_quickr')} ↗</ButtonExternalLink>
            </Pad>}
            <Json data={approval} anchors={showQuickrLinks} />
        </>}
    </Item>;

};

const ApprovalsPayments = ({ approvals }: ApprovalsPaymentsProps) => {
    const { t } = useTranslation();
    return <Card marginBottom={distances.normal}>
        <H3>{t('partner.merchant.approvals_payments')}</H3>
        <Hr />
        <List>
            {approvals.map((approval) => <Approval approval={approval} key={approval.id}/>)}
        </List>
    </Card>;
};

const List = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: ${distances.small};
`;

const Item = styled.div`
    flex: 1;
    max-width: 100%;
    margin-bottom: ${distances.tiny};
    border-radius: ${distances.tiny};
    border: 2px solid ${colors.backgroundAlt};

    &:hover {
        background-color: ${colors.backgroundAlt};
    }
`;

const Toggle = styled.div`
    padding: ${distances.small};
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
`;
const Pad = styled.div`
    padding: ${distances.small};
    margin-bottom: ${distances.small};
`;

const badgeColors: { [key: string]: any } = {
    enabled: palette.success[600],
    pending: palette.warning[500],
    declined: palette.destructive[500],
};

interface BadgeProps {
    status: string;
}

const ApprovalBadge = styled.span<BadgeProps>`
    font-weight: 700;
    font-size: 12px;
    padding: 2px 12px;
    margin-left: ${distances.tiny};
    border-radius: 50px;
    color: white;
    background: ${(props) => badgeColors[props.status]};
`;


export default memo(ApprovalsPayments);
