import React, { memo } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import Allow from '../../../auth/accessToken/components/Allow';
import { isPartner } from '../../../auth/helpers';
import { useAccountId } from '../../../auth/useAccountId';
import Icon from '../../../components/Icons';
import { LoadingOverlay } from '../../../components/Loading';
import Page, { PageButtonLink, Title } from '../../../components/Page';
import { ListGap } from '../../../components/Paging';
import { Table, Tbody, Th, Thead, Tr } from '../../../components/Table/Fake';
import { getListKey } from '../../../helpers/paging';
import { accountPagingIdFn, isAccount } from '../helpers';
import useMerchants from '../hooks/useMerchants';
import AccountRow from './AccountRow';
import Filters from './Filters';
import { Messages } from './Messages';
import Paging from './Paging';

function Merchants() {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const accountId = useAccountId();
    const params = new URLSearchParams(location.search.substring(1));
    const { accounts, isLoading, hasNext, users, next, refresh, isLoadingMore } = useMerchants(params);
    const newAccount = params.get('newAccount') || undefined;
    const partnerLinkCreated = params.get('partnerLinkCreated') === 'true';

    const fillGap = (id: string) => {
        refresh();
    };

    const accountOwners = users.filter(user => user.deleted_at === undefined).map(user => user.email);

    const newAccountType = newAccount && isPartner(newAccount) ? 'partner' : 'merchant';

    return (
        <Page
            title={
                <React.Fragment>
                    <Title>{t('partner.merchants.title')}</Title>
                    <Allow anyOfScopes={[['admin:accounts'], ['write:accounts']]}>
                        {accountId.endsWith('00000000') ? (
                            <PageButtonLink to={`/${accountId}/sub-accounts/new`}>
                                <Icon icon="plus" fill="currentColor" />
                                {t('partner.merchants.new_partner')}
                            </PageButtonLink>
                        ) : (
                            <PageButtonLink to={`/${accountId}/sub-accounts/new`}>
                                <Icon icon="plus" fill="currentColor" />
                                {t('partner.merchants.invite_new_merchant')}
                            </PageButtonLink>
                        )}
                    </Allow>
                </React.Fragment>
            }
        >
            <Messages newAccount={newAccount} partnerLinkCreated={partnerLinkCreated} accountType={newAccountType} />
            <Filters history={history} location={location} refresh={refresh} account_managers={accountOwners} />
            <Table className="no-wrap">
                <Thead>
                    <Tr>
                        <Th className="first-child">{t('partner.merchants.fields.account_id')}</Th>
                        <Th>{t('partner.merchants.fields.business_name')}</Th>
                        <Th>{t('partner.merchants.fields.applicant')}</Th>
                        <Th>{t('partner.merchants.fields.payment_connections_status')}</Th>
                        <Th>{t('partner.merchants.fields.account_manager')}</Th>
                        <Th>{t('partner.merchants.fields.created_at')}</Th>
                        <Th />
                    </Tr>
                </Thead>
                {accounts.length > 0 && (
                    <Tbody>
                        {accounts.map((account) => (
                            <React.Fragment key={getListKey(account, accountPagingIdFn)}>
                                {isAccount(account) ? (
                                    <AccountRow {...{ account, accountId }} />
                                ) : (
                                    <ListGap
                                        fillGap={fillGap}
                                        gap={account}
                                        label={t('partner.merchants.load_gap')}
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </Tbody>
                )}
            </Table>
            <Wrapper>
                <RelativeGroup>
                    <Paging next={next} isLoading={isLoading || isLoadingMore} hasNext={hasNext} />
                    {(isLoading || isLoadingMore) && <LoadingOverlay />}
                </RelativeGroup>
            </Wrapper>
        </Page>
    );
}

const Wrapper = styled.div`
    position: relative;
`;

const RelativeGroup = styled.div`
    position: relative;
    box-sizing: border-box;
`;

export default withTranslation()(memo(Merchants));
