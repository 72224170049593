import { reducer as newClientGrant, namespace as newClientGrantNamespace } from './newClientGrant';

export interface State {
    [newClientGrantNamespace]: newClientGrant.State;
}

export const initialState: State = {
    [newClientGrantNamespace]: newClientGrant.initialState,
};

export const reducers = {
    [newClientGrantNamespace]: newClientGrant.reducer,
};
