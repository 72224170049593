import { useState } from 'react';
import Modal, { type ModalProps } from '../../components/Modal';


type HookedModalProps = Omit<ModalProps, 'children'> & {
    children: ((confirm: () => void, cancel: () => void) => React.ReactNode | React.ReactNode);
};

export default function useConfirmationDialog() {
    const [isOpen, setIsOpen] = useState(false);
    const [props, setProps] = useState<HookedModalProps | undefined>(undefined);

    const triggerConfirmation = async (props: HookedModalProps): Promise<boolean> => {
        setIsOpen(true);
        return new Promise((resolve) => {
            const close = (confirmed: boolean) => {
                setIsOpen(false);
                setProps(undefined);
                resolve(confirmed);
            };
            setProps({
                ...props,
                children: props.children(() => close(true), () => close(false)) as any,
            });
        });
    };

    const modal = isOpen ? (
        <Modal
            {...props as ModalProps}
            backdropStyle={{
                zIndex: 1001,
            }}
        />
    ) : null;

    return {
        modal,
        triggerConfirmation,
    };
};
