import { type RouteComponentProps, type RouteProps, Redirect, Route } from 'react-router-dom';
import useIsAllowed from '../hooks/useIsAllowed';

type AllowRouteProps = RouteProps & {
    scope?: string;
    anyOfScopes?: string[][];
    fallback?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined;
};

const RedirectUp = () => <Redirect to="../" />;

const AllowRoute = ({ scope, anyOfScopes, component, fallback, ...rest }: AllowRouteProps) => {
    const allowedScopes = scope ? [[scope]] : anyOfScopes ?? [];
    const allowed = useIsAllowed(allowedScopes);
    if (allowed) {
        return <Route component={component} {...rest} />;
    }

    return <Route component={fallback ? fallback : RedirectUp} {...rest} />;
};

export default AllowRoute;
