import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled from 'styled-components/macro';

import { useActions } from '../../../../Actions';
import { useAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import Icon from '../../../../components/Icons';
import { LoadingOverlay } from '../../../../components/Loading';
import Page, { PageButtonLink } from '../../../../components/Page';
import { LinkTr, Table, Tbody, Td, Th, Thead, Tr } from '../../../../components/Table/Fake';
import { H1 } from '../../../../components/Typography';
import { distances } from '../../../../styles/constants';
import { SalesLocation } from '../../../../types/customers';
import Filters from './Filters';
import ListMessage from './ListMessage';

import Paging from '../../../../components/Paging/Infinite';
import { SalesLocationQuery } from '../../locationActions';
import * as selectors from '../../selectors';

const LocationRow = (
    {
        location,
    }: {
        location: SalesLocation;
    }
) => (<LinkTr to={`/${location.account_id}/settings/locations/${location.location_id}`}>
    <Td className="first-child">{location.name}</Td>
    <Td className="ellipsis">{location.location_id}</Td>
    <Td>{location.address?.address_line}</Td>
    <Td>{location.address?.postal_place}</Td>
    <Td>{`${location?.email ?? ''} ${location?.phone_number ?? ''}`}</Td>
    <Td>{location.status}</Td>
</LinkTr>);

export type LocationTableProps = {
    locations: SalesLocation[];
    more?: boolean;
    loading?: boolean;
    onLoadMore: () => void;
}

const LocationTable = (
    {
        locations,
        more,
        loading,
        onLoadMore,
    }: LocationTableProps
) => {
    const { t } = useTranslation('app');
    return (
        <Wrapper>
            <Table className="no-wrap">
                <Thead>
                    <Tr>
                        <Th className="first-child">{t('locations.locations_list.fields.name')}</Th>
                        <Th>{t('locations.locations_list.fields.store_id')}</Th>
                        <Th>{t('locations.locations_list.fields.address')}</Th>
                        <Th>{t('locations.locations_list.fields.city')}</Th>
                        <Th>{t('locations.locations_list.fields.contact')}</Th>
                        <Th className="last-child">{t('locations.locations_list.fields.status')}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {locations.map((location, index) => (
                        <LocationRow location={location} key={index} />
                    ))}
                </Tbody>
            </Table>
            <Paging
                label={t('locations.locations_list.load_more')}
                hasMore={!!more}
                isLoading={!!loading}
                onLoadMore={onLoadMore}
            />
            {loading &&
                <LoadingOverlay />
            }
        </Wrapper>
    );
};

const selector = createSelector(
    selectors.hasMore,
    selectors.isLoading,
    selectors.isLoadingAny,
    selectors.salesLocations,
    selectors.query,
    selectors.startAfter,
    (more, isLoading, loadingAny, locations, query, startAfter) => ({ more, isLoading, loadingAny, locations, query, startAfter })
);

const initialQuery: SalesLocationQuery = {
    limit: 100,
};

const LocationsList = () => {
    const getLocations = useActions('settings.locations').getLocations;
    const { t } = useTranslation('app');
    const {
        more,
        isLoading,
        loadingAny,
        locations,
        query,
        startAfter,
    } = useSelector(selector);

    const {
        isTest,
        urlAccountId,
    } = useAccountIds();

    useEffect(() => {
        getLocations({
            ...initialQuery,
        }, urlAccountId);
    }, [urlAccountId, getLocations]);

    const onLoadMore = (accountId: string) => () => {
        if (more(accountId)) {
            getLocations({
                ...query(accountId),
                starting_after: startAfter(accountId),
            }, accountId);
        }
    };

    const onFilter = (filter: Partial<SalesLocationQuery>) =>
        getLocations({
            ...initialQuery,
            ...filter,
            starting_after: undefined,
        }, urlAccountId);



    const hasLocations = urlAccountId && (locations[urlAccountId] || []).length > 0;

    return (
        <Page
            title={
                <>
                    <Title>{t('locations.locations_list.title')}</Title>
                    <PageButtonLink to={`/${urlAccountId}/settings/locations/new`}>
                        <Icon icon="plus" fill="currentColor" />
                        {t('locations.locations_list.add_location_button')}
                    </PageButtonLink>
                </>
            }
        >
            <Filters onChange={onFilter} />
            {hasLocations && (
                <LocationTable
                    locations={locations[urlAccountId] || []}
                    more={more(urlAccountId)}
                    onLoadMore={onLoadMore(urlAccountId)}
                    loading={isLoading(urlAccountId)}
                />
            )}
            {!hasLocations && (
                <Wrapper>
                    <ListMessage
                        locations={Object.values(locations).flat()}
                        isLoading={loadingAny}
                    />
                </Wrapper>
            )}
        </Page>
    );
};


const Wrapper = styled.div`
`;

const Title = styled(H1 as any)`
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default LocationsList;
