import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import Card from '../../../../components/Card';
import { BackButton, Checkbox, Input, ValidationState } from '../../../../components/Forms';
import Hr from '../../../../components/Hr';
import { LoadingOverlay } from '../../../../components/Loading';
import Page, { Dismiss, PageButton } from '../../../../components/Page';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../components/Table';
import { H1, H2 } from '../../../../components/Typography';
import { DOCUMENTED_CORE_API_HOSTNAME } from '../../../../env';
import { colors, distances, globalColumnMaxWidth, grid } from '../../../../styles/constants';

import useAuth from '../../../../auth/accessToken/hooks/useAuth';
import Icon from '../../../../components/Icons';
import { Client, Grant } from '../../../../types/management-auth';
import {
    handleWriteReadScopeToggle,
    hasAllDiscrete,
    isCheckedDiscrete,
    services,
    toggleAllWriteReadAdmin
} from '../../../scopes';
import ClientAudienceDropdown, { ClientAudienceDropdownProps } from '../../components/ClientAudienceDropdown';
import ClientTypeDropdown from '../../components/ClientTypeDropdown';
import { useNewClient } from '../hooks/useNewClient';
import { actions } from '../observables/newClientStore';
import ClientSecret from './ClientSecret';

interface FormikErrors {
    name?: string;
    description?: string;
    type?: string;
    audience?: string;
}

interface FormikValues {
    name: string;
    description: string;
    scope: string[];
    type: 'any' | 'authorization_code' | 'client_credentials' | 'password';
    audience: string;
}

const getInitialAudience = (accountId: string, envParam: string | null) => {
    let audienceId = accountId;
    if (envParam) {
        const envPrefix = envParam === 'production' ? 'P' : 'T';
        audienceId = `${envPrefix}${accountId.substring(1)}`;
    }
    return `${DOCUMENTED_CORE_API_HOSTNAME}/v1/accounts/${audienceId}`;
};

const NewClientForm = () => {
    const { t } = useTranslation();
    const { isLoading, accountId, client, grants, isSecretSafe } = useNewClient();
    const location = useLocation();
    const accountUser = useAuth(state => state.account_user);
    const audienceRoot = `${DOCUMENTED_CORE_API_HOSTNAME}/v1/accounts`;
    useEffect(() => {
        return () => {
            actions.resetForm();
        };
    }, []);

    const currentAccount = accountUser.accounts.find((account) => account.account_id === accountId);
    if (!currentAccount) {
        return null;
    }
    const searchParams = new URLSearchParams(location.search.substring(1));
    const paramsAudience = getInitialAudience(accountId, searchParams.get('environment'));

    return (
        <React.Fragment>
            <Formik
                initialValues={
                    {
                        name: searchParams.get('name') || '',
                        description: '',
                        scope: (searchParams.get('scope') || '').split(','),
                        type: searchParams.get('type') || 'any',
                        audience: paramsAudience,
                    } as FormikValues
                }
                validate={(values) => {
                    const errors: FormikErrors = {};

                    if (!values.name) {
                        errors.name = t('settings.new_client.validation.name_required');
                    }
                    if (!['any', 'authorization_code', 'client_credentials', 'password'].includes(values.type)) {
                        errors.type = t('settings.new_client.validation.type_invalid');
                    }
                    if (!values.audience) {
                        errors.audience = t('settings.new_client.validation.audience_required');
                    }
                    if (values.audience && !values.audience.startsWith(audienceRoot)) {
                        errors.audience = t('settings.new_client.validation.audience_invalid', {
                            audience_root: audienceRoot,
                        });
                    }

                    return errors;
                }}
                validateOnChange={false}
                onSubmit={(values) => {
                    const newClient: Client = {
                        name: values.name,
                        description: values.description,
                    };
                    const newGrant: Grant = {
                        client_id: '',
                        type: values.type,
                        audience: values.audience,
                        scope: values.scope.filter((x) => x),
                    };
                    actions.createClient(newClient, [newGrant]);
                }}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, touched }) => (
                    <Form onSubmit={handleSubmit} autoComplete="nope">
                        <Page
                            title={
                                <React.Fragment>
                                    <Dismiss />
                                    <Title>{t('settings.new_client.title')}</Title>
                                    <PageButton disabled={errors.name !== undefined || !values.name} type="submit">
                                        <Icon icon="save" fill="currentColor" />
                                        {t('settings.new_client.create_client')}
                                    </PageButton>
                                </React.Fragment>
                            }
                        >
                            <Card title={undefined}>
                                <Wrapper>
                                    <FormGroup>
                                        <H2>{t('settings.new_client.account')}</H2>
                                        <Hr />
                                        <Flex>
                                            <InputWrapper>
                                                <Input
                                                    autoComplete="off"
                                                    label={t('settings.new_client.fields.name')}
                                                    placeholder={t('settings.new_client.placeholders.name')}
                                                    type="text"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    validation={{
                                                        state:
                                                            errors.name && touched.name
                                                                ? ValidationState.Invalid
                                                                : ValidationState.Pristine,
                                                        message: errors.name,
                                                    }}
                                                    required
                                                />
                                            </InputWrapper>
                                            <InputWrapper>
                                                <Input
                                                    autoComplete="off"
                                                    label={t('settings.new_client.fields.description')}
                                                    placeholder={t('settings.new_client.placeholders.description')}
                                                    type="text"
                                                    name="description"
                                                    value={values.description}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    validation={{
                                                        state:
                                                            errors.description && touched.description
                                                                ? ValidationState.Invalid
                                                                : ValidationState.Pristine,
                                                        message: errors.description,
                                                    }}
                                                />
                                            </InputWrapper>
                                        </Flex>
                                    </FormGroup>
                                    <FormGroup>
                                        <H2>{t('settings.new_client.grant')}</H2>
                                        <Hr />
                                        <Flex>
                                            <InputWrapper>
                                                <ClientAudienceDropdown
                                                    {...({
                                                        label: t('settings.new_client.fields.audience'),
                                                        placeholder: 'settings.new_client.placeholders.audience',
                                                        name: 'audience',
                                                        value: values.audience,
                                                        onChange: (value: string) =>
                                                            setFieldValue('audience', value),
                                                        audienceRoot: audienceRoot,
                                                        validation: {
                                                            state: errors.audience
                                                                ? ValidationState.Invalid
                                                                : ValidationState.Pristine,
                                                            message: errors.audience,
                                                        },
                                                    } as unknown as ClientAudienceDropdownProps)}
                                                />
                                            </InputWrapper>
                                            <InputWrapper>
                                                <ClientTypeDropdown
                                                    label={t('settings.new_client.fields.type')}
                                                    placeholder={t('settings.new_client.placeholders.type')}
                                                    name="type"
                                                    value={values.type}
                                                    onChange={(value) => setFieldValue('type', value)}
                                                    validation={{
                                                        state: errors.type
                                                            ? ValidationState.Invalid
                                                            : ValidationState.Pristine,
                                                        message: errors.type,
                                                    }}
                                                />
                                            </InputWrapper>
                                        </Flex>
                                    </FormGroup>
                                    <FormGroup>
                                        <H2>{t('settings.new_client.scope')}</H2>
                                        <Hr />
                                        <Table>
                                            <Thead>
                                                <Tr>
                                                    <Th>{t('settings.scopes.service')}</Th>
                                                    <Th>{t('settings.scopes.description')}</Th>
                                                    <Th className="align-center">{t('settings.scopes.admin')}</Th>
                                                    <Th className="align-center">{t('settings.scopes.write')}</Th>
                                                    <Th className="align-center">{t('settings.scopes.read')}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {Object.entries(services).map(([service, access]) => (
                                                    <Tr key={service}>
                                                        <Scope>
                                                            <code>{service}</code>
                                                        </Scope>
                                                        <Description>
                                                            {t([
                                                                `settings.scopes.service_descriptions.${service}`,
                                                                '',
                                                            ] as any)}
                                                        </Description>
                                                        <Access className="align-center">
                                                            {access.includes('admin') ? (
                                                                <Checkbox
                                                                    label=""
                                                                    name="scope"
                                                                    checked={isCheckedDiscrete(
                                                                        values.scope,
                                                                        service,
                                                                        'admin'
                                                                    )}
                                                                    value={`admin:${service}`}
                                                                    onChange={(e) =>
                                                                        setFieldValue(
                                                                            'scope',
                                                                            handleWriteReadScopeToggle(
                                                                                values.scope,
                                                                                e
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </Access>
                                                        <Access className="align-center">
                                                            {access.includes('write') ? (
                                                                <Checkbox
                                                                    label=""
                                                                    name="scope"
                                                                    checked={isCheckedDiscrete(
                                                                        values.scope,
                                                                        service,
                                                                        'write'
                                                                    )}
                                                                    value={`write:${service}`}
                                                                    onChange={(e) =>
                                                                        setFieldValue(
                                                                            'scope',
                                                                            handleWriteReadScopeToggle(
                                                                                values.scope,
                                                                                e
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </Access>
                                                        <Access className="align-center">
                                                            {access.includes('read') ? (
                                                                <Checkbox
                                                                    label=""
                                                                    name="scope"
                                                                    checked={isCheckedDiscrete(
                                                                        values.scope,
                                                                        service,
                                                                        'read'
                                                                    )}
                                                                    value={`read:${service}`}
                                                                    onChange={(e) =>
                                                                        setFieldValue(
                                                                            'scope',
                                                                            handleWriteReadScopeToggle(
                                                                                values.scope,
                                                                                e
                                                                            )
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Access>
                                                    </Tr>
                                                ))}
                                                <Tr>
                                                    <Td></Td>
                                                    <Td></Td>
                                                    <Td className="align-center">
                                                        <Button
                                                            className="alt tiny"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    'scope',
                                                                    toggleAllWriteReadAdmin(values.scope, 'admin')
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            {t(
                                                                hasAllDiscrete(values.scope, 'admin')
                                                                    ? 'settings.scopes.disable_all'
                                                                    : 'settings.scopes.enable_all'
                                                            )}
                                                        </Button>
                                                    </Td>
                                                    <Td className="align-center">
                                                        <Button
                                                            className="alt tiny"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    'scope',
                                                                    toggleAllWriteReadAdmin(values.scope, 'write')
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            {t(
                                                                hasAllDiscrete(values.scope, 'write')
                                                                    ? 'settings.scopes.disable_all'
                                                                    : 'settings.scopes.enable_all'
                                                            )}
                                                        </Button>
                                                    </Td>
                                                    <Td className="align-center">
                                                        <Button
                                                            className="alt tiny"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    'scope',
                                                                    toggleAllWriteReadAdmin(values.scope, 'read')
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            {t(
                                                                hasAllDiscrete(values.scope, 'read')
                                                                    ? 'settings.scopes.disable_all'
                                                                    : 'settings.scopes.enable_all'
                                                            )}
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            </Tbody>
                                        </Table>
                                    </FormGroup>
                                    <ButtonGroup>
                                        <BackButton>{t('settings.new_client.abort')}</BackButton>
                                        <Button disabled={errors.name !== undefined || !values.name} type="submit">
                                            {t('settings.new_client.create_client')}
                                        </Button>
                                    </ButtonGroup>
                                    {isLoading && <LoadingOverlay />}
                                </Wrapper>
                            </Card>
                        </Page>
                    </Form>
                )}
            </Formik>
            {client && grants && (
                <ClientSecret
                    accountId={accountId}
                    isSecretSafe={isSecretSafe}
                    client={client}
                    grants={grants}
                    toggleSecretIsSafe={actions.toggleSecretIsSafe}
                />
            )}
        </React.Fragment>
    );
};

export default NewClientForm;

const Form = styled.form`
    width: 100%;
`;

const Scope = styled(Td)`
    width: 20%;
`;
const Description = styled(Td)`
    width: 35%;
`;
const Access = styled(Td)`
    width: 15%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    width: 100%;
`;

const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Flex = styled.div`
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const InputWrapper = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
    }
`;
