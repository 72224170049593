import React from 'react';
import { useHistory } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';


import useAuth from '../../auth/accessToken/hooks/useAuth';
import { globalMobileBreakpoint, palette } from '../../styles/constants';
import View from '../View';
import arrowUpDown from './icons/arrow-up-down.svg';
import profileActive from './icons/profile-active.svg';
import profileHover from './icons/profile-hover.svg';
import profileInactive from './icons/profile-inactive.svg';

const iconMap = {
    profile: {
        active: profileActive,
        inactive: profileInactive,
        hover: profileHover,
    },
};

interface AppBarMerchantLogoProps extends React.DOMAttributes<HTMLSpanElement>, RouteComponentProps<MatchParams> {
    icon: keyof typeof iconMap;
    bg?: string;
    color?: string;
}

interface MatchParams {
    accountId: string;
}

const AppBarMerchantLogo = (
    {
        match,
        icon,
        bg,
        color,
    }: AppBarMerchantLogoProps
) => {
    const history = useHistory();
    const accountUser = useAuth((state) => state.account_user);

    const currentAccount = accountUser.accounts.find((account) => account.account_id === match.params.accountId);
    const iconFromUrl =
        currentAccount && currentAccount.icon_url
            ? {
                active: currentAccount.icon_url,
                inactive: currentAccount.icon_url,
                hover: currentAccount.icon_url,
            }
            : iconMap[icon];

    const getNameToDisplay = () => {
        if (currentAccount) {
            return currentAccount.display_name
                ? currentAccount.display_name
                : currentAccount.business_name
                    ? currentAccount.business_name
                    : currentAccount.account_id;
        }
        return '';
    };

    return (
        <AppBarIconWrapper onClick={() => history.push(`/${currentAccount?.account_id}/accounts`)}>
            <ProfileImageWrapper bg={bg} color={color}>
                <ProfileImage icon={iconFromUrl} />
            </ProfileImageWrapper>
            <TextWrapper>
                <View direction="column" gap="0" justify="flex-start" alignItems="flex-start">
                    <StyledName>{getNameToDisplay()}</StyledName>
                    {currentAccount && <StyledCode>{currentAccount?.account_id}</StyledCode>}
                </View>
            </TextWrapper>
            <ArrowIcon />
        </AppBarIconWrapper>
    );
};

const ArrowIcon = styled.svg`
    width: 20px;
    height: 20px;
    mask-image: url(${arrowUpDown});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background-color: inherit;
    margin-left: 12px;
`;

const AppBarIconWrapper = styled.div`
    display: flex;
    padding: 4px;
    align-items: center;
    cursor: pointer;

    span.logged-in-label {
        margin: 0;
        opacity: 0.7;
        font-size: 13px;
    }

    &:hover,
    &:active {
        color: ${palette.neutral[900]};
        background: rgba(255, 255, 255, 0.2);
    }

    @media (max-width: ${globalMobileBreakpoint}px) {
        width: 100%;
        margin: 0;
        justify-content: center;
        align-items: center;
    }
`;

interface ProfileImageProps {
    icon: {
        active: string;
        inactive: string;
        hover: string;
    };
}

const ProfileImage = styled.div<ProfileImageProps>`
    width: 34px;
    height: 34px;
    background-image: url(${(props) => props.icon.active});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
    mix-blend-mode: multiply;
    border-radius: 2px;

    &:hover {
        background-image: url(${(props) => props.icon.hover});
    }
`;

const ProfileImageWrapper = styled.div <{ bg?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 6px;
    background: ${(props) => props.bg || palette.primary[50]};
    overflow: hidden;
    margin-right: 8px;
    position: relative;

    ${props => props.color && `
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: ${props.color}55;
            opacity: 1;
            z-index: 1;
            border-radius: 6px;
        }
    `}
`;

const TextWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
`;

const StyledName = styled.p`
    margin: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    @media (max-width: ${globalMobileBreakpoint}px) {
        max-width: 80px;
    }
`;

const StyledCode = styled.p`
    margin: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    font-variant-numeric: tabular-nums;
`;

export default withRouter(AppBarMerchantLogo);
