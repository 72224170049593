import { useSyncExternalStore } from 'use-sync-external-store/shim';
import type { ExternalStore } from '../utils/createExternalStore';

/**
 * Hook to access an external store instance. This hook is a wrapper around useSyncExternalStore.
 * @param store External store to subscribe to
 * @returns The state, dispatch function, and select function of the store
 */
export default function useStore<T>(store: ExternalStore<T>) {
    const state = useSyncExternalStore(store.subscribe, store.getSnapshot);
    const dispatch = store.dispatch;
    const select = store.select;
    return { state, dispatch, select };
}
