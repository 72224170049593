import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { decodeTokenScopes } from '../../../../auth/accessToken/helpers';
import useAuth from '../../../../auth/accessToken/hooks/useAuth';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import Card from '../../../../components/Card';
import { BackButton, Checkbox } from '../../../../components/Forms';
import Input from '../../../../components/Forms/Input';
import Hr from '../../../../components/Hr';
import Icon from '../../../../components/Icons';
import { LoadingOverlay } from '../../../../components/Loading';
import Page, { Back, Dismiss, PageButton } from '../../../../components/Page';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../../components/Table';
import { H1, H2, H3, Muted } from '../../../../components/Typography';
import { colors, distances, globalColumnMaxWidth, grid } from '../../../../styles/constants';
import { handleAdminReadScopeToggle, hasAll, isChecked, services, toggleAllAdminRead } from '../../../scopes';
import { useEditUser } from '../hooks/useEditUser';
import { actions } from '../observables/editUserStore';
import { UpdateAccountUserBody } from '../types';
import NotFound from './NotFound';

const blockedScopes = ['admin:dintero'];

const EditUserPage = () => {
    const { t } = useTranslation();
    const { accountId, userId } = useParams();
    const user = useEditUser(userId);
    const { tokens, accounts } = useAuth((state => ({
        tokens: state.tokens,
        accounts: state.account_user.accounts,
    })));
    const loggedInUserScopes = useMemo(() => {
        return decodeTokenScopes(tokens[accountId]);
    }, [tokens, accountId]);
    const currentAccount = accounts.find((account) => account.account_id === accountId);
    if (!currentAccount) {
        return null;
    }
    const domain = currentAccount.user.email.split('@')[1];

    if (user.isEdited) {
        return <Redirect to={`/${accountId}/settings/users/${userId}`} />;
    }
    if (!user.isLoading && !user.user) {
        return (
            <Page
                title={
                    <React.Fragment>
                        <Back />
                        <Title>{t('settings.edit_user.title')}</Title>
                    </React.Fragment>
                }
            >
                <Card title={undefined} noPadding>
                    <Wrapper>
                        <NotFound userId={userId} accountId={accountId} />
                    </Wrapper>
                </Card>
            </Page>
        );
    }

    const editUserScopes = (user.user && user.user.scope) || [];
    const specialScopes = [...new Set([...editUserScopes, ...loggedInUserScopes])];

    const tableScopes = Object.entries(services).reduce<string[]>((acc, [service, accesses]) => {
        const serviceScopes = accesses.map((access) => `${access}:${service}`);
        return [...acc, ...serviceScopes];
    }, []);
    const nonTableScopes = [...new Set(specialScopes.filter((scope) => !tableScopes.includes(scope) && !blockedScopes.includes(scope)))];
    return (
        <Formik
            enableReinitialize
            initialValues={{
                name: (user.user && user.user.name) || '',
                scope: (user.user && user.user.scope) || [],
            }}
            validateOnChange={false}
            onSubmit={(values) => {
                const updatedUser: UpdateAccountUserBody = {
                    scope: values.scope,
                };
                if (values.name) {
                    updatedUser.name = values.name;
                }
                actions.editUser({
                    accountId,
                    userId,
                    user: updatedUser,
                });
            }}
        >
            {({ values, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                <Form onSubmit={handleSubmit} autoComplete="nope">
                    <Page
                        title={
                            <React.Fragment>
                                <Dismiss />
                                <Title>{t('settings.edit_user.title', { email: user?.user?.email })}</Title>

                                <PageButton disabled={user.isLoading}>
                                    <Icon icon="save" fill="currentColor" />
                                    {t('settings.edit_user.save')}
                                </PageButton>
                            </React.Fragment>
                        }
                    >
                        <Card title={undefined}>
                            <Wrapper>
                                <FormGroup>
                                    <H2>{t('settings.edit_user.account')}</H2>
                                    <Hr />
                                    <Flex>
                                        <InputWrapper>
                                            <Input
                                                placeholder={`user.name@${domain}`}
                                                label={t('settings.edit_user.fields.email')}
                                                type="email"
                                                name="email"
                                                value={(user.user && user.user.email) || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled
                                                required
                                            />
                                        </InputWrapper>
                                        <InputWrapper>
                                            <Input
                                                label={t('settings.edit_user.fields.name')}
                                                placeholder={t('settings.edit_user.placeholders.name')}
                                                type="text"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </InputWrapper>
                                    </Flex>
                                </FormGroup>

                                <H2>{t('settings.edit_user.access')}</H2>
                                <Hr />
                                <Muted>
                                    {t('settings.scopes.accounts_admin_description')}
                                </Muted>
                                <Table>
                                    <Thead>
                                        <Tr>
                                            <Th>{t('settings.scopes.service')}</Th>
                                            <Th>{t('settings.scopes.description')}</Th>
                                            <Th className="align-center">{t('settings.scopes.admin')}</Th>
                                            <Th className="align-center">{t('settings.scopes.read')}</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {Object.entries(services).map(([service, access]) => (
                                            <Tr key={service}>
                                                <Scope>
                                                    <code>{service}</code>
                                                </Scope>
                                                <Description>
                                                    {t([`settings.scopes.service_descriptions.${service}`, ''] as any) /* keep current logic as-is */}
                                                </Description>
                                                <Admin className="align-center">
                                                    {access.includes('admin') ? (
                                                        <Checkbox
                                                            label=""
                                                            name="scope"
                                                            checked={isChecked(values.scope, service, 'admin')}
                                                            value={`admin:${service}`}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    'scope',
                                                                    handleAdminReadScopeToggle(values.scope, e)
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        '-'
                                                    )}
                                                </Admin>
                                                <Read className="align-center">
                                                    {access.includes('read') ? (
                                                        <Checkbox
                                                            label=""
                                                            name="scope"
                                                            checked={isChecked(values.scope, service, 'read')}
                                                            value={`read:${service}`}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    'scope',
                                                                    handleAdminReadScopeToggle(values.scope, e)
                                                                )
                                                            }
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </Read>
                                            </Tr>
                                        ))}
                                        <Tr>
                                            <Td></Td>
                                            <Td></Td>
                                            <Td className="align-center">
                                                <Button
                                                    className="alt tiny"
                                                    onClick={() => {
                                                        setFieldValue(
                                                            'scope',
                                                            toggleAllAdminRead(values.scope, 'admin')
                                                        );
                                                    }}
                                                    type="button"
                                                >
                                                    {t(
                                                        hasAll(values.scope, 'write')
                                                            ? 'settings.scopes.disable_all'
                                                            : 'settings.scopes.enable_all'
                                                    )}
                                                </Button>
                                            </Td>
                                            <Td className="align-center">
                                                <Button
                                                    className="alt tiny"
                                                    onClick={() => {
                                                        setFieldValue(
                                                            'scope',
                                                            toggleAllAdminRead(values.scope, 'read')
                                                        );
                                                    }}
                                                    type="button"
                                                >
                                                    {t(
                                                        hasAll(values.scope, 'read')
                                                            ? 'settings.scopes.disable_all'
                                                            : 'settings.scopes.enable_all'
                                                    )}
                                                </Button>
                                            </Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                                {nonTableScopes.length > 0 && (
                                    <>
                                        <H3>{t('settings.scopes.legacy_scopes')}</H3>
                                        <LegacyScopesWrapper>
                                            {nonTableScopes.sort().map((scope) => (
                                                <ScopeWrapper key={scope}>
                                                    <Checkbox
                                                        name="scope"
                                                        value={scope}
                                                        label={<code>{scope}</code>}
                                                        checked={values.scope.includes(scope)}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                </ScopeWrapper>
                                            ))}
                                        </LegacyScopesWrapper>
                                    </>
                                )}
                                {/* {userExclusiveScope.sort().map((scope) => (
                                        <ScopeWrapper key={scope}>
                                            <Checkbox
                                                disabled={true}
                                                name="scope"
                                                value={scope}
                                                label={<code>{scope}</code>}
                                                checked={values.scope.includes(scope)}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </ScopeWrapper>
                                    ))} */}
                                <ButtonGroup>
                                    <BackButton children={t('settings.edit_user.abort')} />
                                    <Button disabled={user.isLoading} children={t('settings.edit_user.save')} />
                                </ButtonGroup>
                                {user.isLoading && <LoadingOverlay />}
                            </Wrapper>
                        </Card>
                    </Page>
                </Form>
            )}
        </Formik>
    );
};

const Form = styled.form`
    width: 100%;
`;

const LegacyScopesWrapper = styled.div`
    margin-top: ${distances.normal};
    margin-bottom: ${distances.normal};
    margin-left: ${distances.normal};
`;

const Scope = styled(Td)`
    width: 20%;
`;
const Description = styled(Td)`
    width: 50%;
`;
const Read = styled(Td)`
    width: 15%;
`;
const Admin = styled(Td)`
    width: 15%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
`;

const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Flex = styled.div`
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const InputWrapper = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
    }
`;



const ScopeWrapper = styled.div`
    padding-bottom: ${distances.small};
`;

export default EditUserPage;
