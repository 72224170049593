import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import Card from '../../../../components/Card';
import { LoadingOverlay } from '../../../../components/Loading';
import Page, { Dismiss, PageButton } from '../../../../components/Page';
import { H1, H2, P } from '../../../../components/Typography';
import { DOCUMENTED_CORE_API_HOSTNAME } from '../../../../env';
import { colors, distances, globalColumnMaxWidth, grid } from '../../../../styles/constants';

import { useAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import useAuth from '../../../../auth/accessToken/hooks/useAuth';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { BackButton, Input, ValidationState } from '../../../../components/Forms';
import Hr from '../../../../components/Hr';
import Icon from '../../../../components/Icons';
import { Client, Grant } from '../../../../types/management-auth';
import { useNewClient } from '../hooks/useNewClient';
import { actions } from '../observables/newClientStore';
import ClientSecret from './ClientSecret';

interface FormikErrors {
    name?: string;
    description?: string;
    type?: string;
    audience?: string;
}

interface FormikValues {
    name: string;
    description: string;
    scope: string[];
    type: 'any' | 'authorization_code' | 'client_credentials' | 'password';
    audience: string;
}

const NewClientForm = () => {
    const { t } = useTranslation();
    const { accountId, account, client, grants, isLoading, isSecretSafe } = useNewClient();
    const accountUser = useAuth(state => state.account_user);
    const location = useLocation();
    const { prodAccountId, testAccountId } = useAccountIds();
    const accountIds = [prodAccountId, testAccountId].filter((x) => x);
    useEffect(() => {
        return () => {
            actions.resetForm();
        };
    }, []);

    const audienceRoot = `${DOCUMENTED_CORE_API_HOSTNAME}/v1/accounts`;
    const currentAccount = accountUser.accounts.find((account) => account.account_id === accountId);

    if (!currentAccount) {
        return null;
    }
    const searchParams = new URLSearchParams(location.search.substring(1));
    return (
        <React.Fragment>
            <Formik
                enableReinitialize
                initialValues={
                    {
                        name: searchParams.get('name') || account?.company.website || '',
                        description: 'Dintero Checkout',
                        scope: [
                            'write:checkout',
                            'read:checkout',
                            'write:receipts',
                            'write:notifications',
                            'write:discounts:/available_for_receipt',
                        ],
                        type: 'client_credentials',
                    } as FormikValues
                }
                validate={(values) => {
                    const errors: FormikErrors = {};

                    if (!values.name) {
                        errors.name = t('settings.new_client.validation.name_required');
                    }

                    return errors;
                }}
                validateOnChange={false}
                onSubmit={(values) => {
                    const newClient: Client = {
                        name: values.name,
                        description: values.description,
                    };
                    const newGrants: Grant[] = accountIds.map((aid) => ({
                        client_id: '',
                        type: values.type,
                        audience: `${audienceRoot}/${aid}`,
                        scope: values.scope.filter((x) => x),
                    }));
                    actions.createClient(newClient, newGrants);
                }}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, touched }) => (
                    <Form onSubmit={handleSubmit} autoComplete="nope">
                        <Page
                            title={
                                <>
                                    <Dismiss />
                                    <Title>{t('settings.new_client.title_checkout')}</Title>
                                    <PageButton disabled={errors.name !== undefined || !values.name} type="submit">
                                        <Icon icon="save" fill="currentColor" />
                                        {t('settings.new_client.create_client')}
                                    </PageButton>
                                </>
                            }
                        >
                            <Card title={undefined}>
                                <Wrapper>
                                    <FormGroup>
                                        <H2>{t('settings.new_client.checkout_info')}</H2>
                                        <Hr />

                                        <Description>{t('settings.new_client.checkout_description')}</Description>

                                        <Flex>
                                            <InputWrapper>
                                                <Input
                                                    autoComplete="off"
                                                    label={t('settings.new_client.fields.checkout_client_name')}
                                                    placeholder={t('settings.new_client.placeholders.name')}
                                                    type="text"
                                                    name="name"
                                                    value={values.name}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    validation={{
                                                        state:
                                                            errors.name && touched.name
                                                                ? ValidationState.Invalid
                                                                : ValidationState.Pristine,
                                                        message: errors.name,
                                                    }}
                                                    required
                                                />
                                            </InputWrapper>
                                        </Flex>
                                    </FormGroup>
                                    <ButtonGroup>
                                        <BackButton>{t('settings.new_client.abort')}</BackButton>
                                        <Button disabled={errors.name !== undefined || !values.name} type="submit">
                                            {t('settings.new_client.create_client')}
                                        </Button>
                                    </ButtonGroup>
                                    {isLoading && <LoadingOverlay />}
                                </Wrapper>
                            </Card>
                        </Page>
                    </Form>
                )}
            </Formik>
            {client && grants && (
                <ClientSecret
                    accountId={accountId}
                    isSecretSafe={isSecretSafe}
                    client={client}
                    grants={grants}
                    toggleSecretIsSafe={actions.toggleSecretIsSafe}
                />
            )}
        </React.Fragment>
    );
};

export default NewClientForm;

const Form = styled.form`
    width: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    width: 100%;
`;

const Description = styled(P)`
    margin-top: ${distances.normal};
    margin-bottom: ${distances.normal};
`;

const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Flex = styled.div`
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const InputWrapper = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
    }
`;
