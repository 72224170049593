import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import type { Account } from '../../../../types/management-auth';

import { useActions } from '../../../../Actions';
import { payoutInfoSelector } from '../../../../payout/merchant/config/selectors';
import { namespace, CustomerDueDiligenceQuery } from '../../../cddActions';
import { distances } from '../../../../styles/constants';

import { H2 } from '../../../../components/Typography';
import Hr from '../../../../components/Hr';
import BankAccounts from '../../../components/PayoutBankAccount/BankAccounts';
import { useAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import Card from '../../../../components/Card';

export type PayoutProps = {
    account: Account;
};


const enableAccountCddListParams: CustomerDueDiligenceQuery = { actions_on_approval: ['enable_payout_account']};

const PayoutInfo = (
    {
        account,
    }: {
        account: Account;
    }
) => {
    const accountIds = useAccountIds();
    const { getCddCases } = useActions(namespace);
    const getPayoutAccountConfig = useActions('payout.config').getAccountConfig;
    const {  loading, hasPayout } = useSelector(payoutInfoSelector(accountIds.optimisticProdAccountId));

    useEffect(() => {
        if (accountIds.prodAccountId) {
            getCddCases(accountIds.prodAccountId, enableAccountCddListParams);
        }
    }, [accountIds.prodAccountId, getCddCases]);
    useEffect(() => {
        if (accountIds.prodAccountId) {
            getPayoutAccountConfig(accountIds.prodAccountId);
        }
    }, [accountIds.prodAccountId, getPayoutAccountConfig]);

    const { t } = useTranslation();

    if (!account.livemode || loading) {
        return null;
    }

    if (hasPayout) {
        return <Card marginBottom={distances.normal}>
            <H2>{t('settings.account.payout.title')}</H2>
            <Hr />
            <BankAccounts
                organizationNumber={account.company.organization_number!}
                accountId={accountIds.optimisticProdAccountId}
            />
            {/**
             * TODO: display a helpful text informing that no payout will be performed until
             * a gateway is configured for payout (if there no payment gateways are configured for payout)
             */}
        </Card>;
    }

    return null;
};

export default PayoutInfo;
