import useIsAllowed from '../hooks/useIsAllowed';

export type AllowProps = {
    scope?: string;
    anyOfScopes?: string[][];
    children: JSX.Element;
    fallback?: JSX.Element;
};

const Allow = ({ scope, anyOfScopes, fallback, children }: AllowProps) => {
    const allowedScopes = scope ? [[scope]] : anyOfScopes ?? [];
    return useIsAllowed(allowedScopes) ? children : fallback ?? null;
};

export default Allow;
