import styled from 'styled-components/macro';

import { border, defaultRadius, distances, palette } from '../../styles/constants';

const Button = styled.button`
    padding: 10px 16px;
    border-radius: ${defaultRadius};
    color: white;
    background: ${palette.primary[500]};
    border: ${border.normal} solid ${palette.primary[500]};
    text-decoration: none;
    user-select: none;
    font-weight: 500;
    font-size: 14px;
    line-height: ${distances.small};
    cursor: pointer;
    min-height: 40px;
    display: inline-block;
    text-align: center;

    line-height: 20px;

    &:hover {
        background: ${palette.primary[600]};
        border: ${border.normal} solid ${palette.primary[600]};
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        background: ${palette.primary[300]};
        border-color: ${palette.primary[300]};
        cursor: not-allowed;
    }

    &.tiny {
        min-width: auto;
        padding: 0;
        height: ${distances.normal};
        padding-left: ${distances.tiny};
        padding-right: ${distances.tiny};
    }

    &.tiny-768 {
        @media (max-width: 768px) {
            min-width: auto;
            padding: 0;
            height: ${distances.normal};
            padding-left: ${distances.tiny};
            padding-right: ${distances.tiny};
        }
    }

    &.full-width {
        width: 100%;
    }

    &.stretch {
        min-width: 100%;
    }

    &.stretch-768 {
        @media (max-width: 768px) {
            min-width: 100%;
        }
    }

    &.small-1500 {
        @media (max-width: 1500px) {
            padding: 6px 12px;
            font-size: 12px;
            min-height: 20px;
        }
    }

    &.small-1800 {
        @media (max-width: 1800px) {
            padding: 6px 12px;
            font-size: 12px;
            min-height: 20px;
        }
    }

    &.with-icon {
        padding-top: 3px;
        padding-bottom: 1px;
        padding-left: 1px;
    }

    &.small {
        padding: 6px 12px;
        font-size: 12px;
        min-height: 20px;
    }

    &.small-icon {
        padding: 6px;
        font-size: 12px;
        min-height: 20px;
        svg {
            height: 18px;
            width: 18px;
        }
    }

    &.large {
        padding: 12px 20px;
        font-size: 16px;
        line-height: 24px;
    }

    &.alt {
        background: ${palette.primary[50]};
        color: ${palette.primary[500]};
        border: ${border.normal} solid ${palette.primary[50]};

        &:hover {
            background: ${palette.primary[100]};
            border: ${border.normal} solid ${palette.primary[100]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.primary[300]};
        }
        &:active {
            background: ${palette.primary[300]};
            color: ${palette.primary[600]};
        }

        &:disabled {
          opacity: .5;
          pointer-events: none;
        }

        &.neutral {
            background: ${palette.neutral[50]};
            color: ${palette.neutral[900]};
            border: ${border.normal} solid ${palette.neutral[50]};

            .icon {
                background-color: ${palette.neutral[900]};
            }

            &:hover {
                background: ${palette.neutral[100]};
                border: ${border.normal} solid ${palette.neutral[100]};
            }
            &:focus {
                border: ${border.normal} solid ${palette.neutral[300]};
            }
            &:active {
                background: ${palette.neutral[300]};
                color: ${palette.neutral[900]};
                .icon {
                    background-color: ${palette.neutral[900]};
                }
            }
        }
        &.red {
            background: ${palette.destructive[50]};
            color: ${palette.destructive[500]};
            border: ${border.normal} solid ${palette.destructive[50]};

            &:hover {
                background: ${palette.destructive[100]};
                border: ${border.normal} solid ${palette.destructive[100]};
            }
            &:focus {
                border: ${border.normal} solid ${palette.destructive[300]};
            }
            &:active {
                background: ${palette.destructive[300]};
                color: ${palette.destructive[700]};
            }
        }
        &.green {
            background: ${palette.success[50]};
            color: ${palette.success[500]};
            border: ${border.normal} solid ${palette.success[50]};

            &:hover {
                background: ${palette.success[100]};
                border: ${border.normal} solid ${palette.success[100]};
            }
            &:focus {
                border: ${border.normal} solid ${palette.success[300]};
            }
            &:active {
                background: ${palette.success[300]};
                color: ${palette.success[700]};
            }
        }
    }

    &.red {
        background: ${palette.destructive[500]};
        border: ${border.normal} solid ${palette.destructive[500]};

        &:hover {
            background: ${palette.destructive[600]};
            border: ${border.normal} solid ${palette.destructive[600]};
        }
        &:active {
            background: ${palette.destructive[700]};
            border: ${border.normal} solid ${palette.destructive[700]};
        }
    }

    &.green {
        background: ${palette.success[500]};
        border: ${border.normal} solid ${palette.success[500]};

        &:hover {
            background: ${palette.success[600]};
            border: ${border.normal} solid ${palette.success[600]};
        }
        &:active {
            background: ${palette.success[700]};
            border: ${border.normal} solid ${palette.success[700]};
        }
    }

    &.neutral {
        background: ${palette.neutral[50]};
        color: ${palette.neutral[500]};
        border: ${border.normal} solid ${palette.neutral[200]};

        &:hover {
            background: ${palette.neutral[100]};
            border: ${border.normal} solid ${palette.neutral[300]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.neutral[300]};
        }
        &:active {
            background: ${palette.neutral[300]};
            color: ${palette.neutral[700]};
        }

        &:disabled {
          opacity: .5;
          pointer-events: none;
        }
    }

    &.outlined {
        background: transparent;
        color: ${palette.primary[500]};
        border: ${border.normal} solid ${palette.primary[500]};

        &:hover {
            background: transparent;
            border: ${border.normal} solid ${palette.primary[400]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.primary[400]};
        }
        &:active {
            background: transparent;
            color: ${palette.primary[400]};
            border: ${border.normal} solid ${palette.primary[400]};
        }

        &:disabled {
          opacity: .5;
          pointer-events: none;
        }

        &.red {
            background: transparent;
            color: ${palette.destructive[500]};
            border: ${border.normal} solid ${palette.destructive[500]};

            &:hover {
                background: transparent;
                border: ${border.normal} solid ${palette.destructive[600]};
            }
            &:active {
                background: transparent;
                color: ${palette.destructive[700]};
                border: ${border.normal} solid ${palette.destructive[700]};
            }
        }
        &.neutral {
            background: transparent;
            color: white;
            border: ${border.normal} solid white;

            &:hover {
                border: ${border.normal} solid ${palette.neutral[50]};
                .icon {
                    background-color: ${palette.neutral[50]};
                }
            }
            &:focus {
                border: ${border.normal} solid ${palette.neutral[50]};
                .icon {
                    background-color: ${palette.neutral[50]};
                }
            }
            &:active {
                color: ${palette.neutral[100]};
                border: ${border.normal} solid ${palette.neutral[100]};
                .icon {
                    background-color: ${palette.neutral[100]};
                }
            }
        }
        &.neutral-on-darkmode {
            background: transparent;
            color: ${palette.neutral[50]};
            border: ${border.normal} solid ${palette.neutral[50]};
            &:hover {
                background: transparent;
                border: ${border.normal} solid ${palette.neutral[100]};
            }
            &:active {
                background: transparent;
                color: ${palette.neutral[100]};
                border: ${border.normal} solid ${palette.neutral[100]};
            }
        }
        &.green {
            background: transparent;
            color: ${palette.success[500]};
            border: ${border.normal} solid ${palette.success[500]};

            &:hover {
                background: transparent;
                border: ${border.normal} solid ${palette.success[600]};
            }
            &:active {
                background: transparent;
                color: ${palette.success[700]};
                border: ${border.normal} solid ${palette.success[700]};
            }
        }
    }

    &.left-icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: ${distances.tiny};
    }

    &.icon {
        background: transparent;
        color: ${palette.primary[500]};
        border: ${border.normal} solid transparent;

        &:hover {
            background: ${palette.primary[100]};
            border: ${border.normal} solid ${palette.primary[100]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.primary[300]};
        }
        &:active {
            background: ${palette.primary[300]};
            color: ${palette.primary[600]};
        }

        &:disabled {
          opacity: .5;
          pointer-events: none;
        }

        &.red {
            color: ${palette.destructive[500]};

            &:hover {
                background: ${palette.destructive[100]};
                border: ${border.normal} solid ${palette.destructive[100]};
            }
            &:focus {
                border: ${border.normal} solid ${palette.destructive[300]};
            }
            &:active {
                background: ${palette.destructive[300]};
                color: ${palette.destructive[600]};
            }
        }
        &.green {
            color: ${palette.success[500]};

            &:hover {
                background: ${palette.success[100]};
                border: ${border.normal} solid ${palette.success[100]};
            }
            &:focus {
                border: ${border.normal} solid ${palette.success[300]};
            }
            &:active {
                background: ${palette.success[300]};
                color: ${palette.success[600]};
            }
        }
        &.neutral {
            color: ${palette.neutral[500]};

            &:hover {
                background: ${palette.neutral[100]};
                border: ${border.normal} solid ${palette.neutral[100]};
            }
            &:focus {
                border: ${border.normal} solid ${palette.neutral[300]};
            }
            &:active {
                background: ${palette.neutral[300]};
                color: ${palette.neutral[700]};
            }
        }
        &.darkmode {
            color: ${palette.darkmode[100]};

            &:hover {
                background: ${palette.darkmode[200]};
                border: ${border.normal} solid ${palette.darkmode[200]};
            }
            &:focus {
                border: ${border.normal} solid ${palette.darkmode[200]};
            }
            &:active {
                background: ${palette.darkmode[300]};
                color: ${palette.darkmode[100]};
            }
        }
    }

    &.top-bar {
        display: flex;
        gap: ${distances.tiny};
        color: ${palette.neutral[600]};
        align-items: center;
        padding: 4px;
        min-height: unset;

        &:hover {
            color: ${palette.neutral[800]};
            background: white;
            border: ${border.normal} solid white;
        }
        &:focus {
            color: ${palette.neutral[800]};
            background: white;
            border: ${border.normal} solid white;
        }
        &:active, &[data-expanded='true'] {
            color: ${palette.neutral[900]};
            background: white;
            border: ${border.normal} solid white;
        }
    }

    &.slim {
        padding: 10px;
    }

    &.bg-white {
        background: white;
    }

    &.icon-20x20 {
        .icon {
            width: 20px;
            height: 20px;
        }
    }

    &.has-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${distances.tiny};
    }

    &.text {
        background: transparent;
        color: ${palette.primary[500]};
        border: none;
        padding: 0;
        font-size: 14px;
        line-height: 20px;
        &:hover {
            background: transparent;
            border: none;
        }
        &:focus {
            border: none;
        }
        &:active {
            background: transparent;
            color: ${palette.primary[600]};
        }
        &:disabled {
            color: ${palette.primary[300]};
        }
    }

    &.translucent {
        &:hover {
            background: rgba(255, 255, 255, 0.1);
            border: none;
        }
        &:focus {
            border: none;
        }
        &:active {
            background: rgba(255, 255, 255, 0.3);
        }
    }

    &[data-expanded="true"] {
        &.icon {
            background: ${palette.primary[300]};
            color: ${palette.primary[600]};
            &.red {
                background: ${palette.destructive[300]};
                color: ${palette.destructive[600]};
            }
            &.green {
                background: ${palette.success[300]};
                color: ${palette.success[600]};
            }
            &.neutral {
                background: ${palette.neutral[300]};
                color: ${palette.neutral[600]};
            }
            &.darkmode {
                background: ${palette.darkmode[300]};
                color: ${palette.darkmode[600]};
            }
        }
    }
`;

export default Button;
