import React from 'react';
import styled from 'styled-components/macro';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAccountId } from '../../../auth/useAccountId';
import { LoadingOverlay } from '../../../components/Loading';
import Page, { Back } from '../../../components/Page';
import { H1 } from '../../../components/Typography';
import { distances, globalPageMaxWidth } from '../../../styles/constants';
import type { Account } from '../../../types/management-auth';
import useConnectionEvents from '../hooks/useConnectionEvents';
import useMerchantDetails from '../hooks/useMerchantDetails';
import MerchantDetails from './MerchantDetails';
import NotFound from './NotFound';


const MerchantPage = () => {
    const { subAccountId } = useParams();
    const accountId = useAccountId();
    const { t } = useTranslation();

    const { merchant, merchantError, isLoadingMerchant, checkout, subscriptions, approvals, downloadSignupFile, editMerchant } = useMerchantDetails(subAccountId);

    const eventActions = useConnectionEvents(subAccountId);

    const handleMarkAsDeclined = (connection: string, description: string) => {
        if (accountId && subAccountId) {
            eventActions.addConnectionEvent(accountId, connection, {
                status: 'declined',
                event: 'declined_from_backoffice',
                description,
            });
        }
    };

    const lastConnectionEventSuccess = eventActions.lastConnectionEventSuccess;
    const lastConnectionEventFailure = eventActions.lastConnectionEventFailure;

    const triggerEditMerchant = (partnerId: string, account: Account) => {
        editMerchant({
            partnerId,
            subAccountId,
            account,
        });
    };

    return (
        <Page
            title={
                <React.Fragment>
                    <Back />
                    <Title>{t('partner.merchant.title')}</Title>
                </React.Fragment>
            }
            hasEnvFrame={true}
        >
            <MaxWidth>
                {merchant && (
                    <MerchantDetails
                        checkout={checkout}
                        account={merchant}
                        subscriptions={subscriptions || []}
                        partnerId={accountId}
                        downloadSignupFile={downloadSignupFile}
                        markAsDeclinedBag={{
                            handleMarkAsDeclined,
                            lastConnectionEventFailure,
                            lastConnectionEventSuccess,
                        }}
                        approvals={approvals || []}
                        editMerchant={triggerEditMerchant}
                    />
                )}
                {merchantError && <NotFound subAccountId={subAccountId} />}
                {isLoadingMerchant && <LoadingOverlay />}
            </MaxWidth>
        </Page>
    );
};

const MaxWidth = styled.div`
    position: relative;
    width: 100%;
    max-width: ${globalPageMaxWidth}px;
    min-height: calc(${distances.ginormous} + ${distances.large});
`;

const Title = styled(H1)`
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default MerchantPage;
