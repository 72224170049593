import useSWR from 'swr';
import { useAccountId } from '../../../../auth/useAccountId';
import useStore from '../../../../common/hooks/useExternalStore';
import useHasAnyOfScopes from '../../../../common/hooks/useHasAnyOfScopes';
import { actions, userDetailsStore } from '../observables/userDetailsStore';

export const useUserDetails = (userId: string) => {
    const { state } = useStore(userDetailsStore);
    const hasScopesForRead = useHasAnyOfScopes(['read:accounts', 'admin:accounts']);
    const accountId = useAccountId();
    const { data, isLoading } = useSWR(() => accountId && userId && hasScopesForRead ? {
        accountId,
        userId,
    } : null, actions.getUser);

    return {
        ...state,
        user: data?.user,
        users: data?.users || [],
        isLoading,
    };
};
