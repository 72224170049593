type ActionArgs<T, R extends {
    // eslint-disable-next-line space-before-function-paren
    [key: string]: (state: T, ...args: any[]) => T;
}> = {
        [K in keyof R]: R[K] extends (state: T, ...args: infer A) => T ? A : never;
    };

type Listener = <T>(state: T, prevState: T, actionName?: string) => void;

type Options = {
    /** Log snapshots (useful for debugging values when getter is called) */
    logSnapshots?: boolean;
    /** Log selections (useful for debugging values when select is called) */
    logSelections?: boolean;
    /** Log subscription events */
    logSubscriptions?: boolean;
    /** Log dispatch events */
    logDispatches?: boolean;
    /** Store state temporarily within session storage */
    useSessionStorage?: boolean;
};

const defaultOptions: Options = {
    logSnapshots: false,
    logSelections: false,
    logSubscriptions: false,
    logDispatches: true,
    useSessionStorage: false,
};

/**
 * Creates an external store that can be used to manage state outside of React components.
 * @param initialState Initial state to be used by the store
 * @param actions Actions that can be dispatched to update the state
 * @param name Name of the store, used for logging and debugging
 * @param options Options for the store
 * @returns External store with the given initial state and actions
 */
export default function createExternalStore<T, R extends {
    // eslint-disable-next-line space-before-function-paren
    [key: string]: (state: T, ...args: any[]) => T;
}>(initialState: T, actions: R, name: string, options = defaultOptions) {
    let state = options?.useSessionStorage ? getPreviousStateInSessionStorage(name, initialState) : initialState;
    const subscribers = new Set<Listener>();

    const externalStore = {
        getSnapshot: ((value: T) => () => {
            if (value !== state) {
                value = state;
            }
            if (options?.logSnapshots) {
                log('log', name, '📸 Snapshot', value);
            }
            return value;
        })(state),
        dispatch(actionName: keyof R, ...args: ActionArgs<T, R>[keyof R]) {
            try {
                const newState = actions[actionName](state, ...args);

                if (options?.logDispatches) {
                    log('log', name, `🔄 Action: ${String(actionName)}`, {
                        state,
                        newState,
                        args,
                    });
                }

                if (!Object.is(newState, state)) {
                    const previousState = state;
                    state = newState;
                    subscribers.forEach(subscriber => subscriber(state, previousState, String(actionName)));
                }
            } catch (error) {
                log('warning', name, `❌ Action: ${String(actionName)}`, error);
            }
        },
        subscribe: (listener: Listener) => {
            subscribers.add(listener);
            if (options?.logSubscriptions) {
                log('log', name, '🔔 Subscribed');
            }
            return () => {
                if (options?.logSubscriptions) {
                    log('warning', name, '🔕 Unsubscribed');
                }
                subscribers.delete(listener);
            };
        },
        select: <U>(selector: (state: T) => U) => {
            const selected = selector(state);
            if (options?.logSelections) {
                log('log', name, '🔍 Selector', selected);
            }
            return selected;
        },
    };

    integrateWithReduxDevtools(externalStore, name);

    if (options?.useSessionStorage) {
        cacheInSessionStorage(externalStore, name);
    }

    return externalStore;
}

export type ExternalStore<State> = ReturnType<typeof createExternalStore> & {
    getSnapshot: () => State;
    select: <U>(selector: (state: State) => U) => U;
};

const getPreviousStateInSessionStorage = <T>(name: string, initialState: T) => {
    if (window.sessionStorage) {
        try {
            const key = `XStore::${name}`;
            const cachedState = sessionStorage.getItem(key);
            if (cachedState) {
                return JSON.parse(cachedState) as T;
            }
        } catch (error) {
            log('warning', name, '❌ Failed to get cached state from sessionStorage', error);
        }
    }
    return initialState;
};

const cacheInSessionStorage = <T>(store: ExternalStore<T>, name: string) => {
    if (window.sessionStorage) {
        try {
            const key = `XStore::${name}`;
            store.subscribe(state => {
                sessionStorage.setItem(key, JSON.stringify(state));
                log('log', name, '📦 Cached to sessionStorage', state);
            });
        } catch (error) {
            log('warning', name, '❌ Failed to cache to sessionStorage', error);
        }
    }
    return store;
};

/** Add basic support for showing the state of the external store within the Redux devtool */
const integrateWithReduxDevtools = <T>(store: ExternalStore<T>, name: string) => {
    if (process.env.NODE_ENV === 'development' && (window as any).__REDUX_DEVTOOLS_EXTENSION__) {
        const storeName = name || 'EXTERNAL_STORE';
        const devToolsName = `🔧 XStore::${storeName}`;
        const devTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__.connect({
            name: devToolsName,
        });
        devTools.init(store.getSnapshot());
        log('log', storeName, '🔧 Connected to Redux DevTools');
        store.subscribe((state, prevState, actionName) => {
            devTools.send({ type: `🔄 ${actionName}`, state, prevState }, state);
        });
    }
    return store;
};

const log = (type: 'log' | 'warning', name: string, ...args: any[]) => {
    if (process.env.NODE_ENV === 'development') {
        const styles = [...style.base, ...style[type]].join(';');
        console.debug(`%cXStore::${name}`, styles, ...args);
    }
};

const style = {
    base: [
        'color: #fff',
        'padding: 2px 4px',
        'border-radius: 2px',
    ],
    log: [
        'background: #007bff',
        'color: #fff',
    ],
    warning: [
        'background: #ff0',
        'color: #000',
    ],
} as const;
