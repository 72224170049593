import getConnections from '../../helpers/getConnections';
import useAccount from '../../settings/account/hooks/useAccount';

export default function usePaymentsEnabled() {
    const { account, isLoading } = useAccount();
    if (isLoading) {
        return true; // hide the link while loading
    }

    if (!account) {
        return false;
    }

    const numEnabledConnections = getConnections(account, 'enabled').length;

    return account.account_id?.endsWith('00000000') || numEnabledConnections > 0;
}
