import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import Card from '../../../components/Card';
import { Json } from '../../../components/Formatters';
import Hr from '../../../components/Hr';
import { H3 } from '../../../components/Typography';
import { distances, palette } from '../../../styles/constants';
import { Account } from '../../../types/management-auth';
import { MarkAsDeclined, MarkAsDeclinedBag } from './MarkAsDeclined';

export interface PaymentConnectionsStatusProps {
    account: Account;
    markAsDeclinedBag: MarkAsDeclinedBag;
}

const PaymentConnectionsStatus = (
    {
        account,
        markAsDeclinedBag,
    }: PaymentConnectionsStatusProps
) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState<undefined | string>();
    const toggle = (val: string) => {
        setOpen(open === val ? undefined : val);
    };

    if (!account.connections) {
        return null;
    }
    return (
        <Card marginBottom={distances.normal}>
            <H3>{t('partner.merchant.payment_connections_status')}</H3>
            <Hr />
            {account.connections.vipps && (
                <PaymentProvider>
                    <PaymentProviderRow onClick={() => toggle('vipps')}>
                        <PaymentProviderText>Vipps</PaymentProviderText>
                        <div>
                            {account.connections.vipps.status && (
                                <ConnectionBadge status={account.connections.vipps.status}>
                                    {account.connections.vipps.status}
                                </ConnectionBadge>
                            )}
                        </div>
                    </PaymentProviderRow>
                    {open === 'vipps' && <>
                        <PaymentProviderContent>
                            <Json data={account.connections.vipps} />
                        </PaymentProviderContent>
                        <PaymentProviderActions>
                            <MarkAsDeclined status={account.connections.vipps.status} markAsDeclinedBag={markAsDeclinedBag} connection="vipps" />
                        </PaymentProviderActions>
                    </>}
                </PaymentProvider>
            )}

            {account.connections.payex && account.connections.payex.schema !== 2 && account.connections.payex.creditcard && (
                <PaymentProvider>
                    <PaymentProviderRow onClick={() => toggle('payex')}>
                        <PaymentProviderText>
                            Swedbank Pay{' '}
                            {account.connections.payex.creditcard.signup.payment_options
                                .map((p) => t(`payments.product_types.${p.type}`))
                                .join(', ')}
                        </PaymentProviderText>
                        <div>
                            {account.connections.payex.creditcard.status && (
                                <ConnectionBadge status={account.connections.payex.creditcard.status}>
                                    {account.connections.payex.creditcard.status}
                                </ConnectionBadge>
                            )}
                        </div>
                    </PaymentProviderRow>
                    {open === 'payex' && <>
                        <PaymentProviderContent>
                            <Json data={account.connections.payex.creditcard} />
                        </PaymentProviderContent>
                        <PaymentProviderActions>
                            <MarkAsDeclined status={account.connections.payex.creditcard.status} markAsDeclinedBag={markAsDeclinedBag} connection="payex" />
                        </PaymentProviderActions>
                    </>}
                </PaymentProvider>
            )}

            {account.connections.payex && account.connections.payex.schema === 2 && (
                <PaymentProvider>
                    <PaymentProviderRow onClick={() => toggle('payex')}>
                        <PaymentProviderText>
                            Swedbank Pay{' '}
                            {(account.connections.payex.configuration?.payment_options ?? account.connections.payex.signup?.payment_options).map(
                                (p) => t(`payments.product_types.${p.type}`)
                            )
                                .join(', ')}
                        </PaymentProviderText>
                        <div>
                            {account.connections.payex.status && (
                                <ConnectionBadge status={account.connections.payex.status}>
                                    {account.connections.payex.status}
                                </ConnectionBadge>
                            )}
                        </div>
                    </PaymentProviderRow>

                    {open === 'payex' && <>
                        <PaymentProviderContent>
                            <Json data={account.connections.payex} />
                        </PaymentProviderContent>
                        <PaymentProviderActions>
                            <MarkAsDeclined status={account.connections.payex.status} markAsDeclinedBag={markAsDeclinedBag} connection="payex" />
                        </PaymentProviderActions>
                    </>}
                </PaymentProvider>
            )}

            {account.connections.collector && (
                <PaymentProvider>
                    <PaymentProviderRow onClick={() => toggle('collector')}>
                        <PaymentProviderText>
                            Collector{' '}
                            {(account.connections.collector.signup?.payment_options || (account.connections.collector as any).invoice?.signup?.payment_options || [])
                                .map((p) => t(`payments.product_types.${p.type}`))
                                .join(', ')}
                        </PaymentProviderText>
                        <div>
                            {account.connections.collector.status && (
                                <ConnectionBadge status={account.connections.collector.status}>
                                    {account.connections.collector.status}
                                </ConnectionBadge>
                            )}
                        </div>
                    </PaymentProviderRow>
                    {open === 'collector' && <>
                        <PaymentProviderContent>
                            <Json data={account.connections.collector} />
                        </PaymentProviderContent>
                        <PaymentProviderActions>
                            <MarkAsDeclined status={account.connections.collector.status} markAsDeclinedBag={markAsDeclinedBag} connection="collector" />
                        </PaymentProviderActions>
                    </>}
                </PaymentProvider>
            )}

            {account.connections.instabank && (
                <PaymentProvider>
                    <PaymentProviderRow onClick={() => toggle('instabank')}>
                        <PaymentProviderText>
                            Instabank{' '}
                            {account.connections.instabank.signup.payment_options
                                .map((p) => t(`payments.product_types.${p.type}`))
                                .join(', ')}
                        </PaymentProviderText>
                        <div>
                            {account.connections.instabank.status && (
                                <ConnectionBadge status={account.connections.instabank.status}>
                                    {account.connections.instabank.status}
                                </ConnectionBadge>
                            )}
                        </div>
                    </PaymentProviderRow>
                    {open === 'instabank' &&
                        <PaymentProviderContent>
                            <Json data={account.connections.instabank} />
                        </PaymentProviderContent>}
                </PaymentProvider>
            )}

            {account.connections.bambora && (
                <PaymentProvider>
                    <PaymentProviderRow onClick={() => toggle('bambora')}>
                        <PaymentProviderText>
                            Bambora{' '}
                            {(account.connections.bambora.configuration?.payment_options || account.connections.bambora.signup?.payment_options || [])
                                .map(p => p.type)
                                .filter((type): type is 'bambora.creditcard' | 'bambora.mobilepay' | 'bambora.vipps' => !!type)
                                .map((type) => t(`payments.product_types.${type}`))
                                .join(', ')}
                        </PaymentProviderText>
                        <div>
                            {account.connections.bambora.status && (
                                <ConnectionBadge status={account.connections.bambora.status}>
                                    {account.connections.bambora.status}
                                </ConnectionBadge>
                            )}
                        </div>
                    </PaymentProviderRow>
                    {open === 'bambora' && <>
                        <PaymentProviderContent>
                            <Json data={account.connections.bambora} />
                        </PaymentProviderContent>
                        <PaymentProviderActions>
                            <MarkAsDeclined status={account.connections.bambora.status} markAsDeclinedBag={markAsDeclinedBag} connection="bambora" />
                        </PaymentProviderActions>
                    </>}
                </PaymentProvider>
            )}

            {account.connections.swish && (
                <PaymentProvider>
                    <PaymentProviderRow onClick={() => toggle('swish')}>
                        <PaymentProviderText>
                            {(account.connections.swish.configuration?.payment_options || account.connections.swish.signup?.payment_options || [])
                                .map((p) => t(`payments.product_types.${p.type}`))
                                .join(', ')}
                        </PaymentProviderText>
                        <div>
                            {account.connections.swish.status && (
                                <ConnectionBadge status={account.connections.swish.status}>
                                    {account.connections.swish.status}
                                </ConnectionBadge>
                            )}
                        </div>
                    </PaymentProviderRow>
                    {open === 'swish' && <>
                        <PaymentProviderContent>
                            <Json data={account.connections.swish} />
                        </PaymentProviderContent>
                        <PaymentProviderActions>
                            <MarkAsDeclined status={account.connections.swish.status}
                                markAsDeclinedBag={markAsDeclinedBag} connection="swish" />
                        </PaymentProviderActions>
                    </>}
                </PaymentProvider>
            )}
        </Card>
    );
};

export default PaymentConnectionsStatus;

const badgeColors: { [key: string]: any } = {
    enabled: palette.success[600],
    pending: palette.warning[500],
    declined: palette.destructive[500],
};

interface BadgeProps {
    status: string;
}

const ConnectionBadge = styled.span<BadgeProps>`
    font-weight: 700;
    font-size: 12px;
    padding: 6px 12px;
    margin-left: ${distances.tiny};
    border-radius: 50px;
    color: ${palette.neutral[50]};
    text-transform: capitalize;
    background: ${(props) => badgeColors[props.status]};
`;
const PaymentProviderText = styled.div`
    display: inline-block;
    font-weight: 700;
`;

const PaymentProvider = styled.div`
    margin-bottom: ${distances.tiny};
    border-radius: ${distances.tiny};
    border: 2px solid ${palette.neutral[50]};

    &:hover {
        background-color: ${palette.neutral[50]};
    }
`;
const PaymentProviderContent = styled.div`
    margin-bottom: ${distances.tiny};

    &:hover {
        background-color: ${palette.neutral[50]};
    }
`;
const PaymentProviderActions = styled.div`
    display: flex;
    padding: ${distances.small};
`;

const PaymentProviderRow = styled.div`
    padding: ${distances.small};
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;
