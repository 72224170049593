import authStore from './auth/accessToken/observables/authStore';
import { version } from './env';
import { Causes, errorExternalStore } from './errors';
import { expiresAt } from './helpers/jwt';

interface StatusCodes {
    [code: number]: string;
}

const statusCodes: StatusCodes = {
    100: 'Continue',
    101: 'Switching Protocols',
    102: 'Processing',
    200: 'OK',
    201: 'Created',
    202: 'Accepted',
    203: 'Non-authoritative Information',
    204: 'No Content',
    205: 'Reset Content',
    206: 'Partial Content',
    207: 'Multi-Status',
    208: 'Already Reported',
    226: 'IM Used',
    300: 'Multiple Choices',
    301: 'Moved Permanently',
    302: 'Found',
    303: 'See Other',
    304: 'Not Modified',
    305: 'Use Proxy',
    307: 'Temporary Redirect',
    308: 'Permanent Redirect',
    400: 'Bad Request',
    401: 'Unauthorized',
    402: 'Payment Required',
    403: 'Forbidden',
    404: 'Not Found',
    405: 'Method Not Allowed',
    406: 'Not Acceptable',
    407: 'Proxy Authentication Required',
    408: 'Request Timeout',
    409: 'Conflict',
    410: 'Gone',
    411: 'Length Required',
    412: 'Precondition Failed',
    413: 'Payload Too Large',
    414: 'Request-URI Too Long',
    415: 'Unsupported Media Type',
    416: 'Requested Range Not Satisfiable',
    417: 'Expectation Failed',
    421: 'Misdirected Request',
    422: 'Unprocessable Entity',
    423: 'Locked',
    424: 'Failed Dependency',
    426: 'Upgrade Required',
    428: 'Precondition Required',
    429: 'Too Many Requests',
    431: 'Request Header Fields Too Large',
    444: 'Connection Closed Without Response',
    451: 'Unavailable For Legal Reasons',
    499: 'Client Closed Request',
    500: 'Internal Server Error',
    501: 'Not Implemented',
    502: 'Bad Gateway',
    503: 'Service Unavailable',
    504: 'Gateway Timeout',
    505: 'HTTP Version Not Supported',
    506: 'Variant Also Negotiates',
    507: 'Insufficient Storage',
    508: 'Loop Detected',
    510: 'Not Extended',
    511: 'Network Authentication Required',
    599: 'Network Connect Timeout Error',
};

export interface HttpHeaders {
    [headerName: string]: string;
}


interface RequestObject {
    method: string;
    headers: Headers;
    body: undefined | Blob;
}

/** Given a token get an object representing the Authorization header */
const getAuthorizationBearerHeader = (token: string) => {
    const headers: HttpHeaders = {
        Authorization: `Bearer ${token}`,
    };
    return headers;
};

const getSystemHeaders = () => {
    const headers: HttpHeaders = {
        'Dintero-System-Name': 'backoffice',
        'Dintero-System-Version': `${version || ''}`,
    };
    return headers;
};

type HttpHandlerFunction<T, K> = (response: T, headers: Headers) => K;

interface HttpHandlers {
    [status: number]: HttpHandlerFunction<any, any>;
}

async function fetchOrDispatchError(url: string, requestObj: RequestObject, cacheBuster = true) {
    try {
        const cacheBustedUrl = cacheBuster ? addCacheBustParam(url) : url;
        const response = await fetch(cacheBustedUrl, requestObj);
        return response;
    } catch (error) {
        // https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS/Errors
        errorExternalStore.dispatch(
            'setError',
            {
                cause: Causes.FetchException,
                message: error.message,
            }
        );
        throw error;
    }
}

async function getResponseText(response: Response) {
    try {
        return await response.text();
    } catch (error) {
        return '';
    }
}

async function getResponseJson(response: Response) {
    try {
        return await response.json();
    } catch (error) {
        return {};
    }
}

async function getResponseBlob(response: Response) {
    try {
        return await response.blob();
    } catch (error) {
        return {};
    }
}

interface FulfillNoBody {
    url: string;
    headers?: HttpHeaders;
    handlers: HttpHandlers;
    accountId: string;
    noAuthentication?: boolean;
    cacheBuster?: boolean;
}

interface FulfillWithJson<T> extends FulfillNoBody {
    json?: T;
}
interface FulfillWithBody extends FulfillNoBody {
    body?: Blob;
}

const getAccountAuthHeader = (obj: FulfillNoBody) => {
    if (obj.noAuthentication) {
        return;
    }
    if (!obj.accountId) {
        throw Error('no namespace');
    }
    if (obj.accountId) {
        const tokens = authStore.select(state => state.tokens);
        if (tokens[obj.accountId]) {
            return getAuthorizationBearerHeader(tokens[obj.accountId]);
        }
    }
};

function createBodyBlob<T>(obj: FulfillNoBody | FulfillWithJson<T> | FulfillWithBody): Blob |undefined {
    if ('body' in obj) {
        return obj.body;
    }
    if ('json' in obj) {
        return new Blob([JSON.stringify(obj.json)], { type: 'application/json; charset=utf-8' });
    }
    return undefined;
}

const authTokenExpired = (headers: HttpHeaders | undefined) => {
    try {
        const authToken = (headers || {})['Authorization'] || '';
        const [bearer, token] = authToken.split(' ');
        if (bearer === 'Bearer') {
            const expiry = expiresAt(token || '');
            return expiry < new Date();
        }
        // try to inspect token
    } catch (e) {
        console.error(e);
    }
    return false;
};

async function fulfill<T>(method: string, obj: FulfillNoBody | FulfillWithJson<T> | FulfillWithBody) {
    const requestObj: RequestObject = {
        method: method,
        headers: new Headers(getSystemHeaders()),
        body: undefined,
    };

    // Add headers from namespace
    const headers = getAccountAuthHeader(obj);
    if (headers) {
        Object.entries(headers).forEach(([name, value]) => {
            requestObj.headers.append(name, value);
        });
    }

    // Add invocation headers
    if (obj.headers) {
        Object.entries(obj.headers).forEach(([name, value]) => {
            requestObj.headers.append(name, value);
        });
    }

    // Add body
    if (['PUT', 'POST'].includes(method)) {
        const blob = createBodyBlob(obj);
        if (blob) {
            // The blob handles Content-Type and file content length headers
            requestObj.body = blob;
        } else {
            // If Content-Type is not specified, we still add the json content type
            // since some of our servers expect it to be present even though we
            // do not set a body in our request
            if (!requestObj.headers.get('Content-Type')) {
                requestObj.headers.append('Content-Type', 'application/json; charset=utf-8');
            }
        }
    }

    // Fetch
    const response = await fetchOrDispatchError(obj.url, requestObj, obj.cacheBuster);

    // Handle response
    const requestId = response.headers.get('request-id') || undefined;

    // If the response is a 403 or a 401, check if the response was caused by expired token before triggering handler
    const shouldSkipHandler = (response.status === 403 || response.status === 401) && authTokenExpired(headers);

    try {
        // Handle response with specified handler for the response status code.
        // Returns the result from handler function.
        const handler = shouldSkipHandler ? undefined : obj.handlers[response.status];
        if (response.status === 204 && handler) {
            return handler({}, response.headers);
        } else if (handler) {
            const contentType = response.headers.get('content-type');
            if (contentType && contentType.includes('application/json')) {
                const responseJson = await getResponseJson(response);
                return handler(responseJson, response.headers);
            } else if (contentType && contentType.includes('application/pdf')) {
                const responseBlob = await getResponseBlob(response);
                return handler(responseBlob, response.headers);
            } else {
                const responseText = getResponseText(response);
                return handler(responseText, response.headers);
            }
        }
    } catch (error) {
        // An unexpected error occurred while handling the response.
        errorExternalStore.dispatch(
            'setError',
            {
                cause: Causes.HandlerRaisedException,
                message: error.message,
                status: response.status,
                statusText: response.statusText || statusCodes[response.status],
                'request-id': requestId,
            }
        );
    }

    // The response returned an unexpected status not handled by the application.
    const responseText = await getResponseText(response);
    const cause = authTokenExpired(headers) ? Causes.AccessTokenExpired : Causes.UnhandledStatus;
    errorExternalStore.dispatch(
        'setError',
        {
            cause,
            status: response.status,
            statusText: response.statusText || statusCodes[response.status],
            message: responseText,
            'request-id': requestId,
            url: obj.url,
            method,
        }
    );
}

abstract class FulfillByMethod {
    public static get(properties: FulfillNoBody) {
        return fulfill('GET', properties);
    }
    public static delete(properties: FulfillNoBody) {
        return fulfill('DELETE', properties);
    }
    public static post<T>(properties: FulfillWithJson<T> | FulfillWithBody) {
        return fulfill('POST', properties);
    }
    public static put<T>(properties: FulfillWithJson<T> | FulfillWithBody) {
        return fulfill('PUT', properties);
    }
}

const addCacheBustParam = (url: string) => {
    if (url.indexOf('?') > -1) {
        return `${url}&_v=${getCacheBusterValue()}`;
    }
    return `${url}?_v=${getCacheBusterValue()}`;
};

const getCacheBusterValue = () => {
    return new Date().valueOf() + Math.random().toString(36).substring(7);
};

export default FulfillByMethod;
export {
    addCacheBustParam, getAuthorizationBearerHeader,
    getSystemHeaders
};
