import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { namespace } from './actions';
import { useActions } from '../../../Actions';
import { getApprovalsSelector } from './selectors';
import { useAccountIds } from '../../../auth/accessToken/components/withAccountIds';
import useDocumentVisibleAgainEffect from '../../../util/useDocumentVisibleAgainEffect';
import { useInterval } from '../../../common/hooks/useInterval';

export const usePaymentApprovals = () => {
    const { postApproval, fetchPaymentApprovals } = useActions(namespace);
    const fetchPaymentApprovalsRef = useRef(fetchPaymentApprovals);
    const { prodAccountId } = useAccountIds();
    const { approvals, isLoading, accountId: stateAccountId, isPosting, posted } = useSelector(getApprovalsSelector);

    // Load on initial use and when prodAccountId changes
    useEffect(() => {
        if (prodAccountId && prodAccountId !== stateAccountId) {
            fetchPaymentApprovalsRef.current(prodAccountId);
        }
    }, [
        prodAccountId, stateAccountId, fetchPaymentApprovalsRef,
    ]);

    // Load on regained browser tab focus
    useDocumentVisibleAgainEffect(() => {
        if (prodAccountId && !isLoading && !isPosting) {
            fetchPaymentApprovalsRef.current(prodAccountId, false);
        }
    });

    // Update approval status silently once every minute
    useInterval(() => {
        if (prodAccountId && !isLoading && !isPosting) {
            fetchPaymentApprovalsRef.current(prodAccountId, true);
        }
    }, 60 * 1000);


    return {
        isLoading,
        isPosting,
        approvals: approvals || [],
        postApproval,
        posted,
    };
};
