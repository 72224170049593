import useSWR from 'swr';
import { useAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import { CORE_API_HOSTNAME } from '../../../../env';
import fulfill from '../../../../fulfill';
import type { Subscription } from '../../../../types/webhooks';
import { sortElements } from '../../../helpers';

const fetchWebhooks = async (payload: {
    accountIds: string[];
}) => {
    const promises: Promise<Subscription[]>[] = payload.accountIds.map(accountId =>
        fulfill.get({
            accountId: accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/hooks/subscriptions?include_deleted=true&limit=100`,
            handlers: {
                200: (hooks: Subscription[]) => hooks,
                403: () => [],
            },
        }));
    const results = await Promise.all(promises);
    const hooks = results.reduce((acc: Subscription[], result) => [...acc, ...result], []);
    return sortElements(hooks);
};

export const useWebhooks = () => {
    const { prodAccountId, testAccountId } = useAccountIds();
    const { data, isLoading } = useSWR(() => ({
        accountIds: [prodAccountId, testAccountId].filter(x => x) as string[],
    }), fetchWebhooks);

    return {
        hooks: data || [],
        isLoading,
    };
};
