import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { useSelector } from 'react-redux';
import { namespace } from '..';
import { useActions } from '../../../../Actions';
import { useAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { LoadingOverlay } from '../../../../components/Loading';
import Modal from '../../../../components/Modal';
import { H1, P } from '../../../../components/Typography';
import { distances, grid } from '../../../../styles/constants';
import { PayExModalState } from '../actions';
import PayExForm from './PayExForm';

const PayExModal = () => {
    const { t } = useTranslation();
    const { closePayExModal } = useActions(namespace);
    const { prodAccountId } = useAccountIds();
    const { modalState, account } = useSelector((state) => ({
        modalState: state[namespace].modalState,
        account: state[namespace].account,
    }));

    if (!prodAccountId) {
        return null;
    }

    if (modalState === PayExModalState.Closed) {
        return null;
    }

    if (!account) {
        return <LoadingOverlay />;
    }
    return (
        (<Modal fixedWidth>
            {[PayExModalState.ApplicationFormOpened, PayExModalState.InitiateApplicationLoading].includes(
                modalState
            ) && (
                <>
                    <H1>{t('settings.payment_connections.payex_modal.title_intro')}</H1>
                    <PayExForm />
                    {modalState === PayExModalState.InitiateApplicationLoading && (
                        <LoadingOverlay />
                    )}
                </>
            )}
            {modalState === PayExModalState.ApplicationReceived && (
                <>
                    <H1>{t('settings.payment_connections.payex_modal.title_last_step')}</H1>

                    <SpacedWrapper>
                        <Trans i18nKey="settings.payment_connections.payex_modal.last_step">
                            <P className="margin-bottom">
                                You will be able to accept Visa and Mastercard payments once PayEx/Swedbank has accepted
                                your application.
                            </P>
                            <P>
                                In some cases PayEx/Swedbank might reach out to you with some additional questions about
                                the nature of your business before the application is approved.
                            </P>
                        </Trans>
                    </SpacedWrapper>

                    <ButtonGroup>
                        <Button onClick={closePayExModal}>{t('settings.payment_connections.payex_modal.close')}</Button>
                    </ButtonGroup>
                </>
            )}
        </Modal>)
    );
};

export default PayExModal;

const SpacedWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;

const InputWrapper = styled.div`
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }
`;
