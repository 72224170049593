import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Card from '../../../components/Card';
import { colors, palette } from '../../../styles/constants';
import { CheckoutConfig } from '../../../types/checkout';
import { Account } from '../../../types/management-auth';
import { ApprovalsPaymentResponse } from '../../../types/management-auth/generated';
import PaymentLogoMark from './PaymentLogoMark';
import { PaymentMethod } from './types';

const STATUS_MIN_WIDTH = 150;

type MethodStatusProps = {
    isConfigured: boolean;
    isPending: boolean;
    isDeclined: boolean;
    isFailed: boolean;
}

const MethodStatus = ({
    isConfigured,
    isPending,
    isDeclined,
    isFailed,
}: MethodStatusProps) => {
    const { t } = useTranslation();
    if (isConfigured) {
        return (
            <BaseStatus background={colors.valid}>
                {t('settings.approvalsPaymentsConfiguration.active')}
            </BaseStatus>
        );
    }
    if (isDeclined) {
        return (
            <BaseStatus background={palette.neutral[100]} text={palette.neutral[700]} borderColor={palette.neutral[200]}>
                {t('settings.approvalsPaymentsConfiguration.declined')}
            </BaseStatus>
        );
    }
    if (isFailed) {
        return (
            <BaseStatus background={palette.neutral[100]} text={palette.neutral[700]} borderColor={palette.neutral[200]}>
                {t('settings.approvalsPaymentsConfiguration.failed')}
            </BaseStatus>
        );
    }
    if (isPending) {
        return (
            <BaseStatus background={palette.neutral[100]} text={palette.neutral[700]} borderColor={palette.neutral[200]}>
                {t('settings.approvalsPaymentsConfiguration.processing')}
            </BaseStatus>
        );
    }
    return <EmptyStatus>
        {t('settings.approvalsPaymentsConfiguration.noStatus')}
    </EmptyStatus>;
};

const resolveConnectionsStatuses = (account: Account, status: string) => {
    // Trying to map the gateway connections to the payment types where
    // possible. Some gateways deliver more than one payment type so in those
    // cases we might not be able to map the connection to all the payment
    // types that are pending / failed or declined.
    const result = [];
    if (account?.connections?.vipps?.status === status) {
        result.push('vipps');
    }
    if (account?.connections?.payex?.status === status) {
        result.push('creditcard');
    }
    if (account?.connections?.bambora?.status === status) {
        result.push('creditcard');
    }
    if (account?.connections?.swish?.status === status) {
        result.push('swish');
    }
    if (account?.connections?.collector?.status === status) {
        result.push('walley');
    }
    if (account?.connections?.instabank?.status === status) {
        result.push('instabank');
    }
    return [...(new Set(result))] as PaymentMethod[];
};

type PaymentMethodConfigurationProps = {
    approvals: ApprovalsPaymentResponse[];
    checkoutConfiguration: CheckoutConfig | undefined;
    account: Account;
}
type PaymentTypeGroupProperties = {
    pendingConnections: PaymentMethod[];
    failedConnections: PaymentMethod[];
    declinedConnections: PaymentMethod[];
    configuredPaymentOptions: CheckoutConfig['configuration']['payment_options'];
    appliedForPaymentMethods: ApprovalsPaymentResponse['payment_methods'];
    approvalDeclined: boolean;
    approvalFailed: boolean;
    hasNonActiveCase: boolean;
}

const checkHasCaseButNotActive = (approvals: ApprovalsPaymentResponse[]) => {
    const active = approvals.some(a => a.case_status === 'ACTIVE');
    return approvals.length > 0 && !active;
};

const PaymentTypeGroupDefaults = ({ pendingConnections, failedConnections, declinedConnections, configuredPaymentOptions, appliedForPaymentMethods, approvalDeclined, approvalFailed }: PaymentTypeGroupProperties) => {

    const appliedForCreditcard = appliedForPaymentMethods.some(x => x.payment_method === 'creditcard');
    const appliedForVipps = appliedForPaymentMethods.some(x => x.payment_method === 'vipps');
    const appliedForKlarna = appliedForPaymentMethods.some(x => x.payment_method === 'klarna');
    const appliedForWalley = appliedForPaymentMethods.some(x => x.payment_method === 'walley');

    return (
        <>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="visa" label="Visa" />
                    <PaymentLogoMark logo="mastercard" label="Mastercard" />
                    <LabelText>Visa / Mastercard</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some(x => ['payex.creditcard', 'bambora.creditcard'].includes(x.type))}
                        isPending={appliedForCreditcard || pendingConnections.some(x => x === 'creditcard')}
                        isFailed={
                            (appliedForCreditcard && approvalFailed) || failedConnections.some(x => x === 'creditcard')
                        }
                        isDeclined={
                            (appliedForCreditcard && approvalDeclined) || declinedConnections.some(x => x === 'creditcard')
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="vipps" label="Vipps" />
                    <LabelText>Vipps</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some(x => ['vipps', 'payex.vipps', 'bambora.vipps'].includes(x.type))}
                        isPending={appliedForVipps  || pendingConnections.some(x => x === 'vipps')}
                        isFailed={
                            (appliedForVipps && approvalFailed) || failedConnections.some(x => x === 'vipps')
                        }
                        isDeclined={
                            (appliedForVipps && approvalDeclined) || declinedConnections.some(x => x === 'vipps')
                        }
                    />
                </StatusWrapper>

            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="klarna" label="Klarna" />
                    <LabelText>Klarna</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some(x => x.type === 'klarna.klarna')}
                        isPending={appliedForKlarna || pendingConnections.some(x => x === 'klarna')}
                        isFailed={
                            (appliedForKlarna && approvalFailed) || failedConnections.some(x => x === 'klarna')
                        }
                        isDeclined={
                            (appliedForKlarna && approvalDeclined) || declinedConnections.some(x => x === 'klarna')
                        }
                    />
                </StatusWrapper>

            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="walley" label="Walley" />
                    <LabelText>Walley</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some(x => ['collector.invoice', 'collector.finance', 'collector.installment', 'collector.invoice_b2b', 'collector.invoice_b2b_preapproved'].includes(x.type))}
                        isPending={appliedForWalley || pendingConnections.some(x => x === 'walley')}
                        isFailed={
                            (appliedForWalley && approvalFailed) || failedConnections.some(x => x === 'walley')
                        }
                        isDeclined={
                            (appliedForWalley && approvalDeclined) || declinedConnections.some(x => x === 'walley')
                        }
                    />
                </StatusWrapper>
            </PaymentType>
        </>
    );
};

const PaymentTypeGroupAdditional = ({ pendingConnections, failedConnections, declinedConnections, configuredPaymentOptions, appliedForPaymentMethods, approvalDeclined, approvalFailed }: PaymentTypeGroupProperties) => {

    const appliedForApplepay = appliedForPaymentMethods.some(x => x.payment_method === 'applepay');
    const appliedForGooglepay = appliedForPaymentMethods.some(x => x.payment_method === 'googlepay');
    const appliedForClickToPay = appliedForPaymentMethods.some(x => x.payment_method === 'clicktopay');
    const appliedForMobilepay = appliedForPaymentMethods.some(x => x.payment_method === 'mobilepay');
    const appliedForSwish = appliedForPaymentMethods.some(x => x.payment_method === 'swish');
    const appliedForBillie = appliedForPaymentMethods.some(x => x.payment_method === 'billie');
    const appliedForInstabank = appliedForPaymentMethods.some(x => x.payment_method === 'instabank');

    const hasInstabank = (
        appliedForInstabank ||
        pendingConnections.some(x => x === 'instabank') ||
        configuredPaymentOptions.some(x => x.type.startsWith('instabank'))
    );

    return (
        <>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="applepay" label="Apple Pay" />
                    <LabelText>Apple Pay</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some(x => ['payex.applepay'].includes(x.type))}
                        isPending={appliedForApplepay}
                        isFailed={
                            (appliedForApplepay && approvalFailed) || failedConnections.some(x => x === 'applepay')
                        }
                        isDeclined={
                            (appliedForApplepay && approvalDeclined) || declinedConnections.some(x => x === 'applepay')
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="googlepay" label="Google Pay" />
                    <LabelText>Google Pay</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some(x => ['payex.googlepay'].includes(x.type))}
                        isPending={appliedForGooglepay}
                        isFailed={
                            (appliedForGooglepay && approvalFailed) || failedConnections.some(x => x === 'googlepay')
                        }
                        isDeclined={
                            (appliedForGooglepay && approvalDeclined) || declinedConnections.some(x => x === 'googlepay')
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="clicktopay" label="Click to Pay" />
                    <LabelText>Visa Click to Pay</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some(x => ['payex.clicktopay'].includes(x.type))}
                        isPending={appliedForClickToPay}
                        isFailed={
                            (appliedForClickToPay && approvalFailed) || failedConnections.some(x => x === 'clicktopay')
                        }
                        isDeclined={
                            (appliedForClickToPay && approvalDeclined) || declinedConnections.some(x => x === 'clicktopay')
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="mobilepay" label="MobilePay" />
                    <LabelText>MobilePay</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some(x => ['payex.mobilepay', 'bambora.mobilepay'].includes(x.type))}
                        isPending={appliedForMobilepay}
                        isFailed={
                            (appliedForMobilepay && approvalFailed) || failedConnections.some(x => x === 'mobilepay')
                        }
                        isDeclined={
                            (appliedForMobilepay && approvalDeclined) || declinedConnections.some(x => x === 'mobilepay')
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="swish" label="Swish" />
                    <LabelText>Swish</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some(x => ['swish.swish', 'payex.swish'].includes(x.type))}
                        isPending={appliedForSwish || pendingConnections.some(x => x === 'swish')}
                        isFailed={
                            (appliedForSwish && approvalFailed) || failedConnections.some(x => x === 'swish')
                        }
                        isDeclined={
                            (appliedForSwish && approvalDeclined) || declinedConnections.some(x => x === 'swish')
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="billie" label="Billie" />
                    <LabelText>Billie</LabelText>
                </Label>

                <StatusWrapper>
                    <MethodStatus
                        isConfigured={configuredPaymentOptions.some(x => x.type === 'klarna.billie')}
                        isPending={appliedForBillie || pendingConnections.some(x => x === 'klarna')}
                        isFailed={
                            (appliedForBillie && approvalFailed) || failedConnections.some(x => x === 'klarna')
                        }
                        isDeclined={
                            (appliedForBillie && approvalDeclined) || declinedConnections.some(x => x === 'klarna')
                        }
                    />
                </StatusWrapper>
            </PaymentType>
            {hasInstabank && (
                <PaymentType>
                    <Label>
                        <PaymentLogoMark logo="instabank" label="Instabank" />
                        <LabelText>Instabank</LabelText>
                    </Label>
                    <StatusWrapper>
                        <MethodStatus
                            isConfigured={configuredPaymentOptions.some(x => x.type.startsWith('instabank'))}
                            isPending={appliedForInstabank || pendingConnections.some(x => x === 'instabank')}
                            isFailed={
                                (appliedForInstabank && approvalFailed) || failedConnections.some(x => x === 'instabank')
                            }
                            isDeclined={
                                (appliedForInstabank && approvalDeclined) || declinedConnections.some(x => x === 'instabank')
                            }
                        />
                    </StatusWrapper>
                </PaymentType>
            )}
        </>
    );
};

const PaymentMethodConfiguration = ({ approvals, checkoutConfiguration, account }: PaymentMethodConfigurationProps) => {
    const { t } = useTranslation();
    // Legacy connections statuses
    const configuredPaymentOptions = checkoutConfiguration?.configuration?.payment_options || [];

    const pendingConnections = resolveConnectionsStatuses(account, 'pending');
    const failedConnections = resolveConnectionsStatuses(account, 'failed');
    const declinedConnections = resolveConnectionsStatuses(account, 'declined');

    const hasNonActiveCase = checkHasCaseButNotActive(approvals);

    const lastApproval = approvals[approvals.length -1];
    const appliedForPaymentMethods = lastApproval?.payment_methods || [];
    const approvalDeclined = lastApproval?.case_status === 'DECLINED';
    const approvalFailed = lastApproval?.case_status === 'ERROR';

    if (hasNonActiveCase) {
        return null;
    }

    return (
        <Card noPadding overflowHidden>
            <Header>
                <HeaderTitle>
                    {t('settings.approvalsPayments.tableHeadingPaymentMethod')}
                </HeaderTitle>
                <HeaderTitle className="status">
                    {t('settings.approvalsPayments.tableHeadingStatus')}
                </HeaderTitle>
            </Header>
            <PaymentTypeGroupDefaults {...{ account, pendingConnections, failedConnections, declinedConnections, hasNonActiveCase, configuredPaymentOptions, appliedForPaymentMethods, approvalDeclined, approvalFailed }} />
            <PaymentTypeGroupAdditional {...{ account, pendingConnections, failedConnections, declinedConnections, hasNonActiveCase, configuredPaymentOptions, appliedForPaymentMethods, approvalDeclined, approvalFailed }} />
        </Card>
    );
};

const Header = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: ${palette.neutral[900]};
    color: white;
`;

const HeaderTitle = styled.div`
    margin: 0;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;

    padding-inline: 16px;
    padding-block: 12px;
    flex: 1;

    &.status {
        min-width: ${STATUS_MIN_WIDTH}px;
        flex: 0;
    }
`;

const BaseStatus = styled.span<{ background: string; text?: string; borderColor?: string }>`
    font-weight: 500;
    line-height: normal;
    border-radius: 22px;
    padding: 5px 10px;
    display: inline-block;
    background: ${props => props.background};
    color: ${props => props.text || 'white'};
    position: relative;
    overflow: hidden;
    max-width: 100%;

    ${props => props.borderColor && `border: 1px solid ${props.borderColor};`}

    &> span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        line-height: 18px;
    }
`;

const EmptyStatus = styled.span`
    font-weight: 500;
    line-height: normal;
    padding: 5px 10px;
    border-radius: 22px;
    display: inline-block;
    background: transparent;
    border: 1px dashed ${palette.neutral[200]};
    position: relative;
    overflow: hidden;
    max-width: 100%;
    &> span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        line-height: 18px;
        color: ${palette.neutral[700]};
    }
`;

const PaymentType = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline: 16px;
    padding-block: 12px;
    border-bottom: 1px solid ${palette.neutral[200]};
    &:last-child {
        border-bottom: none;
    }
`;

const Label = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    flex: 1;
`;

const LabelText = styled.div`
    font-size: 18px;
    font-weight: 600;
`;

const StatusWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    min-width: ${STATUS_MIN_WIDTH}px;
`;



export default PaymentMethodConfiguration;
