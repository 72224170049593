import { Route } from 'react-router-dom';

import EditUserPage from './editUser/components/EditUserPage';
import NewUserForm from './newUser/components/NewUserForm';
import UserPage from './userDetails/components/UserPage';
import UsersList from './users/components/UserList';

const Routes = [
    <Route key="users" path="/:accountId/settings/users" exact component={UsersList} />,
    <Route key="new_user" path="/:accountId/settings/users/new" exact component={NewUserForm} />,
    <Route key="user_details" path="/:accountId/settings/users/:userId" exact component={UserPage} />,
    <Route key="edit_user" path="/:accountId/settings/users/:userId/edit" exact component={EditUserPage} />,
];

export default Routes;
