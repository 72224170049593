import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { LoadingOverlay } from '../../../components/Loading';
import useAuth, { loadSubAccountTokens } from '../hooks/useAuth';
import NoAccess from './NoAccess';

interface MatchParams {
    accountId: string;
    subAccountId: string;
}

interface WithSubAccountAccessTokenProps extends RouteComponentProps<MatchParams> {
    children: React.ReactNode;
}

export default function WithSubAccountAccessToken({ match, children }: WithSubAccountAccessTokenProps) {
    const accountId = match.params.accountId;
    const subAccountId = match.params.subAccountId;
    const { tokens, isLoadingSubAccountTokens } = useAuth(state => ({
        tokens: state.tokens,
        isLoadingSubAccountTokens: state.isLoadingSubAccountTokens,
    }));

    useEffect(() => {
        if (accountId && subAccountId) {
            loadSubAccountTokens(accountId, subAccountId);
        }
    }, [accountId, subAccountId]);

    if (isLoadingSubAccountTokens) {
        return <LoadingOverlay />;
    }

    // If we aren't able to get a token for this sub account
    const subToken = tokens[subAccountId];
    if (!subToken) {
        return (
            <>
                <NoAccess {...{ subAccountId }} />
            </>
        );
    }

    // With access tokens
    return <React.Fragment key={subAccountId}>{children}</React.Fragment>;
}
