import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { State as PayoutRuleState } from '../reducer';
import { BillingPayoutRule } from '../../../../types/billing';
import { PayoutRuleFormikValues } from './types';
import { BillingPayoutRuleDestination } from '../../../../types/orders/generated';
import { PayoutDestinationGroup } from '../../payoutRules/types';
import { fromMonetaryAmountString } from '../../../../helpers/formatters';
import PayoutRuleForm from './PayoutRuleForm';
import { AccountIdsProps, withAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import { EXAMPLE_VALUE_CURRENCY } from '../../constants';

interface MatchParams {
    accountId: string;
}

export interface NewPayoutRuleProps extends WithTranslation, RouteComponentProps<MatchParams>, AccountIdsProps {
    newPayoutRuleState: PayoutRuleState;
    createPayoutRule: (accountId: string, newPayoutRule: BillingPayoutRule) => void;
    fetchLocations: (accountId: string) => void;
    toggleSecretIsSafe: (value: boolean) => void;
    resetForm: () => void;
}

class NewPayoutRule extends Component<NewPayoutRuleProps> {
    componentDidMount() {
        this.props.accountIds.forEach(accountId => {
            this.props.fetchLocations(accountId);
        });
    }

    render() {
        const { i18n, createPayoutRule, accountIds } = this.props;
        const { isLoading, result, locations } = this.props.newPayoutRuleState;
        const accountId = this.props.match.params.accountId;
        const lng = i18n.language;

        const onSubmit = (values: PayoutRuleFormikValues) => {
            const mapDestinations = (destinations: PayoutDestinationGroup): PayoutDestinationGroup => {
                return destinations.map((dest) => {
                    // destination and destinations can't be defined at the same time
                    return {
                        ...dest,
                        value:
                            dest.type === 'flat_amount'
                                ? fromMonetaryAmountString(lng, dest.value as string, EXAMPLE_VALUE_CURRENCY).toString()
                                : dest.value,
                        destination: dest.destination ? dest.destination : undefined,
                        destinations:
                            dest.destinations && dest.destinations.length > 0
                                ? mapDestinations(dest.destinations as PayoutDestinationGroup)
                                : undefined,
                    };
                });
            };

            const newPayoutRule: BillingPayoutRule = {
                rule_id: values.rule_id,
                rule_type: values.rule_type,
                destinations: mapDestinations(values.destinations) as BillingPayoutRuleDestination[],
            };
            createPayoutRule(values.accountId, newPayoutRule);
        };

        if (result) {
            return <Redirect to={`/${result.account_id}/settings/payout-rules/${result.id}`} />;
        }
        return (
            <PayoutRuleForm
                initialValues={
                    {
                        rule_id: '',
                        rule_type: '',
                        destinations: [],
                        accountId,
                    } as PayoutRuleFormikValues
                }
                accountIds={accountIds}
                locations={locations}
                onSubmit={onSubmit}
                resetForm={() => this.props.resetForm()}
                isLoading={isLoading}
            />
        );
    }
}

export default withAccountIds(withTranslation()(NewPayoutRule));
