import { Route } from 'react-router-dom';

import { HookDetails } from './hookDetails';
import Hooks from './hooks/components/HooksList';
import { NewHook } from './newHook';

const Routes = [
    <Route key="hooks" path="/:accountId/settings/hooks" exact component={Hooks} />,
    <Route key="new_hook" path="/:accountId/settings/hooks/new" exact component={NewHook} />,
    <Route key="hook_details" path="/:accountId/settings/hooks/:hookAccountId/:hookId" exact component={HookDetails} />,
];

export default Routes;
