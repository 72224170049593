import createExternalStore from '../../../../common/utils/createExternalStore';
import { CORE_API_HOSTNAME } from '../../../../env';
import fulfill from '../../../../fulfill';
import type { Client, Grant, HasSecretClientResponse } from '../../../../types/management-auth';
import { sortElements } from '../../../helpers';

export interface State {
    isShowingDeleteModal: boolean;
    isDeleted: boolean;
    isShowingRotateSecretModal: boolean;
    rotated: HasSecretClientResponse | undefined;
    isSecretSafe: boolean;
    isShowingDeleteGrantModal: boolean;
    deleteGrant: Grant | undefined;
}

export const initialState: State = {
    isShowingDeleteModal: false,
    isDeleted: false,
    isShowingRotateSecretModal: false,
    rotated: undefined,
    isSecretSafe: false,
    isShowingDeleteGrantModal: false,
    deleteGrant: undefined,
};

export const clientDetailsStore = createExternalStore(initialState, {
    resetState: () => initialState,
    openDeleteClientModal: (state) => {
        return {
            ...state,
            isShowingDeleteModal: true,
        };
    },
    dismissDeleteClientModal: (state) => {
        return {
            ...state,
            isShowingDeleteModal: false,
        };
    },
    openRotateSecretModal: (state) => {
        return {
            ...state,
            isShowingRotateSecretModal: true,
        };
    },
    dismissRotateSecretModal: (state) => {
        return {
            ...state,
            isShowingRotateSecretModal: false,
        };
    },
    openDeleteGrantModal: (state, grant: Grant) => {
        return {
            ...state,
            isShowingDeleteGrantModal: true,
            deleteGrant: grant,
        };
    },
    dismissDeleteGrantModal: (state) => {
        return {
            ...state,
            isShowingDeleteGrantModal: false,
            deleteGrant: undefined,
        };
    },
    toggleSecretIsSafe: (state, value: boolean) => {
        return {
            ...state,
            isSecretSafe: value,
        };
    },
    onDeleteClientResponse: (state) => {
        return {
            ...state,
            isDeleted: true,
        };
    },
    onRotateSecretResponse: (state, rotated: HasSecretClientResponse) => {
        return {
            ...state,
            rotated,
        };
    },
    onDeleteGrantResponse: (state) => {
        return {
            ...state,
            isShowingDeleteGrantModal: false,
            deleteGrant: undefined,
        };
    },
}, 'clientDetails');

export const actions = {
    getClient: async (payload: { accountId: string; clientId: string }) => {
        clientDetailsStore.dispatch('resetState');
        const result: Client = await fulfill.get({
            accountId: payload.accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/auth/clients/${payload.clientId}`,
            handlers: {
                200: (client: Client) => {
                    return client;
                },
            },
        });


        return result;
    },
    getGrants: async (payload: { accountId: string; clientId: string | undefined }) => {
        const result: Grant[] = await fulfill.get({
            accountId: payload.accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/auth/client-grants?client_id=${payload.clientId}`,
            handlers: {
                200: (grants: Grant[]) => {
                    return sortElements(grants);
                },
            },
        });

        return result;
    },
    deleteClient: async (accountId: string, clientId: string) => {
        const result: Client = await fulfill.delete({
            accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/auth/clients/${clientId}`,
            handlers: {
                200: (client: Client) => {
                    clientDetailsStore.dispatch('onDeleteClientResponse');
                    return client;
                },
            },
        });

        return result;
    },
    rotateSecret: async (accountId: string, clientId: string) => {
        const result: HasSecretClientResponse = await fulfill.post({
            accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/auth/clients/${clientId}/rotate-secret`,
            handlers: {
                200: (rotated: HasSecretClientResponse) => {
                    clientDetailsStore.dispatch('onRotateSecretResponse', rotated);
                    return rotated;
                },
            },
        });

        return result;
    },
    deleteGrant: async (accountId: string, grantId: string) => {
        const result: Grant = await fulfill.delete({
            accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/auth/client-grants/${grantId}`,
            handlers: {
                200: (grant: Grant) => {
                    clientDetailsStore.dispatch('onDeleteGrantResponse');
                    return grant;
                },
            },
        });

        return result;
    },
    openDeleteClientModal: () => clientDetailsStore.dispatch('openDeleteClientModal'),
    dismissDeleteClientModal: () => clientDetailsStore.dispatch('dismissDeleteClientModal'),
    openRotateSecretModal: () => clientDetailsStore.dispatch('openRotateSecretModal'),
    dismissRotateSecretModal: () => clientDetailsStore.dispatch('dismissRotateSecretModal'),
    openDeleteGrantModal: () => clientDetailsStore.dispatch('openDeleteGrantModal'),
    dismissDeleteGrantModal: () => clientDetailsStore.dispatch('dismissDeleteGrantModal'),
    toggleSecretIsSafe: (value: boolean) => clientDetailsStore.dispatch('toggleSecretIsSafe', value),
    resetState: () => clientDetailsStore.dispatch('resetState'),
};
