import _pick from 'lodash/pick';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { useAccountId } from '../../../auth/useAccountId';
import { CHECKOUT_API_HOSTNAME, CORE_API_HOSTNAME } from '../../../env';
import fulfill from '../../../fulfill';
import type { BillingSubscription } from '../../../types/billing';
import type { CheckoutConfig } from '../../../types/checkout';
import type { Account } from '../../../types/management-auth';
import type { ApprovalsPaymentResponse } from '../../../types/management-auth/generated';

const getFileName = (path: string) => {
    const [lastTrail] = path.split('/').reverse();
    const [withoutParams] = lastTrail.split('?');
    return withoutParams;
};

async function fetchDownloadSignupFile(
    _: `download-signup-file-${string}`,
    options: {
        arg: {
            subAccountId: string;
        };
    }
) {
    const { subAccountId } = options.arg;
    return await fulfill.post({
        accountId: subAccountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${subAccountId}/management/settings/connections/common/signup`,
        handlers: {
            200: (payload: any) => {
                const filename = getFileName(payload.rel);
                const a = document.createElement('a');
                a.setAttribute('download', filename);
                a.setAttribute('target', '_blank');
                a.style.display = 'none';
                a.href = payload.rel;
                const addedElement = document.body.appendChild(a);
                a.click();
                document.body.removeChild(addedElement);
            },
        },
    });
}

async function fetchEditMerchant(
    _: `edit-merchant-${string}`,
    options: {
        arg: {
            partnerId: string;
            subAccountId: string;
            account: Account;
        };
    }
) {
    const { partnerId, subAccountId, account } = options.arg;
    const prefix = `${account.livemode ? 'P' : 'T'}`;
    const accountId = `${prefix}${account.account_id || ''}`;
    const accountUpdate = _pick(
        account,
        'applicant',
        'company',
        'billing',
        'account_manager',
        'sale_channel',
        'price_package'
    );
    const result: Account = await fulfill.put({
        accountId: partnerId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${partnerId}/management/accounts/${accountId}`,
        json: accountUpdate,
        handlers: {
            200: (account: Account) => account,
        },
    });
    return result;
}

async function fetchSubAccountApprovalsPayments(subAccountId: string): Promise<ApprovalsPaymentResponse[]> {
    return await fulfill.get({
        accountId: subAccountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${subAccountId}/management/settings/approvals/payments`,
        handlers: {
            200: ({ payments }: { payments: ApprovalsPaymentResponse[] }) => payments,
        },
    });
}

async function fetchSubAccountCheckoutConfig(subAccountId: string): Promise<CheckoutConfig | undefined> {
    return await fulfill.get({
        accountId: subAccountId,
        url: `${CHECKOUT_API_HOSTNAME}/v1/admin/checkout`,
        handlers: {
            200: (checkout: CheckoutConfig) => checkout,
            404: () => undefined,
        },
    });
}

async function fetchSubAccountSubscriptions(subAccountId: string): Promise<BillingSubscription[]> {
    return await fulfill.get({
        accountId: subAccountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${subAccountId}/billing/subscriptions`,
        handlers: {
            200: (subscriptions: BillingSubscription[]) => subscriptions,
        },
    });
}

async function fetchSubAccount(payload: [string, string, string]) {
    const [_, accountId, subAccountId] = payload;
    const subAccountPromise: Promise<Account> = fulfill.get({
        accountId: accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/accounts/${subAccountId}`,
        handlers: {
            200: (account: Account) => account,
        },
    });
    const subAccountCheckoutConfigPromise = fetchSubAccountCheckoutConfig(subAccountId);
    const subAccountSubscriptionsPromise = fetchSubAccountSubscriptions(subAccountId);
    const subAccountApprovalsPaymentsPromise = fetchSubAccountApprovalsPayments(subAccountId);

    const [subAccount, checkout, subscriptions, approvals] = await Promise.all([
        subAccountPromise,
        subAccountCheckoutConfigPromise,
        subAccountSubscriptionsPromise,
        subAccountApprovalsPaymentsPromise,
    ]);

    return {
        merchant: subAccount,
        checkout,
        subscriptions,
        approvals,
    };
}

export default function useMerchantDetails(subAccountId: string) {
    const accountId = useAccountId();
    const { data: merchant, error: merchantError, isLoading: isLoadingMerchant, mutate: mutateMerchant } = useSWR(
        ['merchant', accountId, subAccountId],
        fetchSubAccount,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            revalidateOnMount: true,
            shouldRetryOnError: false,
        }
    );
    const { trigger: downloadSignupFile, isMutating: isDownloadingSignupFile } = useSWRMutation(
        `download-signup-file-${subAccountId}`,
        fetchDownloadSignupFile
    );
    const { trigger: editMerchant, isMutating: isEditingMerchant } = useSWRMutation(`edit-merchant-${subAccountId}`, fetchEditMerchant, {
        onSuccess: () => {
            mutateMerchant();
        },
    });

    return {
        ...merchant,
        isLoadingMerchant,
        merchantError,
        mutateMerchant,
        downloadSignupFile,
        isDownloadingSignupFile,
        editMerchant,
        isEditingMerchant,
    };
}
