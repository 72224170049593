import { useState } from 'react';
import useSWRMutation from 'swr/mutation';
import apiRequest from '../../../apiRequest';
import { CORE_API_HOSTNAME } from '../../../env';
import type { AccountConnectionEvent } from '../../../types/management-auth/generated';

async function fetchAddConnectionEvent(
    key: `add-connection-event-${string}`,
    options: {
        arg: {
            partnerId: string;
            subAccountId: string;
            connection: string;
            data: AccountConnectionEvent;
        };
    }
) {
    const { partnerId, subAccountId, connection, data } = options.arg;
    const url = `${CORE_API_HOSTNAME}/v1/accounts/${partnerId}/management/accounts/${subAccountId}/connections/${connection}/events`;
    await apiRequest('POST', partnerId, url, {}, data);
    return { connection };
};

export default function useConnectionEvents(subAccountId: string) {
    const [lastConnectionEventSuccess, setLastConnectionEventSuccess] = useState<string | undefined>(undefined);
    const [lastConnectionEventFailure, setLastConnectionEventFailure] = useState<string | undefined>(undefined);
    const { trigger: addConnectionEvent, isMutating: isLoading } = useSWRMutation(
        `add-connection-event-${subAccountId}`,
        fetchAddConnectionEvent,
        {
            onSuccess: (data) => {
                setLastConnectionEventSuccess(data.connection);
            },
            onError: (data) => {
                setLastConnectionEventFailure(data.connection);
            },
        }
    );

    return {
        addConnectionEvent: (partnerId: string, connection: string, data: AccountConnectionEvent) => {
            addConnectionEvent({ partnerId, subAccountId, connection, data });
        },
        isLoading,
        lastConnectionEventFailure,
        lastConnectionEventSuccess,
    };
}
