import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { MfaType, SelectMfaTypeOptions } from './types';

type AuthenticateResult = {
    success?: AmazonCognitoIdentity.CognitoUserSession;
    mfaRequired?: AmazonCognitoIdentity.CognitoUser;
    totpRequired?: AmazonCognitoIdentity.CognitoUser;
    mfaType?: MfaType;
    selectMfaTypeOptions?: SelectMfaTypeOptions;
};

const cognitoUserPool = () =>
    new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL || '',
        ClientId: process.env.REACT_APP_COGNITO_CLIENT || '',
    });

export const authenticate = async (username: string, password: string): Promise<AuthenticateResult> => {
    const authenticationData = {
        Username: username,
        Password: password,
    };
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
    const userPool = cognitoUserPool();
    const userData = {
        Username: username,
        Pool: userPool,
    };

    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    return new Promise<AuthenticateResult>((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: (success: AmazonCognitoIdentity.CognitoUserSession) => {
                resolve({ success });
            },
            mfaRequired: () => {
                resolve({ mfaRequired: cognitoUser, mfaType: 'SMS_MFA' });
            },
            totpRequired: () => {
                resolve({ mfaRequired: cognitoUser, mfaType: 'SOFTWARE_TOKEN_MFA' });
            },
            selectMFAType: (type, options) => {
                resolve({ mfaRequired: cognitoUser, selectMfaTypeOptions: options });
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
};

export const getMfaTotpSecret = (
    associateSecretCode: (secretCode: string) => void,
    onFailure: (err: any) => void
) => {
    const cognitoUser = cognitoUserPool().getCurrentUser();
    if (!cognitoUser) {
        console.log('No Cognito User, skip');
        return;
    }
    getSession(cognitoUser).then((_) => {
        cognitoUser.associateSoftwareToken({
            associateSecretCode: associateSecretCode,
            onFailure: onFailure,
        });
    });

};
export const verifyMfa = async (
    cognitoUser: AmazonCognitoIdentity.CognitoUser,
    mfaCode: string,
    rememberDevice: boolean,
    mfaType: MfaType
): Promise<AmazonCognitoIdentity.CognitoUserSession> => {
    return new Promise<AmazonCognitoIdentity.CognitoUserSession>((resolve, reject) => {
        cognitoUser.sendMFACode(mfaCode, {
            onSuccess: (result: AmazonCognitoIdentity.CognitoUserSession) => {
                // ignore error when setting device status as it is not critical
                // for the login to complete
                if (rememberDevice) {
                    cognitoUser.setDeviceStatusRemembered({
                        onSuccess: (_) => resolve(result),
                        onFailure: (error) => {
                            console.log('Error setDeviceStatusRemembered', error);
                            resolve(result);
                        },
                    });
                } else {
                    cognitoUser.setDeviceStatusNotRemembered({
                        onSuccess: (_) => resolve(result),
                        onFailure: (error) => {
                            console.log('Error setDeviceStatusNotRemembered', error);
                            resolve(result);
                        },
                    });
                }
            },
            onFailure: (err) => {
                reject(err);
            },
        }, mfaType);
    });
};



export const selectMfaType = async (
    cognitoUser: AmazonCognitoIdentity.CognitoUser,
    answer: MfaType
): Promise<AuthenticateResult> => {
    return new Promise<AuthenticateResult>((resolve, reject) => {
        cognitoUser.sendMFASelectionAnswer(answer, {
            onSuccess: (success: AmazonCognitoIdentity.CognitoUserSession) => {
                resolve({ success });
            },
            mfaRequired: () => {
                resolve({ mfaRequired: cognitoUser, mfaType: 'SMS_MFA' });
            },
            totpRequired: () => {
                resolve({ mfaRequired: cognitoUser, mfaType: 'SOFTWARE_TOKEN_MFA' });
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
};

export const forgottenPassword = async (username: string): Promise<void> => {
    const userPool = cognitoUserPool();
    const userData = {
        Username: username,
        Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    await new Promise<void>((resolve, reject) => {
        cognitoUser.forgotPassword({
            onSuccess: (data: any) => {
                // successfully initiated reset password request, nothing more to do here
                resolve();
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
};

export const confirmForgottenPassword = async (
    username: string,
    verificationCode: string,
    newPassword: string
): Promise<AuthenticateResult> => {
    const userPool = cognitoUserPool();
    const userData = {
        Username: username,
        Pool: userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    return new Promise<AuthenticateResult>((resolve, reject) => {
        cognitoUser.confirmPassword(verificationCode, newPassword, {
            onSuccess: () => {
                // successfully initiated reset password request
                resolve(authenticate(username, newPassword));
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
};

const getSession = async (
    cognitoUser: AmazonCognitoIdentity.CognitoUser
): Promise<AmazonCognitoIdentity.CognitoUserSession> => {
    return new Promise((resolve, reject) => {
        cognitoUser.getSession((error: any, session: AmazonCognitoIdentity.CognitoUserSession) => {
            if (error || !session?.isValid()) {
                return reject(error || new Error('Invalid Session'));
            }
            return resolve(session);
        });
    });
};

const forgetDevice = async (cognitoUser: AmazonCognitoIdentity.CognitoUser): Promise<string> => {
    return new Promise((resolve, reject) => {
        cognitoUser.forgetDevice({
            onSuccess: (success) => resolve(success),
            onFailure: (error) => {
                console.log('Error: forgetDevice', error);
                reject(error);
            },
        });
    });
};

export const forgetDeviceSignOut = async (): Promise<void> => {
    const cognitoUser = cognitoUserPool().getCurrentUser();
    if (!cognitoUser) {
        console.log('No Cognito User, skip');
        return;
    }

    try {
        cognitoUser.getCachedDeviceKeyAndPassword();
        await getSession(cognitoUser);
        await forgetDevice(cognitoUser);
    } finally {
        cognitoUser.signOut();
    }
};

export const redirectToApp = (cognitoUserSession: AmazonCognitoIdentity.CognitoUserSession): void => {
    const accessToken = cognitoUserSession.getAccessToken();
    const expiresIn = accessToken.getExpiration() * 1000 - new Date().getTime();
    const hash = window.location.hash;
    const prefix = '#';
    let params: URLSearchParams;
    if (hash.startsWith(prefix)) {
        const paramsString = hash.substring(prefix.length);
        params = new URLSearchParams(paramsString);
        params.delete('access_token');
        params.delete('expires_in');
    } else {
        params = new URLSearchParams();
    }
    params.set('access_token', accessToken.getJwtToken());
    params.set('expires_in', expiresIn.toString());
    window.location.hash = '#' + params.toString();
    window.location.reload();
};
