import styled from 'styled-components/macro';
import { defaultRadius, palette } from '../../styles/constants';

export const PayoutBuffer = styled.div`
    display: flex;
    border: 1px solid ${palette.neutral[300]};
    border-radius: ${defaultRadius};
    padding: 2px 6px;
    position: relative;
`;
