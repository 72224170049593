import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import AllowDinteroRoot from '../../../auth/accessToken/components/AllowDinteroRoot';
import { inspectAccount } from '../../../auth/accessToken/helpers';
import { Button } from '../../../components/Buttons';
import Card from '../../../components/Card';
import { H3 } from '../../../components/Typography';
import { checkAccountReadyForProduction, hasAtLeastOneProductAndServiceDetailsField } from '../../../settings/account/helpers';
import { distances, globalColumnMaxWidth, globalMobileBreakpoint } from '../../../styles/constants';
import { BillingSubscription } from '../../../types/billing';
import { CheckoutConfig } from '../../../types/checkout';
import { ApprovalsPaymentResponse } from '../../../types/management-auth/generated';
import { PartnerAccount } from '../types';
import AccountConfiguration from './AccountConfiguration';
import Applicant from './Applicant';
import ApprovalsPayments from './ApprovalsPayments';
import BillingInformation from './BillingInformation';
import CheckoutConfiguration from './CheckoutConfiguration';
import CompanyInformation from './CompanyInformation';
import Credentials from './Credentials';
import JwksInformation from './JwksInformation';
import { MarkAsDeclinedBag } from './MarkAsDeclined';
import MerchantSubscriptionPrices from './MerchantSubscriptionPrices';
import PaymentConnectionsSignup from './PaymentConnectionsSignup';
import PaymentConnectionsStatus from './PaymentConnectionsStatus';
import ProductAndServiceDetails from './ProductAndServiceDetails';
import Statistics from './Statistics';
import TermsInformation from './TermsInformation';

export interface MerchantDetailsProps {
    checkout?: CheckoutConfig;
    account: PartnerAccount;
    subscriptions: BillingSubscription[];
    partnerId: string;
    downloadSignupFile: (payload: { accountId: string; subAccountId: string }) => void;
    markAsDeclinedBag: MarkAsDeclinedBag;
    approvals: ApprovalsPaymentResponse[];
    editMerchant: (partnerId: string, account: PartnerAccount) => void;
}

const MerchantDetails = (
    {
        checkout,
        account,
        subscriptions,
        partnerId,
        downloadSignupFile,
        markAsDeclinedBag,
        approvals,
        editMerchant,
    }: MerchantDetailsProps
) => {
    const { t } = useTranslation();
    const hasLiveInfo = !checkAccountReadyForProduction(account);
    const withAccountIdsDownloadSignupFile = () => {
        downloadSignupFile({
            accountId: partnerId,
            subAccountId: `${account.livemode ? 'P' : 'T'}${account.account_id}`,
        });
    };
    return (
        <Wrapper>
            <Card marginBottom={distances.normal}>
                <FlexHorizontal>
                    <H3>
                        {t('partner.merchant.merchant_details_id')}: {account.livemode ? 'P' : 'T'}
                        {account.account_id}
                    </H3>
                    <Button
                        className="tiny-768"
                        type="button"
                        onClick={() => {
                            inspectAccount(partnerId, `${account.livemode ? 'P' : 'T'}${account.account_id}`);
                        }}
                    >
                        {t('partner.merchants.inspect')}
                    </Button>
                </FlexHorizontal>
            </Card>
            <ColumnsWrapper>
                <Column>
                    <CompanyInformation account={account} />
                    {hasAtLeastOneProductAndServiceDetailsField(account) && <ProductAndServiceDetails account={account} />}
                    {!hasLiveInfo && <BillingInformation account={account} />}
                    {checkout && <Credentials checkout={checkout} />}
                </Column>
                <Column>
                    <Applicant account={account} />
                    <TermsInformation account={account} />
                    {account.jwks && <JwksInformation account={account} />}
                    <AccountConfiguration partnerId={partnerId} account={account} editMerchant={editMerchant} />
                    {approvals.length > 0 && <ApprovalsPayments approvals={approvals} />}
                    {checkout && <CheckoutConfiguration checkout={checkout} />}
                    {!hasLiveInfo && account.connections && <PaymentConnectionsStatus account={account} markAsDeclinedBag={markAsDeclinedBag} />}
                    {!hasLiveInfo && account.connections?.common?.signup && (
                        <PaymentConnectionsSignup
                            account={account}
                            downloadSignupFile={withAccountIdsDownloadSignupFile}
                            partnerId={partnerId}
                        />
                    )}
                    <Statistics account={account} partnerId={partnerId} />
                </Column>
            </ColumnsWrapper>
            <AllowDinteroRoot>
                <MerchantSubscriptionPrices subscriptions={subscriptions} />
            </AllowDinteroRoot>
        </Wrapper>
    );
};

export default MerchantDetails;

const Wrapper = styled.div`
    position: relative;
`;
const FlexHorizontal = styled.div`
    display: flex;
    justify-content: space-between;
`;

const ColumnsWrapper = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    min-width: 0;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const Column = styled.div`
    flex-grow: 1;
    width: 100%;
    display: flex;
    min-width: 0;
    flex-direction: column;
    min-height: 100px;

    @media (min-width: ${globalMobileBreakpoint + 1}px) {
        &:first-child {
            margin-right: ${distances.small};
        }
        &:last-child {
            margin-left: ${distances.small};
        }
    }
`;
