import ArrowTopRightOnSquareIcon from '@heroicons/react/20/solid/ArrowTopRightOnSquareIcon';
import { useFormik } from 'formik';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useAccountIds } from '../../../auth/accessToken/components/withAccountIds';
import { isPartner } from '../../../auth/helpers';
import { useCurrentUser } from '../../../common/hooks/useCurrentUser';
import { Button } from '../../../components/Buttons';
import Card from '../../../components/Card';
import { Checkbox } from '../../../components/Forms';
import SegmentedControl from '../../../components/Forms/SegmentedControl';
import IconWrapper from '../../../components/Icons/IconWrapper';
import { LoadingOverlay } from '../../../components/Loading';
import { LabelTr, Table, Tbody, Td, Th, Thead } from '../../../components/Table/Fake';
import { H2, P } from '../../../components/Typography';
import { getCountryCurrency } from '../../../helpers/countryCurrency';
import { Account } from '../../../types/management-auth';
import { usePaymentApprovals } from './hooks';
import NotAvailable from './NotAvailable';
import NotAvailablePartner from './NotAvailableParner';
import PaymentLogoMark from './PaymentLogoMark';
import { PaymentMethod } from './types';
import { getPaymentMethods } from './utils';

const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
};

type FormValues = {
    country: string;
    paymentMethods: PaymentMethod[];
    product: 'checkout' | 'split_payment';
};

const products = [
    { label: 'Checkout', value: 'checkout', countries: ['NO', 'SE', 'DK']},
    { label: 'Split Payment', value: 'split_payment', countries: ['NO', 'SE', 'DK']},
];

const normalizeOrgNumber = (orgNumber: string | undefined) => {
    if (orgNumber) {
        return orgNumber.replace(/\D/g, '');
    }
};

const ApprovalsPaymentsForm = ({ account }: {account: Account}) => {
    const { prodAccountId, urlAccountId } = useAccountIds();
    const { t } = useTranslation();
    const query = useQuery();
    const { postApproval, isPosting, posted } = usePaymentApprovals();
    const initialProduct = query.get('product') || 'checkout';
    const country = query.get('country') || account?.company?.address?.country || '';
    const currency = query.get('currency') || getCountryCurrency(country) || '';
    const { currentUser } = useCurrentUser();

    const formik = useFormik<FormValues>({
        initialValues: {
            product: initialProduct as 'checkout' | 'split_payment',
            country,
            paymentMethods: [],
        },
        onSubmit: async (values) => {
            if (!prodAccountId || values.paymentMethods.length === 0 || !values.country || !values.product) {
                return;
            }
            const result = await postApproval(
                prodAccountId,
                {
                    country_code: values.country,
                    organization_number: normalizeOrgNumber(account?.company.organization_number) || '',
                    bank_accounts: [{
                        payout_currency: currency,
                        bank_account_currency: currency,
                    }],
                    products: [{ product: values.product }],
                    payment_methods: values.paymentMethods.map(p => ({
                        payment_method: p,
                    })),
                    form_submitter: currentUser ? {
                        email: currentUser.email,
                        name: currentUser.name,
                    } : undefined,
                }
            );
            const url = result.links?.find(x => x.rel === 'declaration_url')?.href;
            window.open(url, '_blank', 'noopener noreferrer');
        },
    });
    const showAll = query.get('showAll') === 'true';
    const options = getPaymentMethods(country, showAll);
    const isAllowed = (products.find(p => p.value === formik.values.product)?.countries || []).includes(country);
    const hasProducts = options.length > 0;
    if (!isPosting && Boolean(posted)) {
        return (
            <Redirect to={`/${urlAccountId}/settings/payment-connections`} />
        );
    }
    const partner = isPartner(urlAccountId);
    if (partner && !showAll) {
        return (

            <Card>
                <H2>{t('settings.approvalsPayments.heading')}</H2>
                <NotAvailablePartner productLabel={'Checkout'} />
            </Card>
        );
    }
    return (
        <Card>
            <H2>{t('settings.approvalsPayments.heading')}</H2>
            <form onSubmit={formik.handleSubmit}>
                <Group>
                    <P>{t('settings.approvalsPayments.selectProduct')}</P>
                    <SegmentedControl
                        options={products}
                        value={formik.values.product}
                        onChange={value => formik.setFieldValue('product', value as 'checkout' | 'split_payment')}
                    />
                </Group>
                {isAllowed && hasProducts ? (
                    <>
                        <ProductTitle>{t('settings.approvalsPayments.productTypeTitle', { context: formik.values.product })}</ProductTitle>
                        <Group>
                            <P>
                                <Trans i18nKey="settings.approvalsPayments.selectPaymentsMethods"><strong /></Trans><br/>
                                {t('settings.approvalsPayments.selectPaymentsMethodsTime')}
                            </P>
                            <Table>
                                <Thead>
                                    <LabelTr>
                                        <ThCheck><Checkbox
                                            name={'all'}
                                            label={null}
                                            value={'all'}
                                            onChange={
                                                (e) => {
                                                    if (e.target.checked) {
                                                        formik.setFieldValue('paymentMethods', options.map(option => option.value as PaymentMethod));
                                                    } else {
                                                        formik.setFieldValue('paymentMethods', []);
                                                    }
                                                }
                                            }
                                            checked={options.length === formik.values.paymentMethods.length}
                                        /></ThCheck>
                                        <PaymentMethodLabelTh>{t('settings.approvalsPayments.tableHeadingPaymentMethod')}</PaymentMethodLabelTh>
                                        <Th>{t('settings.approvalsPayments.tableHeadingPaymentType')}</Th>
                                    </LabelTr>
                                </Thead>
                                <Tbody>
                                    {options.map(option => (<LabelTr key={option.value}>
                                        <TdCheck>
                                            <Checkbox
                                                name={option.value}
                                                label={null}
                                                value={option.value}
                                                onChange={
                                                    (e) => {
                                                        if (e.target.checked) {
                                                            formik.setFieldValue('paymentMethods', [...formik.values.paymentMethods, option.value as PaymentMethod]);
                                                        } else {
                                                            formik.setFieldValue('paymentMethods', formik.values.paymentMethods.filter(pm => pm !== option.value));
                                                        }
                                                    }
                                                }
                                                checked={formik.values.paymentMethods.includes(option.value as PaymentMethod)}
                                            />
                                        </TdCheck>
                                        <PaymentMethodLabelTd>
                                            <PaymentLogoMark logo={option.value} label={option.label} />
                                            {option.label}
                                        </PaymentMethodLabelTd>
                                        <Td>
                                            {t('settings.approvalsPayments.paymentMethodDescription', { context: option.value })}
                                        </Td>
                                    </LabelTr>))}
                                </Tbody>
                            </Table>
                        </Group>
                        <Group>
                            <Button
                                type="submit"
                                disabled={isPosting}
                            >
                                <ButtonInner>
                                    {t('settings.approvalsPayments.applyForPaymentsButton')}
                                    <IconWrapper>
                                        <ArrowTopRightOnSquareIcon />
                                    </IconWrapper>
                                </ButtonInner>
                            </Button>

                        </Group></>
                ) : (
                    <NotAvailable productLabel={products.find(p => p.value === formik.values.product)?.label || 'Checkout'} />
                )}
            </form>
            {isPosting && <LoadingOverlay />}
        </Card>
    );
};

const ProductTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
`;


const Group = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 24px;
`;

const ButtonInner = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const PaymentMethodLabelTh = styled(Th)`
    width: 250px;
`;

const PaymentMethodLabelTd = styled(Td)`
    width: 250px;
`;

const ThCheck = styled(Th)`
    padding-left: 8px;
    width: 40px;
`;

const TdCheck = styled(Td)`
    padding-left: 8px;
    width: 40px;
`;



export default ApprovalsPaymentsForm;
