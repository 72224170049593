import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Portal from '../../common/components/Portal';
import { palette } from '../../styles/constants';
import { useMenuContext } from './Provider';

type MenuButtonProps = {
    children: React.ReactNode;
    target: (onClick: () => void, active: boolean) => React.ReactNode;
    name: string;
    position?: 'bottom-center' | 'bottom-right' | 'bottom-left';
    overflow?: 'visible' | 'auto';
    withinPortal?: boolean;
}

export default function MenuButton(props: MenuButtonProps) {
    const { children, target, name, position, overflow = 'auto', withinPortal } = props;
    const { menus, set } = useMenuContext(name);
    const ref = useRef<HTMLDivElement>(null);
    const [rect, setRect] = useState<DOMRectReadOnly | undefined>(undefined);
    const menu = useMemo(() => menus.find(([id]) => id === name), [menus, name]);
    const open = menu?.[1];

    const toggle = () => {
        set(name, !menu?.[1]);
    };

    useEffect(() => {
        if (open) {
            setRect(ref.current?.getBoundingClientRect());
        }
    }, [open]);

    useEffect(() => {
        return () => {
            setRect(undefined);
            set(name, false);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (withinPortal) {
        return (<>
            <Wrapper ref={ref} data-other-menu-active={
                menus.some(([id, open]) => id !== name && open)
            }>
                {target(toggle, open || false)}
                <Portal>
                    {rect && <BodyPortalWrapper style={{
                        top: rect.bottom + window.scrollY,
                        left: rect.left,
                        width: rect.width,
                    }}>
                        <Body data-visible={!!open} data-position={position} data-overflow={overflow}>
                            {children}
                        </Body>
                    </BodyPortalWrapper>
                    }
                    {open && <Backdrop onClick={toggle} />}
                </Portal>
            </Wrapper>
        </>
        );
    }

    return (<>
        <Wrapper data-other-menu-active={
            menus.some(([id, open]) => id !== name && open)
        }>
            {target(toggle, open || false)}
            <Body data-visible={!!open} data-position={position} data-overflow={overflow}>
                {children}
            </Body>
        </Wrapper>
        {open && <Backdrop onClick={toggle} />}
    </>
    );
}

const Wrapper = styled.div`
    position: relative;
    z-index: 1000;
    &[data-other-menu-active="true"] {
        z-index: 0;
    }
`;

const BodyPortalWrapper = styled.div`
    position: absolute;
`;

const Body = styled.div`
    transition: all 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border-radius: 8px;
    box-shadow: 0px 0px 11px -3px rgba(16, 24, 40, 0.08);
    padding: 8px;
    z-index: 1000;
    width: 100%;
    max-width: 300px;
    min-width: max-content;

    max-height: 400px;
    scrollbar-width: thin;
    scrollbar-color: ${palette.neutral[200]} white;

    &[data-overflow="auto"] {
        overflow: auto;
    }

    &[data-overflow="visible"] {
        overflow: visible;
    }

    &[data-visible="false"] {
        top: 50%;
        transform: translateX(-50%) scale(0.9);
        opacity: 0;
        pointer-events: none;
    }
    &[data-visible="true"] {
        opacity: 1;
        pointer-events: auto;
    }

    &[data-position="bottom-left"] {
        left: 0;
        transform: translateX(0);
    }

    &[data-position="bottom-center"] {
        left: 50%;
        transform: translateX(-50%);
    }

    &[data-position="bottom-right"] {
        left: auto;
        right: 0;
        transform: translateX(0);
    }
`;

const Backdrop = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
`;
