import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { useActions } from '../../../../Actions';
import { getUserId } from '../../../../auth/accessToken/selectors';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { Checkbox, Dropdown, Input, ValidationState } from '../../../../components/Forms';
import { P } from '../../../../components/Typography';
import View from '../../../../components/View';
import { formatDate, formatOrgNumber, formatTime } from '../../../../helpers/formatters';
import { getValueAt } from '../../../../helpers/getValueAt';
import { validate, validateLength, validatePositiveNumber, validateRequired } from '../../../../helpers/validation';
import { validateBamboraMerchantNumber } from '../../../../helpers/validation/bamboraMerchantNumber';
import { validateMD5 } from '../../../../helpers/validation/md5';
import { distances, palette } from '../../../../styles/constants';
import type { Account } from '../../../../types/management-auth';
import { ApprovalsPaymentResponse, type BamboraConnectionCallback, type BamboraDirectConnectionSignup, type BamboraPayFacConnectionSignup } from '../../../../types/management-auth/generated';
import { ToggleDetails } from '../../connections/components/styledListItemComponents';
import BamboraAgreementTypeDropdown from './BamboraAgreementTypeDropdown';
import TranslatedValidatedInput from './ValidatedInput';

const fieldValidationMessage = (
    path: string,
    errors: FormikErrors<FormValues>,
    touched: FormikTouched<FormValues>,
    initialValues: FormValues
) => {
    const initialValue = getValueAt(path, initialValues);
    const error = getValueAt(path, errors);
    const was_touched = getValueAt(path, touched);
    if (error && (was_touched || initialValue)) {
        return {
            state: ValidationState.Invalid,
            message: error,
        };
    }
    return undefined;
};

const toOption = (approval: ApprovalsPaymentResponse | undefined, language: string) => {
    const country = approval?.bank_accounts?.[0]?.bank_account_country_code || '';
    const currency = approval?.bank_accounts?.[0]?.bank_account_currency;
    const org = approval?.organization_number || '';
    const date = formatDate(approval?.created_at || '', language);
    const time = formatTime(approval?.created_at || '', language);
    const mcc = approval?.mcc || '';
    return {
        label: [currency, date, time, country, formatOrgNumber(country, org).trim(), mcc].filter(x => x).join(', '),
        value: approval?.id || '',
    };
};

type AgreementTypes = 'direct' | 'payfac'

type FormValues = {
    agreement_type: AgreementTypes;
    case_id: string | undefined;
    merchant_number: string;
    md5_key: string;
    mcc: string;
    access_token: string;
    secret_token: string;
    token_scope: string;
    payment_options: {
        type: 'bambora.creditcard' | 'bambora.vipps';
        enabled: boolean;
        currencies: {
            currency: string;
            enabled: boolean;
        }[];
    }[];
};

type BamboraPayFacFormProps = {
    prodAccountId: string;
    activeApprovals: ApprovalsPaymentResponse[];
    account: Account;
}

const BamboraForm = ({ prodAccountId, activeApprovals, account }: BamboraPayFacFormProps) => {
    const { t, i18n } = useTranslation();
    const userId = useSelector(state => getUserId(state));
    const { closeModal, finishRegistration } = useActions('connections.bambora');
    const [overrideSettings, setOverrideSettings] = useState(false);


    const initialFormValues: FormValues = {
        agreement_type: 'payfac',
        case_id: activeApprovals[activeApprovals.length - 1]?.id,
        merchant_number: '',
        md5_key: '',
        mcc: '',
        access_token: '',
        secret_token: '',
        token_scope: '',
        payment_options: [
            {
                type: 'bambora.creditcard',
                enabled: false,
                currencies: [
                    {
                        currency: 'NOK',
                        enabled: false,
                    },
                    {
                        currency: 'SEK',
                        enabled: false,
                    },
                    {
                        currency: 'DKK',
                        enabled: false,
                    },
                    {
                        currency: 'EUR',
                        enabled: false,
                    },
                ],
            },
            {
                type: 'bambora.vipps',
                enabled: false,
                currencies: [
                    {
                        currency: 'NOK',
                        enabled: false,
                    },
                ],
            },
        ],
    };

    const formik = useFormik<FormValues>({
        initialValues: initialFormValues,
        validate: (values) => {
            let errors: { [key: string]: string } = {};
            const validators = [
                {
                    path: 'agreement_type',
                    validator: validateRequired(t('settings.payment_connections.bambora_modal.invalid.agreement_type.required')),
                },
                {
                    path: 'case_id',
                    validator: validateRequired(t('settings.payment_connections.bambora_modal.invalid.case_id.required')),
                },
                {
                    path: 'mcc',
                    validator: validateLength(4, t('settings.payment_connections.bambora_modal.invalid.mcc.invalid')),
                },
                {
                    path: 'mcc',
                    validator: validatePositiveNumber(t('settings.payment_connections.bambora_modal.invalid.mcc.invalid')),
                },
                {
                    path: 'payment_options',
                    validator: (value: { type: string; enabled: boolean; currencies: { currency: string; enabled: boolean }[] }[]) => {
                        const enabledPaymentOptions = value.filter(option => option.enabled);
                        if (enabledPaymentOptions.length === 0) {
                            return t('settings.payment_connections.bambora_modal.invalid.payment_options_enabled.none');
                        }
                        if (enabledPaymentOptions.some(option => option.currencies.filter(currency => currency.enabled).length === 0)) {
                            return t('settings.payment_connections.bambora_modal.invalid.payment_options_enabled.no_currency');
                        }
                        return;
                    },
                },
            ];
            if (values.agreement_type === 'direct') {
                validators.push(
                    {
                        path: 'merchant_number',
                        validator: validateRequired(t('settings.payment_connections.bambora_modal.invalid.merchant_number.required')),
                    },
                    {
                        path: 'merchant_number',
                        validator: validateBamboraMerchantNumber(t('settings.payment_connections.bambora_modal.invalid.merchant_number.invalid'), { ignoreEmpty: true }),
                    },
                    {
                        path: 'md5_key',
                        validator: validateRequired(t('settings.payment_connections.bambora_modal.invalid.md5_key.required')),
                    },
                    {
                        path: 'md5_key',
                        validator: validateMD5(t('settings.payment_connections.bambora_modal.invalid.md5_key.invalid'), { ignoreEmpty: true }),
                    }
                );
            }
            errors = validators.reduce((acc, elem) => {
                return validate(elem.path, elem.validator, values, acc);
            }, errors);

            return errors;
        },
        onSubmit: async (values) => {
            const signup: BamboraPayFacConnectionSignup | BamboraDirectConnectionSignup = {
                case_id: values.case_id || '',
                payment_options: values.payment_options.filter(x => x.enabled).map(option => ({
                    type: option.type,
                    currencies: option.currencies.filter(currency => currency.enabled).map(currency => currency.currency),
                })),
                ...(values.mcc?.length && values.agreement_type === 'payfac' && {
                    mcc: values.mcc,
                }),
            };
            if (values.agreement_type === 'direct') {
                signup.merchant_number = values.merchant_number;
                signup.md5_key = values.md5_key;
            }

            // Legacy callback handling, will not be used for agreement_type 'payfac'
            const callback: BamboraConnectionCallback = {
                merchant_number: values.merchant_number,
                md5_key: values.md5_key,
                ...(overrideSettings && values.access_token?.length && { access_token: values.access_token }),
                ...(overrideSettings && values.secret_token?.length && { secret_token: values.secret_token }),
                ...(overrideSettings && values.token_scope?.length && { token_scope: values.token_scope }),
                payment_options: values.payment_options.filter(x => x.enabled).map(option => ({
                    type: option.type,
                    currencies: option.currencies.filter(currency => currency.enabled).map(currency => currency.currency),
                })),
            };

            await finishRegistration({
                accountId: prodAccountId,
                userId,
                account,
                body: {
                    agreement_type: values.agreement_type,
                    signup,
                    callback,
                },
            });
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <SpacedWrapper>
                <InputWrapper>
                    <BamboraAgreementTypeDropdown
                        value={formik.values.agreement_type}
                        name= "agreement_type"
                        label= {t('settings.payment_connections.bambora_modal.fields.agreement_type')}
                        onChange={(value) => formik.setFieldValue('agreement_type', value)}
                        disabled={formik.isSubmitting}
                    />
                </InputWrapper>
            </SpacedWrapper>
            <SpacedWrapper>
                <InputWrapper>
                    <Dropdown
                        label={t('settings.payment_connections.bambora_modal.select_approval_label')}
                        name="approval"
                        value={toOption(activeApprovals.find(x => x.id === formik.values.case_id), i18n.language)}
                        onChange={option => {
                            formik.setFieldValue('case_id', option.value);
                        }}
                        disabled={activeApprovals.length === 0 || formik.isSubmitting}
                        options={
                            activeApprovals.reverse().map((a) => toOption(a, i18n.language))
                        }
                        required={formik.values.agreement_type === 'payfac'}
                    />
                </InputWrapper>
                {formik.values.agreement_type === 'direct' && <>
                    <InputWrapper>
                        <Input
                            label={t('settings.payment_connections.bambora_modal.fields.merchant_number')}
                            placeholder={t('settings.payment_connections.bambora_modal.placeholders.merchant_number')}
                            type="text"
                            name="merchant_number"
                            value={formik.values.merchant_number}
                            onChange={formik.handleChange}
                            onBlur={(e) => {
                                formik.handleChange(e);
                                formik.handleBlur(e);
                            }}
                            validation={fieldValidationMessage('merchant_number', formik.errors, formik.touched, formik.initialValues)}
                            required
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <Input
                            label={t('settings.payment_connections.bambora_modal.fields.md5_key')}
                            placeholder={t('settings.payment_connections.bambora_modal.placeholders.md5_key')}
                            type="text"
                            name="md5_key"
                            value={formik.values.md5_key}
                            onChange={formik.handleChange}
                            onBlur={(e) => {
                                formik.handleChange(e);
                                formik.handleBlur(e);
                            }}
                            validation={fieldValidationMessage('md5_key', formik.errors, formik.touched, formik.initialValues)}
                            required
                        />
                    </InputWrapper>
                </>}
                <View direction="column" width="100%" gap={distances.nano} alignItems="flex-start" pb={distances.normal}>
                    <P>{t(
                        'settings.payment_connections.bambora_modal.fields.payment_options_enabled'
                    )}</P>
                    <View direction="column" width="100%">
                        {formik.values.payment_options.map((paymentOption, index) => (
                            <CheckboxSection key={index}>
                                <Checkbox
                                    name={`payment_options[${index}].enabled`}
                                    label={paymentOption.type}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    checked={paymentOption.enabled}
                                />
                                {paymentOption.enabled && <CheckboxSection inset>
                                    <P>{t(
                                        'settings.payment_connections.bambora_modal.fields.currencies'
                                    )}</P>
                                    <CheckboxGroup>
                                        {paymentOption.currencies.map((currency, currencyIndex) => (
                                            <Checkbox
                                                key={currencyIndex}
                                                name={`payment_options[${index}].currencies[${currencyIndex}].enabled`}
                                                label={currency.currency}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                checked={currency.enabled}
                                            />
                                        ))}
                                    </CheckboxGroup>
                                </CheckboxSection>}
                            </CheckboxSection>
                        ))}
                    </View>
                    {formik.errors.payment_options && <P color={palette.destructive[500]}>{formik.errors.payment_options}</P>}
                </View>
            </SpacedWrapper>
            <SpacedWrapper>
                <ToggleDetails type="button" onClick={() => setOverrideSettings(!overrideSettings)}>
                    {t('settings.payment_connections.bambora_modal.advanced_toggle', {
                        context: overrideSettings ? 'hide' : 'show',
                    })}
                </ToggleDetails>
                {overrideSettings && <>
                    <InputWrapper>
                        <Input
                            label={t('settings.payment_connections.bambora_modal.fields.mcc')}
                            placeholder={t('settings.payment_connections.bambora_modal.placeholders.mcc')}
                            type="text"
                            name="mcc"
                            value={formik.values.mcc}
                            onChange={formik.handleChange}
                            onBlur={(e) => {
                                formik.handleChange(e);
                                formik.handleBlur(e);
                            }}
                            validation={fieldValidationMessage('mcc', formik.errors, formik.touched, formik.initialValues)}
                            disabled={formik.values.agreement_type === 'direct'}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <TranslatedValidatedInput
                            path="access_token"
                            initialValues={initialFormValues}
                            values={formik.values}
                            errors={formik.errors}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            touched={formik.touched}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <TranslatedValidatedInput
                            path="secret_token"
                            initialValues={initialFormValues}
                            values={formik.values}
                            errors={formik.errors}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            touched={formik.touched}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <TranslatedValidatedInput
                            path="token_scope"
                            initialValues={initialFormValues}
                            values={formik.values}
                            errors={formik.errors}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            touched={formik.touched}
                        />
                    </InputWrapper>
                </>}
            </SpacedWrapper>
            <ButtonGroup>
                <Button className="alt" onClick={closeModal}>
                    {t('settings.payment_connections.bambora_modal.cancel')}
                </Button>
                <Button type="submit"
                    disabled={
                        Object.keys(formik.errors || {}).length > 0 ||
                        formik.values.payment_options.filter((option) => option.enabled).length === 0
                        || formik.isSubmitting
                    }>
                    {t(
                        'settings.payment_connections.bambora_modal.sign_up_with_bambora_call_to_action'
                    )}
                </Button>
            </ButtonGroup>
        </form>
    );
};


const SpacedWrapper = styled.div`
`;

const InputWrapper = styled.div`
    &:last-child {
        margin: 0;
    }
`;

const CheckboxSection = styled.section<{ inset?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: ${distances.tiny};
    width: 100%;
    ${({ inset }) => !inset && `
        border-bottom: 1px solid ${palette.neutral[300]};
        padding-block: ${distances.small};
        &:last-child {
            border-bottom: none;
        }
    `}
    ${({ inset }) => inset && `padding-left: 24px;`}
`;

const CheckboxGroup = styled.div`
    display: flex;
    gap: ${distances.tiny};
`;


export default BamboraForm;
