import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useAccountId } from '../auth/useAccountId';
import { globalMobileBreakpoint, palette } from '../styles/constants';
import { useTranslation } from 'react-i18next';
import chevronRightSvg from './TopBar/icons/chevron-right.svg';
import usePaymentsEnabled from '../common/hooks/usePaymentsEnabled';
import useScopes from '../auth/accessToken/hooks/useScopes';

type ActivatePaymentsLinkProps = {
    shrink?: boolean;
    sideEffect?: () => void;
}

export default function ActivatePaymentsLink({ shrink, sideEffect }: ActivatePaymentsLinkProps) {
    const accountId = useAccountId();
    const { t } = useTranslation();
    const paymentsEnabled = usePaymentsEnabled();
    const userScopes = useScopes();
    const history = useHistory();


    if (paymentsEnabled || !userScopes.includes('admin:accounts')) {
        return null;
    }

    return (
        <Wrapper
            to={`/${accountId}/settings/payment-connections`}
            data-shrink={shrink}
            onClick={(e) => {
                if (sideEffect) {
                    e.preventDefault();
                    sideEffect();
                    history.push(`/${accountId}/settings/payment-connections`);
                }
            }}
        >
            {t('top_bar.activate_payments')}
            <Icon />
        </Wrapper>
    );
}

const Wrapper = styled(Link)`
    transition: all 0.2s ease;
    background: ${palette.success[500]};
    color: white;
    display: flex;
    padding: 6px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 24px;
    text-decoration: none;
    text-align: center;

    /* Paragraph/Small/Medium */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    &:hover {
        background: ${palette.success[600]};
    }

    &:active {
        background: ${palette.success[700]};
    }

    @media (max-width: ${globalMobileBreakpoint}px) {
        &[data-shrink='false'] {
            align-self: center;
            flex: 1;
            border-radius: 0;
            margin-block: -8px;
            display: inline-flex;
            min-height: 40px;
        }
        &[data-shrink='true'] {
            display: flex;
            padding: 2px 10px;
            justify-content: center;
            align-items: center;
            gap: 6px;

            /* Paragraph/XSmall/Medium */
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 166.667% */
        }
    }
`;

const Icon = styled.div.attrs({ className: 'icon' })`
    mask-image: url(${chevronRightSvg});
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    background: white;
    width: 16px;
    height: 16px;
`;
