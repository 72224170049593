import React, {  } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components/macro';

import { H1, P } from '../../../../components/Typography';
import Modal from '../../../../components/Modal';
import { Button } from '../../../../components/Buttons';
import { distances } from '../../../../styles/constants';
import A from '../../../../components/A';
import useIsDinteroRoot from '../../../../auth/accessToken/hooks/useIsDinteroRoot';

interface DeleteLocationModalProps {
    name: string;
    deleteLocation: () => void;
    dismissModal: () => void;
}

const DeleteLocationModal = (
    {
        name,
        deleteLocation,
        dismissModal,
    }: DeleteLocationModalProps
) => {
    const { t } = useTranslation('app');
    const history = useHistory();
    const { accountId } = useParams();
    const isDinteroAdmin = useIsDinteroRoot();

    return (
        <Modal>
            <H1>{t('locations.delete_location.title', { name })}</H1>
            <TextWrapper>
                {isDinteroAdmin ?
                    <P>{t('locations.delete_location.description')}</P>
                    :
                    <P><Trans i18nKey="locations.delete_location.description_not_admin"><A href="mailto:finance@dintero.com">finance@dintero.com</A></Trans></P>
                }
            </TextWrapper>
            <Button
                disabled={!isDinteroAdmin}
                onClick={() => {
                    deleteLocation();
                    history.push(`/${accountId}/settings/locations/`);
                }}
            >
                {t('locations.delete_location.delete_location_call_to_action')}
            </Button>
            <ButtonSpacer />
            <Button className="alt" onClick={dismissModal}>
                {t('locations.delete_location.back')}
            </Button>
        </Modal>
    );
};

const ButtonSpacer = styled.span`
    display: inline-block;
    width: ${distances.tiny};
`;

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;

export default DeleteLocationModal;
