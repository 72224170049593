import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { useAccountId } from '../../../auth/useAccountId';
import { CORE_API_HOSTNAME } from '../../../env';
import fulfill from '../../../fulfill';
import type { Account, AccountAdminUser } from '../../../types/management-auth';

async function fetchAccounts(payload: {
    accountId: string;
    params?: string;
}) {
    const params = new URLSearchParams(payload.params);
    return await fulfill.get({
        accountId: payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/management/accounts?${params.toString()}`,
        handlers: {
            200: (accounts: Account[]) => ({
                accounts,
                hasNext: accounts.length > 0,
            }),
            401: () => ({
                accounts: [],
            }),
            403: () => ({
                accounts: [],
            }),
        },
    }) as { accounts: Account[]; hasNext: boolean };
}

async function fetchPartnerUsers(payload: {
    accountId: string;
}) {
    return await fulfill.get({
        accountId: payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/management/users`,
        handlers: {
            200: (users: AccountAdminUser[]) => users,
        },
    }) as AccountAdminUser[];
};

const defaultParams = new URLSearchParams('limit=100');

export default function useMerchants(params: URLSearchParams = new URLSearchParams()) {
    const accountId = useAccountId();
    const mergedParams = new URLSearchParams([...defaultParams, ...params]);
    const { data, isLoading, isValidating, setSize } = useSWRInfinite((_, previousPageData) => {
        if (previousPageData && !previousPageData.hasNext) return null;
        if (previousPageData?.hasNext) {
            const nextPageArgs = previousPageData.accounts?.[previousPageData.accounts.length - 1]?.account_id;
            if (nextPageArgs) {
                mergedParams.set('starting_after', nextPageArgs);
            }
        }
        return { key: `merchants-${accountId}`, accountId, params: mergedParams?.toString() };
    }, fetchAccounts);

    const { data: users, isLoading: isLoadingUsers } = useSWR({ key: `partner-users-${accountId}`, accountId }, fetchPartnerUsers, {
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateOnMount: true,
    });

    const accounts = data ? data.map(page => page.accounts).flat() : [];
    const isLoadingInitialData = !data && !isValidating;
    const hasNext = data ? data[data.length - 1].hasNext : false;
    const isReachingEnd = hasNext === false;
    const isLoadingMore = Boolean(isValidating && data && data.length > 0);

    return {
        accounts,
        isLoading,
        isLoadingInitialData,
        isReachingEnd,
        next: () => hasNext && setSize((size) => size + 1),
        refresh: () => setSize(1),
        isLoadingMore,
        hasNext,
        users: users || [],
        isLoadingUsers,
    };
}
