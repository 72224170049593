import createExternalStore from '../../../../common/utils/createExternalStore';
import { CORE_API_HOSTNAME } from '../../../../env';
import fulfill from '../../../../fulfill';
import type { AccountAdminUser } from '../../../../types/management-auth';

export interface State {
    isShowingRemoveModal: boolean;
    isDeleted: boolean;
}

export const initialState: State = {
    isShowingRemoveModal: false,
    isDeleted: false,
};

export const userDetailsStore = createExternalStore(initialState, {
    openRemoveUserModal: (state) => ({
        ...state,
        isShowingRemoveModal: true,
    }),
    dismissRemoveUserModal: (state) => ({
        ...state,
        isShowingRemoveModal: false,
    }),
    fetchRemoveUserResponse: (state) => ({
        ...state,
        isDeleted: true,
    }),
}, 'userDetails');


export const actions = {
    getUser: async (payload: { accountId: string; userId: string }) => {
        const result: { user: AccountAdminUser; users: AccountAdminUser[] } = await fulfill.get({
            accountId: payload.accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/management/users`,
            handlers: {
                200: (users: AccountAdminUser[]) => {
                    const user = users.find(user => user.id === payload.userId)!;
                    return { user, users };
                },
            },
        });
        return result;
    },
    removeUser: async (payload: { accountId: string; userId: string }) => {
        const result = await fulfill.delete({
            accountId: payload.accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${payload.accountId}/management/users/${payload.userId}`,
            handlers: {
                200: () => userDetailsStore.dispatch('fetchRemoveUserResponse'),
            },
        });
        return result;
    },
    openRemoveUserModal: () => userDetailsStore.dispatch('openRemoveUserModal'),
    dismissRemoveUserModal: () => userDetailsStore.dispatch('dismissRemoveUserModal'),
};
