import { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import ArrowTopRightOnSquareIcon from '@heroicons/react/24/outline/ArrowTopRightOnSquareIcon';
import ArrowDownOnSquareIcon from '@heroicons/react/24/outline/ArrowDownOnSquareIcon';

import Card from '../../../components/Card';
import { H2, P } from '../../../components/Typography';
import { ButtonExternalLink } from '../../../components/Buttons';
import IconWrapper from '../../../components/Icons/IconWrapper';
import { ApprovalsPaymentResponse } from '../../../types/management-auth/generated';
import ApprovalsStatusBadge from './ApprovalStatusBadge';
import { useAccountId } from '../../../auth/useAccountId';
import { getPaymentMethods, toLanguageLink } from './utils';
import { getCurrentLanguage } from '../../../i18n';
import { Account } from '../../../types/management-auth';
import NotAvailable from './NotAvailable';
import FinishAccountInfobox from '../../components/FinishAccountInfobox';
import { Redirect } from 'react-router-dom';

const ApprovalsStatus = ({ approvals, account }: {approvals: ApprovalsPaymentResponse[]; account: Account}) => {
    const accountId = useAccountId();
    useEffect(() => {
        // scroll to top on mount
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, []);
    const { t, i18n } = useTranslation();
    const mostRecentCase = approvals[approvals.length -1];
    if (mostRecentCase) {
        const caseUrl = toLanguageLink(mostRecentCase.links?.find(l => l.rel === 'contract_url')?.href, getCurrentLanguage(i18n));
        const contractFileUrl = mostRecentCase.links?.find(l => l.rel === 'signed_contract_file_url')?.href;
        return (
            <Card>
                {mostRecentCase.case_status === 'WAITING_FOR_DECLARATION' && (
                    <>
                        <H2>{t('settings.approvalsPayments.approvalsWaitingDeclaration.title')}</H2>

                        <Stack>
                            <P>{t('settings.approvalsPayments.approvalsWaitingDeclaration.paragraph_action')}</P>
                            <P>{t('settings.approvalsPayments.approvalsWaitingDeclaration.paragraph_review')}</P>
                            <br />
                            {caseUrl && <ButtonExternalLink href={caseUrl} target="_blank">
                                <ButtonInner>
                                    {t('settings.approvalsPayments.approvalsWaitingDeclaration.button')}
                                    <IconWrapper>
                                        <ArrowTopRightOnSquareIcon />
                                    </IconWrapper>
                                </ButtonInner>
                            </ButtonExternalLink>}
                        </Stack>
                    </>
                )}
                {mostRecentCase.case_status === 'WAITING_FOR_SIGNATURE' && (
                    <>
                        <H2>{t('settings.approvalsPayments.approvalsWaitingSignatures.title')}</H2>

                        <Stack>
                            <P>{t('settings.approvalsPayments.approvalsWaitingSignatures.paragraph_action')}</P>
                            <P>{t('settings.approvalsPayments.approvalsWaitingSignatures.paragraph_review')}</P>
                            <br />
                            {caseUrl && <ButtonExternalLink href={caseUrl} target="_blank">
                                <ButtonInner>
                                    {t('settings.approvalsPayments.approvalsWaitingSignatures.button')}
                                    <IconWrapper>
                                        <ArrowTopRightOnSquareIcon />
                                    </IconWrapper>
                                </ButtonInner>
                            </ButtonExternalLink>}
                        </Stack>
                    </>
                )}
                {mostRecentCase.case_status === 'WAITING_FOR_DETAILS' && (
                    <>
                        <H2>{t('settings.approvalsPayments.approvalsWaitingDetails.title')}</H2>

                        <Stack>
                            <P>{t('settings.approvalsPayments.approvalsWaitingDetails.paragraph_action')}</P>
                            <P>{t('settings.approvalsPayments.approvalsWaitingDetails.paragraph_review')}</P>

                        </Stack>
                    </>
                )}

                {['UNDER_MANUAL_REVIEW', 'AUTOMATIC_REVIEW'].includes(mostRecentCase.case_status || '') && (
                    <>
                        <H2>{t('settings.approvalsPayments.approvalsWaitingReview.title')}</H2>

                        <Stack>
                            <P>{t('settings.approvalsPayments.approvalsWaitingReview.paragraph_action')}</P>
                            <P>{t('settings.approvalsPayments.approvalsWaitingReview.paragraph_review')}</P>
                        </Stack>
                    </>
                )}
                {mostRecentCase.case_status === 'DECLINED' && (
                    <>
                        <H2>{t('settings.approvalsPayments.approvalsDeclined.title')}</H2>

                        <Stack>
                            <P>{t('settings.approvalsPayments.approvalsDeclined.paragraph_action')}</P>
                        </Stack>
                    </>
                )}
                {mostRecentCase.case_status === 'ERROR' && (
                    <>
                        <H2>{t('settings.approvalsPayments.approvalsError.title')}</H2>

                        <Stack>
                            <P>{t('settings.approvalsPayments.approvalsError.paragraph_action')}</P>
                        </Stack>
                    </>
                )}
                {mostRecentCase.case_status === 'ACTIVE' && (
                    <SpacedApartRow>
                        <RowGroup>
                            <Strong>{t('settings.approvalsPayments.approvalsActive.declarationForm')}</Strong>
                            {mostRecentCase.case_status && <ApprovalsStatusBadge status="success" label={t('settings.approvalsPayments.signed')} />}
                        </RowGroup>
                        {contractFileUrl && <ButtonExternalLink href={contractFileUrl} target="_blank">
                            <ButtonInner>
                                <IconWrapper>
                                    <ArrowDownOnSquareIcon />
                                </IconWrapper>
                                {t('settings.approvalsPayments.approvalsActive.action')}
                            </ButtonInner>
                        </ButtonExternalLink>}
                    </SpacedApartRow>
                )}
            </Card>
        );
    }
    const country = account?.company?.address?.country || '';
    if (!country) {
        return (
            <Card>
                <FinishAccountInfobox />
            </Card>
        );
    }

    if (!['SE', 'NO', 'DK'].includes(country)) {
        return (
            <Card>
                <NotAvailable productLabel="Checkout" />
            </Card>
        );
    }
    return (
        <Redirect to={`/${accountId}/settings/payment-connections/form`} />
    );
};

const Strong = styled.strong`
    font-weight: 600;
    display: inline-block;
`;

const RowGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

const SpacedApartRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
`;

const Stack = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-top: 8px;
`;

const ButtonInner = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

export default ApprovalsStatus;
