import { Formik } from 'formik';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { namespace } from '..';
import { useActions } from '../../../../Actions';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { Input, ValidationState } from '../../../../components/Forms';
import { getValueAt } from '../../../../helpers/getValueAt';
import { validate, validateLength, validatePositiveNumber, validateRequired, validateUuid } from '../../../../helpers/validation';
import { useAccountIds } from '../../../../auth/accessToken/components/withAccountIds';
import { useSelector } from 'react-redux';
import { getUserId } from '../../../../auth/accessToken/selectors';
import { ApprovalsPaymentResponse } from '../../../../types/management-auth/generated';

interface ValidatedInputProps extends WithTranslation {
    values: any;
    initialValues: any;
    errors: any;
    path: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    maxlength?: number;
    formatter?: (value: string) => string;
}

const ValidatedInput = (
    {
        t,
        values,
        initialValues,
        errors,
        path,
        onChange,
        onBlur,
        touched,
        required,
        maxlength,
    }: ValidatedInputProps
) => {
    const value = getValueAt(path, values);
    const initialValue = getValueAt(path, initialValues);
    const error = getValueAt(path, errors);
    const was_touched = getValueAt(path, touched);
    const validation =
        (was_touched || initialValue) && error
            ? {
                state: ValidationState.Invalid,
                message: error,
            }
            : undefined;
    const label = t(`settings.payment_connections.vipps_callback_modal.fields.${path}`);
    return (
        <Input
            label={label}
            placeholder={t(`settings.payment_connections.vipps_callback_modal.placeholders.${path}`)}
            type="text"
            name={path}
            value={value || ''}
            onChange={(e) => {
                onChange(e);
            }}
            onBlur={(e) => {
                onChange(e);
                onBlur(e);
            }}
            validation={validation}
            maxlength={maxlength}
            required={required}
        />
    );
};

const TranslatedValidatedInput = withTranslation()(ValidatedInput);


export default function VippsDirectForm() {
    const { closeVippsModal, finishRegistration } = useActions(namespace);
    const { account } = useSelector((state) => ({
        account: state[namespace].account,
    }));
    const { prodAccountId } = useAccountIds();
    const userId = useSelector(state => getUserId(state));
    const { t } = useTranslation();
    const initialValues = {
        merchant_serial_number: '',
        client_id: '',
        client_secret: '',
        subscription_key: '',
    };
    const onSubmit = async (values: typeof initialValues) => {
        console.log(values);
        const businessName = account?.company.business_name;
        const signup = account?.connections?.vipps?.signup;
        const orgnumber = account?.company.organization_number;
        const strippedBusinessName = businessName?.replace(' ', '_');
        await finishRegistration({
            accountId: prodAccountId,
            body: {
                merchantSerialNumber: values.merchant_serial_number.trim(),
                client_Id: values.client_id.trim(),
                client_Secret: values.client_secret.trim(),
                'merchant-name': businessName,
                'signup-id': signup?.id,
                createdTime: new Date().toISOString(),
                orgnumber:  orgnumber,
                subscriptionKeys: [
                    {
                        'ocp-apim-subscription-key': values.subscription_key.trim(),
                        product: 'DEFAULT_ACCESSTOKEN',
                    },
                    {
                        'ocp-apim-subscription-key': values.subscription_key.trim(),
                        product: `ECOMMERCE-${strippedBusinessName}`,
                    },
                ],
            },
            userId,
        });
    };
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validate={values => {
                let errors: Partial<Record<keyof typeof initialValues, string>> = {};
                if (!values) {
                    return {
                        error: 'Account not loaded yet',
                    };
                }

                const validators: {
                    path: keyof typeof initialValues;
                    validator: (value: any) => string | undefined;
                }[] = [
                    {
                        path: 'merchant_serial_number',
                        validator: validateRequired(
                            t(
                                'settings.payment_connections.vipps_callback_modal.invalid.merchant_serial_number.required'
                            )
                        ),
                    },
                    {
                        path: 'merchant_serial_number',
                        validator: validatePositiveNumber(
                            t(
                                'settings.payment_connections.vipps_callback_modal.invalid.merchant_serial_number.invalid'
                            )
                        ),
                    },
                    {
                        path: 'client_id',
                        validator: validateUuid(
                            t(
                                'settings.payment_connections.vipps_callback_modal.invalid.client_id.invalid'
                            )
                        ),
                    },
                    {
                        path: 'client_id',
                        validator: validateLength(
                            36,
                            t(
                                'settings.payment_connections.vipps_callback_modal.invalid.client_id.invalid'
                            )
                        ),
                    },
                    {
                        path: 'client_id',
                        validator: validateRequired(
                            t(
                                'settings.payment_connections.vipps_callback_modal.invalid.client_id.required'
                            )
                        ),
                    },
                    {
                        path: 'client_secret',
                        validator: validateRequired(
                            t(
                                'settings.payment_connections.vipps_callback_modal.invalid.client_secret.required'
                            )
                        ),
                    },
                    {
                        path: 'subscription_key',
                        validator: validateRequired(
                            t(
                                'settings.payment_connections.vipps_callback_modal.invalid.subscription_key.required'
                            )
                        ),
                    },
                ];

                errors = validators.reduce((acc, elem) => {
                    return validate(elem.path, elem.validator, values, acc);
                }, errors);

                return errors;
            }}
        >
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                <form onSubmit={handleSubmit}>
                    <TranslatedValidatedInput
                        path="merchant_serial_number"
                        initialValues={initialValues}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        required
                    />
                    <TranslatedValidatedInput
                        path="client_id"
                        initialValues={initialValues}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        required
                    />
                    <TranslatedValidatedInput
                        path="client_secret"
                        initialValues={initialValues}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        required
                    />
                    <TranslatedValidatedInput
                        path="subscription_key"
                        initialValues={initialValues}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        required
                    />

                    <ButtonGroup>
                        <Button className="alt" type="button" onClick={closeVippsModal}>{t(
                            'settings.payment_connections.vipps_modal.cancel'
                        )}</Button>
                        <Button disabled={
                            Object.keys(errors).length > 0 ||
                            Object.keys(touched).length === 0
                        } type="submit">
                            {t(
                                'settings.payment_connections.vipps_modal.setup_vipps'
                            )}
                        </Button>
                    </ButtonGroup>
                </form>
            )}
        </Formik>
    );
}
