import styled from 'styled-components/macro';

import { grid, distances } from '../../../styles/constants';

interface DtProps {
    doubleColumn?: boolean;
    span?: keyof typeof grid.spans;
    stretch?: boolean;
}

const getWidth = (props: DtProps) => {
    if (props.doubleColumn) {
        return grid.spans.span2;
    }
    if (props.span) {
        return grid.spans[props.span];
    }
    return grid.spans.span1;
};

const Dt = styled.dt<DtProps>`
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: ${distances.normal};
    display: inline-block;
    width: ${getWidth};
    min-width: ${getWidth};
    overflow: hidden;
    text-overflow: ellipsis;
    ${(props) => (props.stretch ? 'flex: 1;' : '')}
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export default Dt;
