import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../Actions';

import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import styled from 'styled-components/macro';
import { useAccountIds } from '../../../auth/accessToken/components/withAccountIds';
import { ButtonGroup, ButtonLink } from '../../../components/Buttons';
import { Dd, Dl, Dt } from '../../../components/DescriptionList/Horizontal';
import Grid, { GridItem } from '../../../components/Grid';
import { LoadingOverlay } from '../../../components/Loading';
import * as selectors from '../../locations/selectors';
import { defaultRadius, palette } from '../../../styles/constants';

export interface SellerLocationsProps {
    organizationNumber?: string;
    accountId?: string;
};

const selector = createSelector(
    selectors.isLoading,
    selectors.isLoadingAny,
    selectors.salesLocations,
    (isLoading, loadingAny, locations) => ({ isLoading, loadingAny, locations })
);

const SellerLocations = ({ organizationNumber, accountId }: SellerLocationsProps) => {

    const getLocations = useActions('settings.locations').getLocations;
    const { t } = useTranslation('app');
    const {
        isLoading,
        loadingAny,
        locations,
    } = useSelector(selector);

    const {
        urlAccountId,
        accountIds,
    } = useAccountIds();

    useEffect(() => {
        if (organizationNumber) {
            accountIds.forEach(aid => getLocations({
                organization_number: ([organizationNumber.trim()].join(',') as unknown as string[]), // string[] gets parsed as %50 in the query
                limit: 100,
            }, aid));
        }
    }, [accountIds, getLocations, organizationNumber]);

    if (!locations || loadingAny || isLoading(urlAccountId)) {
        return <LoadingOverlay />;
    }

    const locationsTiedToSeller = Object.values(locations).flat().filter(l => accountId ? l.account_id === accountId : l);

    return <>
        <Grid gap={24} alignItems="stretch">
            {locationsTiedToSeller.map(location => <GridItem key={location.id}>
                <OutlinedWrapper>
                    <div>
                        <Dl break={1000}>
                            <Dt span="span2">{t('locations.location_form.fields.environment')}</Dt>
                            <Dd>{location.account_id?.startsWith('T') ? t('environments.test') : t('environments.production')}</Dd>
                        </Dl>
                        {location.name && <Dl break={1000}>
                            <Dt span="span2">{t('locations.location_form.fields.name')}</Dt>
                            <Dd>{location.name}</Dd>
                        </Dl>}
                        <Dl break={1000}>
                            <Dt span="span2">{t('locations.location_form.fields.location_id')}</Dt>
                            <Dd>{location.location_id}</Dd>
                        </Dl>
                        {location.address && location.address.country && <Dl break={1000}>
                            <Dt span="span2">{t('locations.location_form.fields.country')}</Dt>
                            <Dd>{location.address?.country}</Dd>
                        </Dl>}
                        {location.address && location.address.address_line && <Dl break={1000}>
                            <Dt span="span2">{t('locations.locations_list.fields.address')}</Dt>
                            <Dd>{location.address?.address_line}</Dd>
                        </Dl>}
                        {location.email && <Dl break={1000}>
                            <Dt span="span2">{t('locations.locations_list.fields.contact')}</Dt>
                            <Dd>{location.email}</Dd>
                        </Dl>}
                    </div>
                    <ButtonGroup mt="var(--Spacing-1)" mb={8}>
                        <ButtonLink to={`/${location.account_id}/settings/locations/${location.location_id}`} className="alt">
                            {t('locations.locations_list.view')}
                        </ButtonLink>
                    </ButtonGroup>
                </OutlinedWrapper>
            </GridItem>)}
        </Grid>
    </>;
};

const OutlinedWrapper = styled.div`
    padding-inline: 16px;
    padding-block: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: 1px solid ${palette.neutral[200]};
    border-radius: ${defaultRadius};
`;

export default SellerLocations;
